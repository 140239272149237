import React, { useEffect, useState } from "react";
import { Avatar, IconButton, List, ListItemButton, ListItemText, Menu, MenuItem, Popover, TextField, Tooltip, Typography } from "@mui/material";
import { AttachFileOutlined, Cancel, DataObject, Mood } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { Picker } from "emoji-mart";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({

    iconButton: {
        color: theme.palette.text.secondary
    },
    uploadInput: {
        display: "none",
    },
    // uploadInputHide: {
    //     minHeight: "56px",
    //     "& input": {
    //         cursor: "pointer",
    //         opacity: 0
    //     }
    // },
    textField: {
        flex: 1,
        "& textarea": {
            marginBottom: "30px !important",
        },
        "& input": {
            marginBottom: "30px !important",
        }
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: "-45px",
        padding: "5px 0px",
        "& label": {
            maxWidth: "calc(100% - 70px)",
            display: "flex",
        },
    },
    flexContainer: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        maxWidth: "93%",
    },
    fileNameBreaker: {
        textAlign: "center",
        wordWrap: "break-word",
        //maxWidth: "calc(100% - 80px)",
    },
    errorIcon: {
        color: theme.palette.secondary.main
    },
}))

const AdvancedTextField = ({
    value,
    onChange,
    templates,
    uploadValue,
    selectFiles,
    selectTemplates,
    selectEmojis,
    maxImageSize = (4 * 1024 * 1024),
    maxFileSize = (10 * 1024 * 1024),
    maxVideoSize = (30 * 1024 * 1024),
    removeButton = true,
    ...props
}) => {
    const classes = useStyles();
    const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);
    const [moreAnchorEl, setMoreAnchorEl] = useState(null);
    const [textFieldValue, setTextFieldValue] = useState(value);
    const [selectedMedias, setSelectedMedias] = useState([]);

    useEffect(async () => {
        setSelectedMedias(uploadValue ? await downloadFile(uploadValue) : []);
    }, [uploadValue]);

    const handleClear = () => {
        setSelectedMedias([]);
        //onClear()
    }

    const handleChangeMedias = (e, inputName) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        const file = e.target.files[0];
        if (file.type.startsWith('image/')) {
            if (file.size > maxImageSize) {
                toast.error(`Imagem muito grande! O tamanho máximo de envio de imagens é de ${(maxImageSize / (1024 * 1024)).toFixed(2)} MB.`);
            } else {
                const selectedFiles = Array.from(e.target.files);
                setSelectedMedias(selectedFiles);
            }
        } else if (file.type.startsWith('video/')) {
            if (file.size > maxVideoSize) {
                toast.error(`Vídeo muito grande! O tamanho máximo de envio de vídeos é de ${(maxVideoSize / (1024 * 1024)).toFixed(2)} MB.`);
            } else {
                const selectedFiles = Array.from(e.target.files);
                setSelectedMedias(selectedFiles);
            }
        } else {
            if (file.size > maxFileSize) {
                toast.error(`Arquivo muito grande! O tamanho máximo de envio de arquivos é de ${(maxFileSize / (1024 * 1024)).toFixed(2)} MB.`);
            } else {
                const selectedFiles = Array.from(e.target.files);
                setSelectedMedias(selectedFiles);
            }
        }

        e.target.value = null;
    };

    const downloadFile = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const urlParts = url.split('/');

            const fileName = urlParts[urlParts.length - 1];
            // Criar o objeto File a partir do blob do arquivo
            const file = new File([blob], fileName);

            // Definir o arquivo no elemento <input>
            return [file];
        } catch (error) {
            console.error('Error on downloading file:', error);
        }
    }

    useEffect(() => {
        onChange(textFieldValue, selectedMedias);
    }, [textFieldValue, selectedMedias])

    useEffect(() => {
        setTextFieldValue(value);
    }, [value])

    const handleEmojiClick = (event) => {
        setEmojiAnchorEl(event.currentTarget);
    };

    const handleMoreClick = (event) => {
        setMoreAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setEmojiAnchorEl(null);
        setMoreAnchorEl(null);
    };

    const handleEmojiSelect = (emoji) => {
        const newTextFieldValue = textFieldValue + emoji.native;
        setTextFieldValue(newTextFieldValue);
        handleClose();
    };

    const emojiOpen = Boolean(emojiAnchorEl);
    const emojiId = emojiOpen ? 'emoji-popover' : undefined;

    const moreOpen = Boolean(moreAnchorEl);
    const moreId = moreOpen ? 'more-popover' : undefined;
    return (
        <TextField
            {...props}
            value={textFieldValue}
            onChange={(e) => setTextFieldValue(e.target.value)}
            helperText={
                <div>
                    <div className={classes.iconContainer}>
                        <IconButton
                            aria-label="emojiPicker"
                            aria-describedby={emojiId}
                            component="span"
                            onClick={handleEmojiClick}
                            size="small"
                        >
                            <Mood className={props.error ? classes.errorIcon : classes.iconButton} />
                        </IconButton>

                        <Popover
                            id={emojiId}
                            open={emojiOpen}
                            anchorEl={emojiAnchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <Picker
                                perLine={16}
                                showPreview={false}
                                showSkinTones={false}
                                onSelect={handleEmojiSelect}
                            />
                        </Popover>
                        {selectTemplates &&
                            <>
                                <IconButton
                                    aria-label="moreOptions"
                                    aria-describedby={moreId}
                                    component="span"
                                    onClick={handleMoreClick}
                                    size="small"
                                >
                                    <DataObject className={props.error ? classes.errorIcon : classes.iconButton} />
                                </IconButton>
                                <Menu
                                    id={moreId}
                                    open={moreOpen}
                                    anchorEl={moreAnchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    {templates && Object.keys(templates).map((optionKey) => (
                                        <MenuItem
                                            key={optionKey}
                                            onClick={() => {
                                                const newTextFieldValue = textFieldValue + `{{${optionKey}}}`;
                                                setTextFieldValue(newTextFieldValue);
                                                handleClose();
                                            }}
                                        >
                                            <ListItemText primary={templates[optionKey]} />
                                        </MenuItem>
                                    ))}

                                </Menu>
                            </>
                        }

                        {selectFiles &&
                            <>
                                <input
                                    type="file"
                                    id={`upload-${props.id}`}
                                    name={props.uploadName}
                                    value={props.uploadValue}
                                    className={classes.uploadInput}
                                    onChange={handleChangeMedias}
                                />
                                <label htmlFor={`upload-${props.id}`}>
                                    <IconButton
                                        aria-label="upload"
                                        component="span"
                                        size="small">
                                        <AttachFileOutlined className={props.error ? classes.errorIcon : classes.iconButton} />
                                    </IconButton>
                                    {selectedMedias && selectedMedias.length > 0 && selectedMedias.map((media, index) => (
                                        <Tooltip
                                            title={<>
                                                <Typography>
                                                    {media.name}
                                                </Typography>
                                                <img alt={media.name} src={URL.createObjectURL(media)} style={{ maxWidth: 300, width: "auto", height: 50, objectFit: "fill" }} />
                                            </>
                                            }

                                        >
                                            <div key={index} className={classes.flexContainer}>
                                                <Avatar className={classes.avatar} alt={media?.name} src={URL.createObjectURL(media)} sx={{ width: 24, height: 24 }} />
                                                <Typography
                                                    className={classes.fileNameBreaker}
                                                    noWrap
                                                    component="span"
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    {`${media.name}`}
                                                </Typography>
                                            </div>
                                        </Tooltip>
                                    ))}
                                </label>
                                {selectedMedias && selectedMedias.length > 0 &&
                                    <IconButton
                                        aria-label="cancel-upload"
                                        component="span"
                                        onClick={handleClear}
                                        size="small"
                                    >
                                        <Cancel className={classes.iconButton} />
                                    </IconButton>
                                }
                            </>
                        }

                    </div>
                </div>
            }
        />
    );
}

export default AdvancedTextField;
