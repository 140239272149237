import makeStyles from '@mui/styles/makeStyles';
import MainHeader from '../../components/MainHeader';
import Title from '../../components/Title';
import { i18n } from '../../translate/i18n';
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper';
import DashboardFilters from './Filters';
import { useContext, useEffect, useState } from 'react';
import { endOfDay, startOfMonth } from 'date-fns';
import Charts from './Charts';
import HourChart from './HourChart';
import { Container, Grid, Paper, Typography } from '@mui/material';
import api from '../../services/api';
import toastError from '../../errors/toastError';
import { AuthContext } from '../../context/Auth/AuthContext';
import NumberEasing from '../../components/NumberEasing';
import InconsistentDataChart from './InconsistentDataChart';


const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(4),
    },
    formControl: {
        minWidth: 120,
    },
    data: {
        minWidth: 200,
        borderColor: theme.palette.divider,
        '& .MuiDataGrid-withBorderColor': {
            borderColor: theme.palette.divider,
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.secondary,
        }
    },
    ticketStatusCounter: {
        padding: theme.spacing(1),
        display: "flex",
        flex: 1,
        minWidth: 95,
        textAlign: 'center',
        overflow: "auto",
        flexDirection: "column",
        height: 80,
    },
    wrapper: {
        display: "flex",
        flexFlow: "row",
        flexWrap: "wrap",
        gap: 4,
    }
}));

const Dashboard = () => {
    const { user } = useContext(AuthContext);
    const classes = useStyles();
    const today = new Date();
    const firstDayOfMonth = startOfMonth(today);
    const lastHourOfToday = endOfDay(today);
    const [filter, setFilter] = useState({ start: firstDayOfMonth, end: lastHourOfToday, status: '' });
    const initialState = {
        userData: [{ id: 0, name: '', amount: 0 },],
        contactData: [{ id: 0, name: '', amount: 0 },],
        queueData: [{ id: 0, name: '', amount: 0 }],
        ticketCount: 0,
        timeData: [
            { hour: "06:00", count: 0 },
            { hour: "07:00", count: 0 },
            { hour: "08:00", count: 0 },
            { hour: "09:00", count: 0 },
            { hour: "10:00", count: 0 },
            { hour: "11:00", count: 0 },
            { hour: "12:00", count: 0 },
            { hour: "13:00", count: 0 },
            { hour: "14:00", count: 0 },
            { hour: "15:00", count: 0 },
            { hour: "16:00", count: 0 },
            { hour: "17:00", count: 0 },
            { hour: "18:00", count: 0 },
            { hour: "19:00", count: 0 },
            { hour: "20:00", count: 0 },
            { hour: "21:00", count: 0 },
            { hour: "22:00", count: 0 },
            { hour: "23:00", count: 0 },
        ],
        ticketCounterByStatus: {
            open: 0,
            closed: 0,
            pending: 0,
            total: 0
        },
        responseTime: [],
        inconsistentTicketsResult: {
            id: 0
        }
    }
    const [chartsData, setChartsData] = useState(initialState);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("/dashboard", {
                        params: { searchType: "contactId", searchStatus: filter.status, DateStart: filter.start, DateEnd: filter.end },
                    });

                    function transformArray(array, type) {
                        return array.map(item => ({
                            id: item.queueId || item.userId || item.contactId || 0,
                            name: item["queue.name"] || item["user.name"] || item["contact.name"] || (type === "queue" ? i18n.t("dashboard.messages.noQueue.title") : type === "user" ? i18n.t("dashboard.messages.unanswered.title") : ''),
                            amount: item.count || 0,
                        }));
                    }
                    const transformedData = {

                        queueData: transformArray(data.queueData, "queue"),
                        userData: transformArray(data.userData, "user"),
                        contactData: transformArray(data.contactData, "contact"),
                        timeData: chartsData.timeData.map((item) => ({
                            hour: item.hour,
                            count: data?.timeData.reduce((count, ticket) => {
                                const ticketHour = ticket.hour; // Certifique-se de ter um campo "hour" nos seus dados de ticket
                                if (ticketHour === item.hour) {
                                    return ticket.count;
                                }
                                return count;
                            }, 0),
                        })),
                        ticketCount: data.totalTicketsResult,
                        ticketCounterByStatus: data.ticketCounterByStatus,
                        responseTime: data.responseTimes,
                        inconsistentTicketsResult: data.inconsistentTicketsResult
                    };
                    setChartsData(transformedData);

                } catch (err) {
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [filter]);

    return (

        <Container maxWidth="lg" className={classes.container}>
            <MainHeader>
                <div>
                    <Title>{i18n.t("dashboard.title")}</Title>
                    <DashboardFilters classes={classes} values={filter} onChange={setFilter} />
                </div>
                <MainHeaderButtonsWrapper>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
                        <Paper className={classes.ticketStatusCounter} style={{ overflow: "hidden" }} variant="outlined">
                            <Typography variant="body" color="primary" >
                                {i18n.t("dashboard.messages.waiting.title")}
                            </Typography>
                            <Grid item >
                                <Typography color="textPrimary" component="h1" variant="h4">
                                    <NumberEasing value={chartsData.ticketCounterByStatus.pending} duration={100} decimals={0} />
                                </Typography>
                            </Grid>
                        </Paper>
                        <Paper className={classes.ticketStatusCounter} style={{ overflow: "hidden" }} variant="outlined">
                            <Typography variant="body" color="primary" >
                                {i18n.t("dashboard.messages.inAttendance.title")}
                            </Typography>
                            <Grid item>
                                <Typography color="textPrimary" component="h1" variant="h4">
                                    <NumberEasing value={chartsData.ticketCounterByStatus.open} duration={100} decimals={0} />
                                </Typography>
                            </Grid>
                        </Paper>
                        <Paper className={classes.ticketStatusCounter} style={{ overflow: "hidden" }} variant="outlined">
                            <Typography variant="body" color="primary" >
                                {i18n.t("dashboard.messages.closed.title")}
                            </Typography>
                            <Grid item>
                                <Typography color="textPrimary" component="h1" variant="h4">
                                    <NumberEasing value={chartsData.ticketCounterByStatus.closed} duration={100} decimals={0} />
                                </Typography>
                            </Grid>
                        </Paper>
                        <Paper className={classes.ticketStatusCounter} style={{ overflow: "hidden" }} variant="outlined">
                            <Typography variant="body" color="primary" >
                                {i18n.t("dashboard.messages.total.title")}
                            </Typography>
                            <Grid item>
                                <Typography color="textPrimary" component="h1" variant="h4">
                                    <NumberEasing value={chartsData.ticketCounterByStatus.total} duration={100} decimals={0} />
                                </Typography>
                            </Grid>
                        </Paper>

                    </div>

                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <HourChart classes={classes} counter={chartsData.ticketCount} chartData={chartsData.timeData} responseTimeData={chartsData.responseTime} medianData />
                </Grid>
                <>
                {user.profile === "admin" && chartsData.inconsistentTicketsResult.length > 0 &&
                        <Grid item xs={12}>
                            <InconsistentDataChart chartData={chartsData.inconsistentTicketsResult} />
                        </Grid>
                }
                {user.profile === "admin"&&
                        <Grid item xs={12} >
                            <Charts
                                label={i18n.t("dashboard.charts.perUser.title")}
                                classes={classes}
                                chartData={chartsData.userData}
                            />
                        </Grid>
                }
                </>
                <Grid item xs={12}>
                    <Charts
                        label={i18n.t("dashboard.charts.perContact.title")}
                        classes={classes}
                        chartData={chartsData.contactData}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Charts
                        label={i18n.t("dashboard.charts.perQueue.title")}
                        classes={classes}
                        chartData={chartsData.queueData}
                    />
                </Grid>
            </Grid>

        </Container>
    );
}
export default Dashboard;