import React, { useState, useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';

import ModalImage from "react-modal-image";
import api from "../../services/api";
import { Box, Typography } from "@mui/material";
import { BorderBottom, ImageNotSupported } from "@mui/icons-material";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	messageMedia: {
		objectFit: "cover",
		maxHeight: "300px !important",
		maxWidth: 330,
		width: "100%",
		minWidth: 200,
		borderRadius: 8
	},
	mediaWrapper: {
		maxWidth: 330,
		width: "100%",
		minHeight: 100,
	},
	noMediaWrapper: {
		width: "100%",
		minWidth: 200,
		height: 100,
		padding: 4,
		backgroundColor: theme.palette.divider,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 6
	}
}));

const ModalImageCors = ({ imageUrl, filename }) => {
	const classes = useStyles();
	const [fetching, setFetching] = useState(true);
	const [blobUrl, setBlobUrl] = useState(null);

	useEffect(() => {
		if (!imageUrl) return;
		const fetchImage = async () => {
			try {
				const { data, headers } = await api.get(imageUrl, {
					responseType: "blob",
				});
				const url = window.URL.createObjectURL(
					new Blob([data], { type: headers["content-type"] })
				);
				setBlobUrl(url);

			} catch {
				setBlobUrl(null);
			}
			setFetching(false);
		};
		fetchImage();
	}, [imageUrl]);

	return (<>
		{fetching || blobUrl &&
			<Box className={classes.mediaWrapper}>
				<ModalImage
					className={classes.messageMedia}
					showRotate={fetching ? imageUrl : blobUrl}
					smallSrcSet={fetching ? imageUrl : blobUrl}
					medium={fetching ? imageUrl : blobUrl}
					large={fetching ? imageUrl : blobUrl}
					alt={filename || "image"}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null; // prevents looping
						currentTarget.style.display = "none"
					}}
				/>
			</Box>
		}
		{!fetching && !blobUrl &&
			<Box className={classes.noMediaWrapper}>
				<ImageNotSupported fontSize="large" />
				<Typography>{i18n.t("messagesList.noImage")}</Typography>
			</Box>
		}
	</>
	);
};

export default ModalImageCors;
