import { AttachFileOutlined, Cancel, Delete } from "@mui/icons-material";
import { Avatar, FormControl, FormHelperText, IconButton, InputLabel, OutlinedInput, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
const useStyles = makeStyles(theme => ({
    uploadInput: {
        cursor: "default",
        display: "flex",
        justifyContent: "space-between",
        minHeight: "56px",
        "& input": {
            display: "none",
        }
    },
    uploadInputHide: {
        minHeight: "56px",
        "& *": {
            cursor: "pointer",
        },
        "& input": {
            opacity: 0
        }
    },
    flexContainer: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        width: "80%"
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
}))


const UploadFieldNew = ({
    onClear,
    onChange,
    previewUrl,
    maxImageSize = (4 * 1024 * 1024),
    maxFileSize = (10 * 1024 * 1024),
    maxVideoSize = (30 * 1024 * 1024),
    removeButton = true,
    ...props
}) => {
    props.value = props.value||""
    const classes = useStyles();

    const [selectedMedias, setSelectedMedias] = useState();
    useEffect(async () => {
        previewUrl ? setSelectedMedias(await downloadFile(previewUrl)) : setSelectedMedias([]);
    }, [previewUrl]);

    useEffect(() => {
        onChange(selectedMedias)
    }, [selectedMedias])

    const handleClear = () => {
        setSelectedMedias([])
        onClear()
    }

    const handleChangeMedias = (e, inputName) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        const file = e.target.files[0];
        if (file.type.startsWith('image/')) {
            if (file.size > maxImageSize) {
                toast.error(`Imagem muito grande! O tamanho máximo de envio de imagens é de ${(maxImageSize / (1024 * 1024)).toFixed(2)} MB.`);
            } else {
                const selectedFiles = Array.from(e.target.files);
                setSelectedMedias(selectedFiles);
            }
        } else if (file.type.startsWith('video/')) {
            if (file.size > maxVideoSize) {
                toast.error(`Vídeo muito grande! O tamanho máximo de envio de vídeos é de ${(maxVideoSize / (1024 * 1024)).toFixed(2)} MB.`);
            } else {
                const selectedFiles = Array.from(e.target.files);
                setSelectedMedias(selectedFiles);
            }
        } else {
            if (file.size > maxFileSize) {
                toast.error(`Arquivo muito grande! O tamanho máximo de envio de arquivos é de ${(maxFileSize / (1024 * 1024)).toFixed(2)} MB.`);
            } else {
                const selectedFiles = Array.from(e.target.files);
                setSelectedMedias(selectedFiles);
            }
        }

        e.target.value = null;
    };


    async function downloadFile(url) {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const urlParts = url.split('/');

            const fileName = urlParts[urlParts.length - 1];
            // Criar o objeto File a partir do blob do arquivo
            const file = new File([blob], fileName);
            // Definir o arquivo no elemento <input>
            return [file];
        } catch (error) {
            console.error('Erro ao fazer o download do arquivo:', error);
        }
    }

    return (
        <FormControl {...props}>
            <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
            <OutlinedInput
                {...props}
                className={clsx(classes.uploadInputHide, {
                    [classes.uploadInput]: selectedMedias && selectedMedias.length > 0,
                })}
                type="file"
                value={""}
                onChange={handleChangeMedias}
                startAdornment={<>
                    <AttachFileOutlined className={classes.iconButton} />
                    {selectedMedias && selectedMedias.length > 0 && selectedMedias.map((file, index) => (
                        <Tooltip
                            title={<>
                                <Typography>
                                    {file.name}
                                </Typography>
                                <img alt={file.name} src={URL.createObjectURL(file)} style={{ maxWidth: 300, width: "auto", height: 50, objectFit: "fill" }} />
                            </>
                            }

                        >
                            <div key={index} className={classes.flexContainer}>
                                <Avatar alt={file.name} src={URL.createObjectURL(file)} sx={{ width: 24, height: 24 }} />
                                <Typography
                                    noWrap
                                    sx={{ maxWidth: "100%" }}
                                    component="span"
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {`${file.name}`}
                                </Typography>
                            </div>
                        </Tooltip>
                    ))}
                </>
                }
                endAdornment={
                    selectedMedias && selectedMedias.length > 0 && removeButton && (
                        <IconButton
                            aria-label="cancel-upload"
                            component="span"
                            onClick={handleClear}
                            size="small"
                            className={classes.iconButton}
                        >
                            <Cancel />
                        </IconButton>
                    )
                }
            />
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    )
}
export default UploadFieldNew;