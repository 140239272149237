import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material"
import { Field, Form, Formik } from "formik";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import api from "../../services/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { useContext, useEffect, useState } from "react";

const CloseTicketModal = ({ modalOpen, onClose, ticketId, userId, ticketData }) => {
    const { subject, isGroup, whatsapp, queue } = ticketData;
    const hasMovideskIn = whatsapp?.movideskId || queue?.movideskId;
    const initialState = {
        subject: subject || "",
        startReviewService: !isGroup && !!whatsapp?.reviewMessage,
        sendFarewellMsg: !isGroup && !!whatsapp?.farewellMessage,
        sendTicketToMovidesk: !isGroup && !!hasMovideskIn,
        defineMovideskId: undefined,
        category: undefined,
        status: undefined,
        justification: undefined

    };
    const [categories, setCategories] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [justifications, setJustifications] = useState([]);
    const [loading, setLoading] = useState(false);

    const { user } = useContext(AuthContext);

    const history = useHistory();
    const { settings } = useContext(SettingsContext);

    useEffect(() => {
        if (!modalOpen || !hasMovideskIn || isGroup) return;
        setLoading(true);
        (async () => {
            try {
                const { data } = await api.get(`/integrations/${hasMovideskIn}`);
                setCategories(data.categories);
                setStatuses(data.statuses);
            } catch (err) {
                toastError(err);
            }
            setLoading(false);
        })();
    }, [modalOpen, ticketId, hasMovideskIn]);

    const getSettingValue = (key) => {
        if (settings && settings.length > 0) {
            const { value } = settings.find(s => s.key === key);
            return value;
        }
    };

    const requireSubject = getSettingValue('requireticketsubject') === 'enabled' ? true : false;
    const showFarewellMsgSwitch = (getSettingValue('showfarewellmessageswitch') === 'enabled' || user.profile === "admin") && whatsapp?.farewellMessage ? true : false;
    const showReviewMsgSwitch = (getSettingValue('showreviewmessageswitch') === 'enabled' || user.profile === "admin") && whatsapp?.reviewMessage ? true : false;

    const handleClose = () => {
        setCategories([]);
        setStatuses([]);
        setJustifications([]);
        onClose();
    }

    const handleUpdateTicket = async (values) => {
        try {
            await api.put(`/tickets/${ticketId}`, {
                status: values.startReviewService && !isGroup ? "onreview" : "closed",
                sendFarewellMsg: !isGroup ? values.sendFarewellMsg : false,
                sendTicketToMovidesk: values.sendTicketToMovidesk,
                sendReviewMsg: values.startReviewService,
                movideskData: {
                    movideskTicketId: values.defineMovideskId,
                    category: values.category,
                    status: values.status,
                    justification: values.justification
                },
                subject: values.subject,
                userId: userId,
            });
        } catch (err) {
            toastError(err);
        }
        history.push("/tickets");
        handleClose();
    }
    const handleChangeStatus = (e) => {
        const selectedStatus = e.target.value;
        const statusIndex = statuses.findIndex((status) => selectedStatus === status.name);
        if (statusIndex !== -1) {
            setJustifications(statuses[statusIndex].justifications);
        } else {
            setJustifications([]);
        };
    }
    return (
        <Dialog open={modalOpen} onClose={handleClose} maxWidth="xs" fullWidth scroll="paper">
            <DialogTitle
                color="textPrimary"
                id="form-dialog-title"
            >
                {i18n.t("closeTicketModal.title", { ticketId })}
            </DialogTitle>
            <Formik
                initialValues={initialState}
                enableReinitialize={true}
                //validationSchema={UserSchema}
                onSubmit={(values, actions) => {
                    setTimeout(async () => {
                        await handleUpdateTicket(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <DialogContent dividers>
                            <Field
                                as={TextField}
                                label={i18n.t("closeTicketModal.form.subject")}
                                autoFocus
                                name="subject"
                                error={touched.subject && Boolean(errors.subject)}
                                helperText={touched.subject && errors.subject}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                required={requireSubject}
                            />
                            {!isGroup &&
                                <>
                                    {showFarewellMsgSwitch &&
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    name="sendFarewellMsg"
                                                    as={Switch}
                                                    color="primary"
                                                    checked={values.sendFarewellMsg}
                                                />
                                            }
                                            label={i18n.t("closeTicketModal.form.sendFarewellMsg")}
                                        />
                                    }
                                    {showReviewMsgSwitch &&
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    name="startReviewService"
                                                    as={Switch}
                                                    color="primary"
                                                    checked={values.startReviewService}
                                                />
                                            }
                                            label={i18n.t("closeTicketModal.form.startReviewService")}
                                        />
                                    }
                                    {hasMovideskIn &&
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        name="sendTicketToMovidesk"
                                                        as={Switch}
                                                        color="primary"
                                                        checked={values.sendTicketToMovidesk}
                                                    />
                                                }
                                                label={i18n.t("closeTicketModal.form.sendTicketToMovidesk")}
                                            />
                                            {values.sendTicketToMovidesk &&
                                                <>
                                                    <Field
                                                        as={TextField}
                                                        label={i18n.t("closeTicketModal.form.defineMovideskId")}
                                                        name="defineMovideskId"
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                    />
                                                    {categories && categories.length > 0 &&
                                                        <FormControl
                                                            variant="outlined"
                                                            margin="dense"
                                                            fullWidth
                                                            error={Boolean(errors.category)}
                                                        >
                                                            <InputLabel>{i18n.t("closeTicketModal.form.category")}</InputLabel>
                                                            <Field
                                                                label={i18n.t("closeTicketModal.form.category")}
                                                                as={Select}
                                                                color="primary"
                                                                name="category"
                                                            >
                                                                <MenuItem value={""}><em>Nenhum</em></MenuItem>
                                                                {categories.map((category) =>
                                                                    <MenuItem value={category.name}>{category.name}</MenuItem>)
                                                                }
                                                            </Field>
                                                            <FormHelperText>{errors.category}</FormHelperText>
                                                        </FormControl>
                                                    }
                                                    {statuses && statuses.length > 0 &&
                                                        <FormControl
                                                            variant="outlined"
                                                            margin="dense"
                                                            fullWidth
                                                            error={Boolean(errors.status)}
                                                        >
                                                            <InputLabel>{i18n.t("closeTicketModal.form.status")}</InputLabel>
                                                            <Field
                                                                label={i18n.t("closeTicketModal.form.status")}
                                                                as={Select}
                                                                color="primary"
                                                                name="status"
                                                                loading={true}

                                                                onChange={(e) => {
                                                                    handleChangeStatus(e);
                                                                    setFieldValue("status", e.target.value);
                                                                }}

                                                            >
                                                                <MenuItem value={""}><em>Nenhum</em></MenuItem>
                                                                {statuses.map((status) =>
                                                                    <MenuItem value={status.name}>{status.name}</MenuItem>)
                                                                }
                                                            </Field>
                                                            <FormHelperText>{errors.status}</FormHelperText>
                                                        </FormControl>
                                                    }
                                                    {justifications && justifications.length > 0 &&
                                                        <FormControl
                                                            variant="outlined"
                                                            margin="dense"
                                                            fullWidth
                                                            error={Boolean(errors.justification)}
                                                        >
                                                            <InputLabel>{i18n.t("closeTicketModal.form.justification")}</InputLabel>
                                                            <Field
                                                                label={i18n.t("closeTicketModal.form.justification")}
                                                                as={Select}
                                                                color="primary"
                                                                name="justification"

                                                            >
                                                                <MenuItem value={""}><em>Nenhum</em></MenuItem>
                                                                {justifications.map((justification) =>
                                                                    <MenuItem value={justification.name}>{justification.name}</MenuItem>)
                                                                }
                                                            </Field>
                                                            <FormHelperText>{errors.justification}</FormHelperText>
                                                        </FormControl>
                                                    }
                                                    {loading &&
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                            <CircularProgress size={30} />
                                                        </Box>
                                                    }
                                                </>
                                            }

                                        </>
                                    }
                                </>
                            }

                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                color="secondary"
                                disabled={isSubmitting}
                                variant="outlined"
                            >
                                {i18n.t("closeTicketModal.buttons.cancel")}
                            </Button>
                            <ButtonWithSpinner
                                variant="contained"
                                type="submit"
                                color="primary"
                                loading={isSubmitting}
                            >
                                {i18n.t("closeTicketModal.buttons.ok")}
                            </ButtonWithSpinner>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

export default CloseTicketModal;