export const capitalizeFirst = str => {
    if (str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
};

export const capitalizeFirstLowercaseRest = str => {
    if (str){
        return (
          str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
        );
    }
};