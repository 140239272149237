import MainContainer from "../../components/MainContainer";
import makeStyles from '@mui/styles/makeStyles';
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { Avatar, AvatarGroup, Button, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { DeleteOutline, Edit, Search } from "@mui/icons-material";
import { Can } from "../../components/Can";
import { useContext, useEffect, useReducer, useState } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import FileListModal from "../../components/FileListModal";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import openSocket from "../../services/socket-io";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import useFileLists from "../../hooks/useFileList";


const reducer = (state, action) => {
    if (action.type === "LOAD_FILELISTS") {
        const fileLists = action.payload;
        const newFileLists = [];

        fileLists.forEach((fileList) => {
            const fileListIndex = state.findIndex((f) => f.id === fileList.id);
            if (fileListIndex !== -1) {
                state[fileListIndex] = fileList;
            } else {
                newFileLists.push(fileList);
            }
        });

        return [...state, ...newFileLists];
    }

    if (action.type === "UPDATE_FILELIST") {
        const fileList = action.payload;
        const fileListIndex = state.findIndex((f) => f.id === fileList.id);

        if (fileListIndex !== -1) {
            state[fileListIndex] = fileList;
            return [...state];
        } else {
            return [fileList, ...state];
        }
    }

    if (action.type === "DELETE_FILELIST") {
        const fileListId = action.payload;

        const fileListIndex = state.findIndex((f) => f.id === fileListId);
        if (fileListIndex !== -1) {
            state.splice(fileListIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        margin: theme.spacing(1),
        marginTop: 0,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
    trashIcon: {
        color: theme.palette.secondary.main
    },
    tableCustomRow: {
        '& td, & th': {
            borderColor: theme.palette.divider,
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 130,
    },
}))

const FileList = () => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const { fileLists, loading, hasMore, count } = useFileLists({ searchParam, pageNumber });
    const [fileListModalOpen, setFileListModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [selectedFileListId, setSelectedFileListId] = useState(null);

    useEffect(() => {
        setPageNumber(1);
    }, [searchParam]);

    const handleSearch = (event) => {
        setSearchParam(event.target.value);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleOpenFileListModal = () => {
        setSelectedFileListId(null);
        setFileListModalOpen(true);
    };
    const handleEditFileList = (fileListId) => {
        setSelectedFileListId(fileListId);
        setFileListModalOpen(true);
    };
    const handleDeleteFileList = async (fileListId) => {
        try {
            await api.delete(`/filelists/${fileListId}`);
            toast.success(i18n.t("fileLists.deleteConfirmationModal.deleteSuccess"));
        } catch (err) {
            toastError(err);
        }
        setSelectedFileListId(null);
    };
    const handleClose = () => {
        setFileListModalOpen(false);
        setSelectedFileListId(null);
    }
    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
        setSelectedFileListId(null);
    };
    return (
        <Can
            role={user.profile}
            perform="drawer-admin-items:view"
            yes={() => (
                <MainContainer className={classes.mainContainer}>
                    <FileListModal
                        open={fileListModalOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        fileListId={selectedFileListId}
                    />
                    <ConfirmationModal
                        title={
                            selectedFileListId &&
                            `${i18n.t("fileList.deleteConfirmationModal.title")} ${selectedFileListId
                            }?`
                        }
                        open={confirmModalOpen}
                        onClose={handleCloseConfirmationModal}
                        onConfirm={() => handleDeleteFileList(selectedFileListId)}
                    >

                        {i18n.t("fileList.deleteConfirmationModal.warning")}
                    </ConfirmationModal>
                    <MainHeader>
                        <Title>
                            {i18n.t("fileList.title")}
                        </Title>
                        <MainHeaderButtonsWrapper>
                            <TextField
                                placeholder={i18n.t("contacts.searchPlaceholder")}
                                type="search"
                                value={searchParam}
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search style={{ color: "gray" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenFileListModal}
                            >
                                {i18n.t("fileList.buttons.add")}
                            </Button>
                        </MainHeaderButtonsWrapper>
                    </MainHeader>
                    <Paper
                        className={classes.mainPaper}
                        variant="outlined"
                        onScroll={handleScroll}
                    >
                        <Table
                            size="small"
                        >
                            <TableHead>
                                <TableRow className={classes.tableCustomRow}>
                                    <TableCell align="center">
                                        {i18n.t("fileList.table.name")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t("fileList.table.files")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t("fileList.table.actions")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fileLists.length > 0 && fileLists.map((fileList) =>
                                    <TableRow className={classes.tableCustomRow}>
                                        <TableCell align="center">{fileList.name}</TableCell>
                                        <TableCell align="center">
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <AvatarGroup total={fileList.files.length}>
                                                    {fileList.files.length > 0 && fileList.files.map((file) =>
                                                        <Avatar src={file.mediaUrl} alt={file.filename} />
                                                    )}
                                                </AvatarGroup>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                size="small"
                                                className={classes.iconButton}
                                                onClick={() => handleEditFileList(fileList.id)}
                                            >
                                                <Edit />
                                            </IconButton>
                                            <Can
                                                role={user.profile}
                                                perform="fileLists-page:deleteFileList"
                                                yes={() => (
                                                    <IconButton
                                                        size="small"
                                                        className={classes.trashIcon}
                                                        onClick={() => {
                                                            setSelectedFileListId(fileList.id);
                                                            setConfirmModalOpen(true);
                                                        }}
                                                    >
                                                        <DeleteOutline />
                                                    </IconButton>
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {loading && <TableRowSkeleton columns={3} />}

                            </TableBody>
                        </Table>
                    </Paper>
                </MainContainer>
            )}
        />
    );
}
export default FileList;