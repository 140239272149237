import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { i18n } from '../translate/i18n';

import { withStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { toast } from 'react-toastify';

const styles = (theme) => ({
    errorDescription: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4",
    },

    errorTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px",
    },

    errorDiv: {
        display: "flex",
        height: "100%",
        marginTop: "20vh",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },

    errorDetails: {
        maxHeight: '40vh', // Altura máxima para exibição da barra de rolagem
        overflowY: 'auto',
        backgroundColor: "#212121",
        padding: '5px',
        borderRadius: '5px',
        color: "#ffffff" // Adicionar barra de rolagem vertical
    },
    // Estilos para o cabeçalho
    actions: {
        width: "100%",
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 1, // Adicionar uma alta camada para que fique acima do conteúdo rolável
    },
});

class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
        error: null,
        errorInfo: null,
        detailsOpen: false,
    };

    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo,
        });
    }

    toggleDetails = () => {
        this.setState((prevState) => ({
            detailsOpen: !prevState.detailsOpen,
        }));
    };

    reloadPage = () => {
        window.location.href = "/"; // Isso recarregará a página
    };

    copyToClipboard = () => {
        const messageText = this.state.error + "\n" + this.state.errorInfo.componentStack;
        navigator.clipboard.writeText(messageText);
    }

    render() {
        const { classes } = this.props;

        if (this.state.hasError) {
            return (
                <div className={classes.errorDiv}>
                    <span className={classes.errorTitle}>
                        {i18n.t("Ops! Algo deu errado")}
                    </span>
                    <p className={classes.errorDescription}>
                        {i18n.t("Ocorreu um erro inesperado. Por favor, tente novamente mais tarde ou entre em contato com o suporte.")}
                    </p>

                    <Button
                        onClick={this.reloadPage}
                        variant="outlined"
                        color="primary"
                    >
                        {i18n.t("Voltar à pagina inicial")}
                    </Button>
                    <Button
                        onClick={this.toggleDetails}
                        startIcon={<ExpandMoreIcon />}
                        size="small"
                    >
                        {i18n.t("Detalhes do erro")}
                    </Button>
                    <Collapse in={this.state.detailsOpen}>
                        <div className={classes.errorDetails}>
                            <p>
                                {this.state.error && this.state.error.toString()}
                            </p>
                            <pre>
                                {this.state.errorInfo && this.state.errorInfo.componentStack}
                            </pre>
                        </div>
                        <div className={classes.actions}>
                            <Button
                                onClick={this.copyToClipboard}
                                startIcon={<ContentCopy />}
                            >
                                {i18n.t("Copiar p/ área de transferência")}
                            </Button>
                        </div>
                    </Collapse>
                </div>
            );
        }
        return this.props.children;
    }
}

export default withStyles(styles)(ErrorBoundary);
