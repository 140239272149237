import {
    Button,
    CircularProgress,
    colors,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Field, Form, Formik } from "formik";
import React, { useState, useEffect, useRef} from "react";
import * as Yup from "yup";
import { i18n } from "../../translate/i18n";
import MainHeader from "../MainHeader";
import ColorPicker from "../ColorPicker";
import { Colorize } from "@mui/icons-material";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
    root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: theme.palette.primary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	iconButton:{
		color: theme.palette.text.secondary
	}
}));

const TagsSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito curto!")
		.max(100, "Muito longo")
		.required("Obrigatório!"),
	color: Yup.string().required("Obrigatório"),
	
});

const TagsModal = ({ open, onClose, tagId }) =>{
    const classes = useStyles();
	const initialState = {
		name:'',
		color:'',
		contacts:[]
	}
	const colorRef = useRef();
    const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
    const [tag, setTag] = useState(initialState);

    useEffect(() => {
		const delayDebounceFn = setTimeout(() => {

		(async () => {
			if (!tagId) return;
			try {
				const { data } = await api.get(`/tags/${tagId}`);
				setTag(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
		})();
		 }, 500)

		return () => {
			setTag(initialState);
			clearTimeout(delayDebounceFn);
		};
	}, [tagId, open]);

    const handleClose =()=>{
		setTag(initialState);
        onClose();
    }
    const handleSaveTag = async values =>{
		let tagData = values;
		try {
			if (tagId) {
				await api.put(`/tags/${tagId}`, tagData);
			} else {
				await api.post("/tags/", tagData);
			}
			toast.success(i18n.t("tags.tagModal.notices.addSuccess"));
			handleClose();
		} catch (err) {
			toastError(err);
		}
    }

    return(
        <div className={classes.root}>
            <Dialog maxWidth={'xs'} open={open} onClose={handleClose} scroll="paper">
				<MainHeader>
                    <DialogTitle
						color="textPrimary"
					>{tagId
							? i18n.t('tags.tagModal.title.edit')
							: i18n.t('tags.tagModal.title.add')}</DialogTitle>
                </MainHeader>
                <Formik
					initialValues={tag}
					enableReinitialize={true}
					validationSchema={TagsSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveTag(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
                {({ touched, errors, isSubmitting, values }) => (
                    <Form>
                        <DialogContent dividers>
							<Field
								as={TextField}
								label={i18n.t("tags.form.name")}
								fullWidth
								name="name"
								inputRef={colorRef}
								error={touched.name && Boolean(errors.name)}
								helperText={touched.name && errors.name}
								variant="outlined"
								margin="dense"
								
							/>
							<Field
									as={TextField}
									label={i18n.t("tags.form.color")}
									name="color"
									id="color"
									fullWidth
									onFocus={() => {
										setColorPickerModalOpen(true);
                                        colorRef.current.focus();
									}}
									error={touched.color && Boolean(errors.color)}
									helperText={touched.color && errors.color}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<div
													style={{ backgroundColor: values.color }}
													className={classes.colorAdorment}
												></div>
											</InputAdornment>
										),
										endAdornment: (
											<IconButton className={classes.iconButton}
												size="small"
												color="default"
												onClick={() => setColorPickerModalOpen(true)}
											>
												<Colorize />
											</IconButton>
										),
									}}
									variant="outlined"
									margin="dense"
								/>
								<ColorPicker
									open={colorPickerModalOpen}
									handleClose={() => setColorPickerModalOpen(false)}
									onChange={color => {
										values.color = color;
										setTag(() => {
											return { ...values, color };
										});
									}}
								/>
							
							

                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                color="secondary"
                                disabled={isSubmitting}
                                variant="outlined"
                            >
                                {i18n.t("tags.buttons.cancel")}
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSubmitting}
                                variant="contained"
                                className={classes.btnWrapper}
                            >
                                {tagId
										? `${i18n.t("tags.buttons.edit")}`
										: `${i18n.t("tags.buttons.add")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
                            </Button>
                        </DialogActions>
                    </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
}
export default TagsModal;