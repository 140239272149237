import React from "react";
import { Chip, Tooltip, Typography, Paper, Table, TableHead, TableCell, TableBody, TableRow, IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import { id } from "date-fns/locale";
import { i18n } from "../../translate/i18n";
import makeStyles from '@mui/styles/makeStyles';
import Title from "../../components/Title";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Visibility, WhatsApp } from "@mui/icons-material";


const useStyles = makeStyles(theme => ({
    chip: {
        margin: 2,
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
    tableCustomRow: {
        '& td, & th': {
            borderColor: theme.palette.divider,
        },
    },
}));
const InconsistentDataChart = ({ chartData, ...props }) => {
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const checkQueueInconsistency = (data) => {
        if (data.user.queues.includes(data.queue)) {
            return false;
        } else {
            return true;
        }
    }

    const handleOpenTicket = async (ticketId) => {
        if (!ticketId) return;
        history.push(`/tickets/${ticketId}`);
    };

    return (
        <Paper variant="outlined" color="secondary" style={{
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            height: "100%",
            borderColor: theme.palette.secondary.main + "4b",
            //flexWrap: "wrap",
            overflow: "hidden",
        }}>
            <Title color="secondary">{i18n.t("dashboard.charts.inconsistentTickets.title")}</Title>
            <Table size="small">
                <TableHead>
                    <TableRow className={classes.tableCustomRow}>
                        <TableCell>{i18n.t("dashboard.charts.inconsistentTickets.table.id")}</TableCell>
                        <TableCell align="center">
                            {i18n.t("dashboard.charts.inconsistentTickets.table.user")}
                        </TableCell>
                        <TableCell align="center">
                            {i18n.t("dashboard.charts.inconsistentTickets.table.queue")}
                        </TableCell>
                        <TableCell align="center">
                            {i18n.t("dashboard.charts.inconsistentTickets.table.connection")}
                        </TableCell>
                        <TableCell align="center">
                            {i18n.t("dashboard.charts.inconsistentTickets.table.reason")}
                        </TableCell>
                        <TableCell align="center">
                            {i18n.t("dashboard.charts.inconsistentTickets.table.actions")}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {chartData.length > 0 && chartData.map((data) =>
                        <TableRow className={classes.tableCustomRow}>
                            <TableCell align="center">
                                {`#${data.id}`}
                            </TableCell>
                            <TableCell align="center">
                                <Typography></Typography>
                                <Tooltip title={
                                    <>
                                        <Typography>{i18n.t("dashboard.charts.inconsistentTickets.table.queue")}</Typography>
                                        {data.user?.queues.map((uq) =>
                                            <Chip
                                                key={uq.id}
                                                style={{ backgroundColor: uq.color }}
                                                label={uq.name}
                                                className={classes.chip}
                                            />
                                        )}
                                    </>
                                }>
                                    <Typography>{`${data.user?.name}`}</Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell align="center">
                                {`${data.queue?.name}`}
                            </TableCell>
                            <TableCell align="center">
                                {`${data.whatsapp?.name}`}
                            </TableCell>
                            <TableCell align="center">
                                {checkQueueInconsistency(data) &&
                                    <Typography color="secondary">{`Usuário não possui fila atribuída ao ticket`}</Typography>
                                }
                            </TableCell>
                            <TableCell align="center">
                                <IconButton
                                    size="small"
                                    className={classes.iconButton}
                                    onClick={() => handleOpenTicket(data.id)}
                                >
                                    <Visibility />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Paper>
    );
}
export default InconsistentDataChart;