import React from "react";
import Markdown from "markdown-to-jsx";
import { blue, cyan, lightBlue } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	customLink: {
		color: "#027eb5",
		textDecoration: "none",
		"&:hover": {
			textDecoration: "underline"
		}
	}
}))

const elements = [
	"a",
	"abbr",
	"address",
	"area",
	"article",
	"aside",
	"audio",
	"b",
	"base",
	"bdi",
	"bdo",
	"big",
	"blockquote",
	"body",
	"br",
	"button",
	"canvas",
	"caption",
	"cite",
	"code",
	"col",
	"colgroup",
	"data",
	"datalist",
	"dd",
	"del",
	"details",
	"dfn",
	"dialog",
	"div",
	"dl",
	"dt",
	"em",
	"embed",
	"fieldset",
	"figcaption",
	"figure",
	"footer",
	"form",
	"h1",
	"h2",
	"h3",
	"h4",
	"h5",
	"h6",
	"head",
	"header",
	"hgroup",
	"hr",
	"html",
	"i",
	"iframe",
	"img",
	"input",
	"ins",
	"kbd",
	"keygen",
	"label",
	"legend",
	"li",
	"link",
	"main",
	"map",
	"mark",
	"marquee",
	"menu",
	"menuitem",
	"meta",
	"meter",
	"nav",
	"noscript",
	"object",
	"ol",
	"optgroup",
	"option",
	"output",
	"p",
	"param",
	"picture",
	"pre",
	"progress",
	"q",
	"rp",
	"rt",
	"ruby",
	"s",
	"samp",
	"script",
	"section",
	"select",
	"small",
	"source",
	"span",
	"strong",
	"style",
	"sub",
	"summary",
	"sup",
	"table",
	"tbody",
	"td",
	"textarea",
	"tfoot",
	"th",
	"thead",
	"time",
	"title",
	"tr",
	"track",
	"u",
	"ul",
	"var",
	"video",
	"wbr",

	// SVG
	"circle",
	"clipPath",
	"defs",
	"ellipse",
	"foreignObject",
	"g",
	"image",
	"line",
	"linearGradient",
	"marker",
	"mask",
	"path",
	"pattern",
	"polygon",
	"polyline",
	"radialGradient",
	"rect",
	"stop",
	"svg",
	"text",
	"tspan",
];

const allowedElements = ["a", "b", "strong", "em", "u", "code", "del"];

const CustomLink = ({ children, ...props }) => {
	const classes = useStyles();
	return (
		<a {...props} target="_blank" className={classes.customLink} rel="noopener noreferrer">
			{children}
		</a>
	);
};


const MarkdownWrapper = ({ children }) => {
	const boldRegex = /\*(.*?)\*/g;
	const tildaRegex = /~(.*?)~/g;

	// Verificações para sanitizar conteúdo potencialmente perigoso
	if (children && children.includes("BEGIN:VCARD")) children = null;

	if (children && children.includes("MESSAGE_NOT_SUPPORTED"))
		children = i18n.t("backendErrors.MESSAGE_NOT_SUPPORTED");

	if (children && children.includes("data:image/")) children = null;

	if (children && boldRegex.test(children)) {
		children = children.replace(boldRegex, "**$1**");
	}
	if (children && tildaRegex.test(children)) {
		children = children.replace(tildaRegex, "~~$1~~");
	}

	// Verificar se o conteúdo contém blocos de código
	const containsCode = /`[^`]*`/.test(children) || /<pre[^>]*>[\s\S]*?<\/pre>/.test(children) || /<code[^>]*>[\s\S]*?<\/code>/.test(children);

	const options = React.useMemo(() => {
		const markdownOptions = {
			disableParsingRawHTML: true,
			forceInline: !containsCode, // Se contém código, forceInline é false
			overrides: {
				a: { component: CustomLink },
			},
		};

		elements.forEach(element => {
			if (!allowedElements.includes(element)) {
				markdownOptions.overrides[element] = el => el.children || null;
			}
		});

		return markdownOptions;
	}, [containsCode, children]);

	// Substituir URLs por links clicáveis
	const transformUrlsToLinks = (markdownText) => {
		const transformedText =
			markdownText?.replace(
				/(^|\s)((?<!@)\b\w+(\.\w+)*\.(com|net|org|gov|io|ly|dev|xyz)(\.[a-z]+)?\b)(\S*)/gi,
				(match, leadingSpace, url) => {
					if (!/^(http:\/\/|https:\/\/).*/i.test(url)) {
						return `${leadingSpace}https://${url}`;
					}
					return match;
				}
			) || "";

		return transformedText;
	};

	let modifiedMarkdown = transformUrlsToLinks(children);

	return <Markdown options={options}>{modifiedMarkdown}</Markdown>;
};

export default MarkdownWrapper;