import React, { useState, useEffect } from 'react';

const NumberEasing = ({ value, duration, decimals, minDigits }) => {
  const formatValue = (val, numDecimals, numMinDigits) => {
    return val.toFixed(numDecimals).padStart(numMinDigits, "0");
  };
  const [displayValue, setDisplayValue] = useState(formatValue(value, decimals, minDigits));
  const [targetValue, setTargetValue] = useState(parseFloat(value));

  useEffect(() => {
    const startValue = parseFloat(displayValue);
    const steps = Math.floor(duration / 16); // 60 fps


    if (isNaN(targetValue)) {
      console.error("Invalid target value");
      return;
    }

    const increment = (targetValue - startValue) / steps;
    let currentStep = 0;

    const intervalId = setInterval(() => {
      currentStep++;
      const newValue = startValue + currentStep * increment;
      setDisplayValue(formatValue(newValue, decimals, minDigits));

      if (currentStep >= steps) {
        clearInterval(intervalId);
        setDisplayValue(formatValue(targetValue, decimals, minDigits));
      }
    }, 16);

    return () => {
      clearInterval(intervalId);
    };
  }, [value, duration, decimals, minDigits, displayValue, targetValue]);

  useEffect(() => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue)) {
      setTargetValue(newValue);
    }
  }, [value]);

  

  return displayValue;
};

export default NumberEasing;
