import { Avatar, AvatarGroup, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, InputBase, List, ListItemAvatar, ListItemButton, ListItemText, Paper, TextField, Typography } from "@mui/material"
import MainHeader from "../MainHeader";
import { i18n } from "../../translate/i18n";
import MainHeaderButtonsWrapper from "../MainHeaderButtonsWrapper";
import { Close, Search } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import useFileLists from "../../hooks/useFileList";
import { useEffect, useState } from "react";
import TableRowSkeleton from "../TableRowSkeleton";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
    listItemButton: {
        borderRadius: 4,
    },
    noFileListText: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4",
    },

    noFileListTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px",
    },

    noFileListDiv: {
        display: "flex",
        height: "100px",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
}))

const FileListSelectModal = ({ open, onClose, choseFileList }) => {
    const classes = useStyles();
    const [searchParam, setSearchParam] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [downloading, setDownloading] = useState(false);
    const { fileLists, hasMore, loading } = useFileLists({ searchParam, pageNumber });

    useEffect(() => {
        setPageNumber(1);
    }, [searchParam]);

    const handleSearch = (event) => {
        setSearchParam(event.target.value);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };
    const handleClose = () => {
        onClose();
    }

    const handleSelectFileList = async (fileList) => {
        setDownloading(true);
        try{
            const files = await Promise.all(
                Array.from(fileList.files).map(async (file) => {
                    const blob = await fetch(file.mediaUrl).then((response) => response.blob());
                    return new File([blob], file.filename, { type: blob.type });
                })
            );
            // Crie um array de objetos File, como o FileList simulado
            const simulatedMediaInput = { target: { name: "fileLists", files } };
            choseFileList(simulatedMediaInput);
        }catch(err){
            toastError(err);
        }finally{
            setDownloading(false);
        }
    };

    return (
        <Dialog open={open} maxWidth="xs" fullWidth onClose={handleClose}>
            <MainHeader fullWidth>
                <DialogTitle 
                    color="textPrimary" 
                    id="filelist-dialog-title"
                >
                    {i18n.t("fileListSelectModal.title")}
                </DialogTitle>
                <TextField
                    fullWidth
                    placeholder={i18n.t("contacts.searchPlaceholder")}
                    type="search"
                    value={searchParam}
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search style={{ color: "gray" }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </MainHeader>
            <DialogContent dividers padding={0} onScroll={handleScroll} style={{minHeight: 230}}>
                <List style={{ paddingTop: 0 }}>
                    {fileLists.length > 0 ? fileLists.map((fileList) =>
                        <>
                            <ListItemButton disabled={downloading} key={fileList.id} className={classes.listItemButton} onClick={() => handleSelectFileList(fileList)}>
                                <ListItemAvatar >
                                    <AvatarGroup max={2} total={fileList.files.length}>
                                        {fileList.files.length > 0 && fileList.files.map((file) =>
                                            <Avatar src={file.mediaUrl} alt={file.filename} />
                                        )}
                                    </AvatarGroup>
                                </ListItemAvatar>
                                <ListItemText primary={<Typography style={{ marginLeft: 5 }}>{fileList.name}</Typography>} />
                            </ListItemButton>
                            <Divider variant="inset" />
                        </>
                    ) : !loading &&
                    <div className={classes.noFileListDiv}>
                        <span className={classes.noFileListTitle}>
                            {i18n.t("Nada Aqui!")}
                        </span>
                        <p className={classes.noFileListText}>
                            {i18n.t("Nenhuma lista de arquivos encontrada")}
                        </p>
                    </div>
                    }
                    {loading &&
                        <div style={{ textAlign: "center", padding: 10 }}>
                            <CircularProgress />
                        </div>
                    }

                </List>
            </DialogContent>
            <DialogActions>
                <ButtonWithSpinner
                    color="secondary"
                    variant="outlined"
                    loading={downloading}
                    onClick={handleClose}
                >
                    {i18n.t("fileListSelectModal.buttons.cancel")}
                </ButtonWithSpinner>
            </DialogActions>
        </Dialog>
    );
}

export default FileListSelectModal;