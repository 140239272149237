import React, { useState, useRef, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import openSocket from "../../services/socket-io";
import useSound from "use-sound";

import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import makeStyles from '@mui/styles/makeStyles';
import Badge from "@mui/material/Badge";
import ChatIcon from "@mui/icons-material/Chat";

import TicketListItem from "../TicketListItem";
import { i18n } from "../../translate/i18n";
import useTickets from "../../hooks/useTickets";
import alertSound from "../../assets/sound.mp3";
import { AuthContext } from "../../context/Auth/AuthContext";
import useSettings from "../../hooks/useSettings";
import { toast } from "react-toastify";

import Favico from 'favico.js';
import { useTheme } from "@mui/material";


const useStyles = makeStyles(theme => ({
	tabContainer: {
		overflowY: "auto",
		maxHeight: 350,
		...theme.scrollbarStyles,
	},
	popoverPaper: {
		width: "100%",
		maxWidth: 350,
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(1),
		[theme.breakpoints.down('md')]: {
			maxWidth: 270,
		},
	},
	noShadow: {
		boxShadow: "none !important",
	},
	iconButton: {
		color: theme.palette.text.secondary
	}
}));


const NotificationsPopOver = () => {
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const ticketIdUrl = +history.location.pathname.split("/")[2];
	const ticketIdRef = useRef(ticketIdUrl);
	const anchorEl = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const { settings } = useSettings();

	const [, setDesktopNotifications] = useState([]);

	const { tickets } = useTickets({ withUnreadMessages: "true" });
	const [play] = useSound(alertSound);
	const soundAlertRef = useRef();

	const historyRef = useRef(history);
	useEffect(() => {
		const favico = new Favico({
			bgColor: theme.palette.secondary.main,      // Cor de fundo vermelha
			textColor: theme.palette.text.primary,    // Cor do texto branco
			fontFamily: 'Cantarell',     // Família da fonte Arial
			fontStyle: 'bold',     // Estilo da fonte normal
			type: 'circle',          // Tipo de favicon circular
			position: 'bottom',          // Posição do favicon na página (por exemplo, 'up', 'down', 'left', 'right')
			animation: 'none',    // Tipo de animação 'popFade'
		});

		favico.badge(notifications?.length||0);

		return () => {
			favico.reset();
		};
	}, [notifications]);
	useEffect(() => {
		soundAlertRef.current = play;
		if (!("Notification" in window)) {
			console.warn("This browser doesn't support notifications");
		} else {
			Notification.requestPermission();
		}
	}, [play]);

	useEffect(() => {
		setNotifications(tickets);
	}, [tickets]);

	useEffect(() => {
		ticketIdRef.current = ticketIdUrl;
	}, [ticketIdUrl]);

	useEffect(() => {
		const socket = openSocket();
		socket.on("connect", () => socket.emit("joinNotification"));
		socket.on("schedule", data => {
			if (data.action === "sent") {
				if (data.schedule?.userId === user?.id) {
					if (localStorage.getItem("notificationsnd") === "enabled") {
						soundAlertRef.current();
					}
					toast.success(`Mensagem agendada foi enviada para o contato ${data.schedule?.contact?.name} com sucesso!`)
				}
			}
		});
		socket.on("ticket", data => {
			const UserQueues = user.queues.findIndex((users) => (users.id === data.ticket?.queueId));
			if (
				data.action === "update" &&
				(data.ticket.userId === user?.id || !data.ticket.userId) &&
				(UserQueues !== -1 || !data.ticket.queueId) &&
				(data.ticket.status !== 'closed' && data.ticket.status !== 'expired')
			) {

				setNotifications(prevState => {
					const ticketIndex = prevState.findIndex(t => t.id === data.ticket?.id);
					if (ticketIndex !== -1) {
						prevState[ticketIndex] = data.ticket;
						return [...prevState];
					}
					return [data.ticket, ...prevState];
				});
			}
			if (data.action === "setUnread") {
				if (data.ticket?.userId === user?.id && localStorage.getItem("notificationsnd") === "enabled") {
					soundAlertRef.current();
				}
			} else if (
				data.action === "updateUnread" || data.action === "delete" ||
				(data.action === "update" && ((UserQueues === -1 && data.ticket?.queueId) || data.ticket?.userId !== user?.id))
			) {
				setNotifications(prevState => {
					const ticketIndex = prevState.findIndex(t => t.id === data.ticketId || data.ticket?.id);
					if (ticketIndex !== -1) {
						prevState.splice(ticketIndex, 1);
						return [...prevState];
					}
					return prevState;
				});

				setDesktopNotifications(prevState => {
					const notfiticationIndex = prevState.findIndex(
						n => n.tag === String(data.ticketId || data?.ticket?.id)
					);
					if (notfiticationIndex !== -1) {
						prevState[notfiticationIndex].close();
						prevState.splice(notfiticationIndex, 1);
						return [...prevState];
					}
					return prevState;
				});
			}
		});

		socket.on("appMessage", data => {
			const UserQueues = user.queues.findIndex((queue) => (queue.id === data.ticket?.queueId));
			if (
				((data.action === "create") &&
					!data.message.read &&
					(data.ticket.userId === user?.id || !data.ticket.userId)
					&& (UserQueues !== -1 || !data.ticket.queueId))
			) {
				setNotifications(prevState => {
					const ticketIndex = prevState.findIndex(t => t.id === data.ticket?.id);
					if (ticketIndex !== -1) {
						prevState[ticketIndex] = data.ticket;
						return [...prevState];
					}
					return [data.ticket, ...prevState];
				});

				const shouldNotNotificate =
					(data.message.ticketId === ticketIdRef.current &&
						document.visibilityState === "visible") ||
					(data.ticket.userId && data.ticket.userId !== user?.id) ||
					data.ticket.isGroup;

				if (shouldNotNotificate || localStorage.getItem("notificationsnd") === "disabled") return;

				handleNotifications(data);
			}
		});
		return () => {
			socket.disconnect();
		};
	}, [user]);

	const handleNotifications = async (data) => {
		const { message, contact, ticket } = data;
		const isMobileDevice = window.innerWidth <= 767; // Ajuste o valor conforme necessário

		const notificationOptions = {
			title: `${i18n.t("tickets.notification.message")} ${contact.name}`,
			body: `${message.body} - ${format(new Date(), "HH:mm")}`,
			icon: contact.profilePicUrl,
			tag: ticket.id,
			renotify: true,
			data: {
				url: `/tickets/${ticket.id}`,
			},
		};

		try {
			if (isMobileDevice) {
				const registration = await navigator.serviceWorker.ready;
				registration.showNotification(notificationOptions.title, notificationOptions);
			} else {
				const notification = new Notification(
					notificationOptions.title,
					notificationOptions
				);

				notification.onclick = e => {
					e.preventDefault();
					window.focus();
					historyRef.current.push(notificationOptions.data.url);
				};
			}
		} catch (error) {
			console.error('Error getting Service Worker registration:', error);
		}
		soundAlertRef.current();
	};



	const handleClick = () => {
		setIsOpen(prevState => !prevState);
	};

	const handleClickAway = () => {
		setIsOpen(false);
	};

	const NotificationTicket = ({ children }) => {
		return <div onClick={handleClickAway}>{children}</div>;
	};


	return <>
		<IconButton
			onClick={handleClick}
			ref={anchorEl}
			aria-label="Open Notifications"
			className={classes.iconButton}
			size="large">
			<Badge badgeContent={notifications.length} color="secondary">
				<ChatIcon />
			</Badge>
		</IconButton>
		<Popover
			disableScrollLock
			open={isOpen}
			anchorEl={anchorEl.current}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			classes={{ paper: classes.popoverPaper }}
			onClose={handleClickAway}
		>
			<List dense className={classes.tabContainer}>
				{notifications.length === 0 ? (
					<ListItem>
						<ListItemText>{i18n.t("notifications.noTickets")}</ListItemText>
					</ListItem>
				) : (
					notifications.map(ticket => (
						<NotificationTicket key={ticket.id}>
							<TicketListItem
								settings={settings}
								ticket={ticket} />
						</NotificationTicket>
					))
				)}
			</List>
		</Popover>
	</>;
};

export default NotificationsPopOver;