import React, { createContext, useEffect, useState } from "react";

const SearchContext = createContext();

const SearchProvider = ({ children }) => {
    const [searchMessage, setSearchMessage] = useState();
    const [isSearching, setIsSearching] = useState(false);

    return (
        <SearchContext.Provider
            value={{
                searchMessage, setSearchMessage,
                isSearching, setIsSearching
            }}>
            {children}
        </SearchContext.Provider>
    );
};

export { SearchContext, SearchProvider };
