const messages = {
  pt: {
    translations: {
      errors: {
        required: "Obrigatório",
      },
      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem um conta? Cadastre-se!",
        },
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
      },

      commandListModal:{
        title: "Lista de Comandos",
        buttons:{
          cancel: "Cancelar"
        },
        commands:{
          comments: "!!<sua mensagem>",
          startChatbot: "!iniciar",
          stopChatbot: "!parar",
          commandsChatbot: "!comandos",
          descriptions: {
            comments: "Comentário Interno. Use !! e digite a sua mensagem para fazer um comentário que não será enviado para o WhatsApp. Ex: !!Cobrar Mensalidade",
            startChatbot: "Habilita o chatbot neste ticket.",
            stopChatbot: "Para o chatbot neste ticket.",
            commandsChatbot: "Envia internamente a lista de comandos."
          },
        },
      },

      dashboard: {
        title: "Dashboard",
        filters: {
          startDate: "Data de início",
          endDate: "Data de término",
          status: "Status",
        },
        errors: {
          dateMax: "Você não pode selecionar uma data de início maior que a de término",
          dateMin: "Você não pode selecionar uma data de término menor que a de início",
          generic: "Ocorreu um erro!",
        },
        charts: {
          name: {
            title: "Nome",
          },
          ticketCount: {
            title: "Tickets",
          },
          perDay: {
            title: "Tickets hoje: ",
          },
          perHour: {
            title: "Tickets por hora",
          },
          perUser: {
            title: "Atendimentos por Usuários",
          },
          perContact: {
            title: "Atendimentos por Contatos",
          },
          perQueue: {
            title: "Atendimentos por Filas",
          },
          meanMedian: {
            mean: "Tempo Médio de Espera dos Tickets",
            median: "Tempo Mediano de Espera dos Tickets",
            groupWarning: "A contagem não se aplica a grupos.",
          },
          inconsistentTickets: {
            title: "Inconsistências detectadas",
            table: {
              id: "Ticket",
              user: "Usuário",
              queue: "Fila",
              connection: "Conexão",
              reason: "Problema",
              actions: "Ações"
            }
          }
        },
        dataGrid: {
          noRowsLabel: 'Sem informação',
          toolbarDensityLabel: 'Size',
          toolbarDensityCompact: 'Small',
          toolbarDensityStandard: 'Medium',
          toolbarDensityComfortable: 'Large',
        },
        messages: {
          inAttendance: {
            title: "Atendendo"
          },
          waiting: {
            title: "Aguardando"
          },
          closed: {
            title: "Finalizado"
          },
          total: {
            title: "Total",
          },
          noQueue: {
            title: "Sem fila",
          },
          unanswered: {
            title: "Sem atendente",
          },
        },

      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão excluída com sucesso!",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectWppMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
          disconnectFbMessage:
            "Tem certeza? Pode ser que precise reautenticar novamente.",
        },
        buttons: {
          add: "Adicionar Conexão",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
          whatsapp: "Whatsapp",
          altconn: "Conexão alternativa"
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            adminContent: "Houve uma falha na tentativa de iniciar sessão no WhatsApp. Por favor, verifique as configurações do servidor e o status da conexão para garantir que tudo esteja funcionando corretamente.",
            userContent: "Houve uma falha na tentativa de iniciar sessão no WhatsApp. Solicite a ajuda de um administrador para obter um novo QR Code."
          },

          disconnectedfb: {
            title: "Não foi possível estabelecer uma sessão com os serviços da Meta",
            adminContent: "Houve uma falha na tentativa de iniciar sessão nos serviços da Meta. Recomendamos tentar conectar novamente. Se o problema persistir, considere realizar a autenticação novamente utilizando o botão de adicionar conexão.",
            userContent: "Houve uma falha na tentativa de iniciar sessão nos serviços da Meta. Por favor, solicite a ajuda de um administrador para reconectar-se."
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            adminContent: "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
            userContent: "Aguarde a ação do administrador para ler o QR Code e iniciar a sessão."
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            adminContent: "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
            userContent: "A conexão com o celular foi perdida. Por favor, solicite a um administrador que reconecte."
          },
        },
        table: {
          name: "Nome",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          actions: "Ações",
          session: "Sessão",
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar {{connType}}",
          edit: "Editar {{connType}}",
        },
        form: {
          name: "Nome",
          default: "Padrão",
          history: "Importar histórico ao conectar",
          historyStart: "Data de inicio da importação",
          historyEnd: "Data de término da importação",
          farewellMessage: "Mensagem de despedida",
          awayMessage: "Mensagem de ausência de atendentes",
          isMultidevice: "Multidevice",
          transferTicketMessage: "Mensagem de Transferência do Ticket",
          reopenLastTicket: "Reabrir Ultimo Ticket",
          minutes: "Minutos",
          newticketafter: "Gerar um Novo Ticket após",
          titlenewticketafter: "Ao receber uma mensagem",
          aiMode: "Modo IA",
          queueMode: "Modo de filas",
          integratedAi: "Usar IA Integrada",
          integratedMovidesk: "Usar integração com Movidesk",
          reviewMessage: "Mensagem de avaliação pós atendimento"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        notices:{
          warning:"Atenção: Não é possível importar mensagens muito antigas do WhatsApp. Para acessá-las, use o aplicativo do WhatsApp no seu celular. "
        },
        success: "WhatsApp salvo com sucesso.",
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.",
          importMessage: "Deseja importas todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar Contatos",
          add: "Adicionar Contato",
        },
        table: {
          name: "Nome",
          whatsapp: "WhatsApp",
          company: "Empresa",
          email: "Email",
          extraInfo: "Info Extra",
          actions: "Ações",
        },
      },
      contactMediaModal: {
        title: "Mídias compartilhadas com {{contactName}}",
        you: "Você",
        file: "Arquivo",
        tabs: {
          image: "Imagens",
          video: "Vídeos",
          application: "Documentos"
        },
        buttons: {
          close: "Fechar",
        },
        noMedia: {
          video: "Nenhum vídeo para exibir",
          image: "Nenhuma imagem para exibir",
          application: "Nenhum documento para exibir"
        }

      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar {{type}}",
        },
        form: {
          mainInfo: "Dados do {{type}}",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          company: "Empresa",
          email: "Email",
          obs: "Observações",
          extraName: "Nome do campo",
          extraValue: "Valor",
          movideskPersonId: "ID Cliente Movidesk"
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "{{type}} salvo com sucesso.",
        group: "grupo",
        contact: "contato"
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta Rápida salva com sucesso.",
      },
      fileListSelectModal: {
        title: "Selecionar lista de arquivos",
        buttons: {
          cancel: "Cancelar",
        },
      },
      fileListModal: {
        title: {
          edit: "Editar lista de arquivos {{name}}",
          add: "Adicionar lista de arquivos",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          addFile: "Adicionar arquivo"
        },
        form: {
          name: "Nome",
          description: "Mensagem para enviar com arquivo",
          fileName: "Arquivo selecionado",
          noFileName: "Nenhum arquivo selecionado"
        },
        success: "Lista de arquivos salva com sucesso."
      },
      queueModal: {
        title: {
          add: "Adicionar fila",
          edit: "Editar fila",
        },
        form: {
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
          instructions: "Instruções",
          transferQueueMessage: "Mensagem de Transferência entre Setor",
          outOfWorkMessage: "Personalize a mensagem quando estiver fora do horário de expediente",
          startWorkHour: "Expediente: Abertura",
          endWorkHour: "Expediente: Fechamento",
          awayMessage: "Mensagem de ausência de atendentes",
          optionMode: "Modo de opções",
          integratedAi: "Usar IA integrada",
          aiMode: "Modo IA",
          monday: "Segunda",
          tuesday: "Terça",
          wednesday: "Quarta",
          thursday: "Quinta",
          friday: "Sexta",
          saturday: "Sábado",
          sunday: "Domingo",
          holiday: "Feriado",
          token: "Token",
          checkHourExpedient: "Checar Horário de Expediente",
          integratedMovidesk:"Usar integração com Movidesk"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          title: "Dados do agente para a integração com movidesk: {{movideskName}}",
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
          defaultQueue:"Fila padrão",
          whatsapp: "Conexão Padrão",
          agentId: "ID do agente",
          agentTeam: "Equipe do Agente"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
        featuredContacts: {
          title: "Meus Contatos em Destaque"
        },
      },
      uploads: {
        titles: {
          titleUploadMsgDragDrop: "ARRASTE E SOLTE ARQUIVOS NO CAMPO ABAIXO",
          titleFileList: "Lista de arquivo(s)"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Tag salvo com sucesso.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsFilter: {
        title: "Filtros",
        filters: {
          queues: "Filas",
          tags: "Tags",
          connections: "Conexões"
        }
      },
      tickets: {
        toasts: {
          deleted: "O ticket que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolvidos" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar tickets e contatos",
        },
        buttons: {
          showAll: "Todos",
        },

      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para fila",
        fieldConnectionLabel: "Transferir para conexão",
        fieldQueuePlaceholder: "Selecione uma fila",
        fieldConnectionPlaceholder: "Selecione uma conexão",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Atendendo",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhum ticket encontrado com esse status ou termo pesquisado",
        connectionTitle: "Conexão que está sendo utilizada atualmente.",
        queue:"Fila",
        user:"Atendente",
        subject:"Assunto",
        connection:"Conexão",
        noUser: "Nenhum",
        noQueue: "Sem fila",
        noSubject: "Sem assunto",
        noConnection:"Sem conexão",
        buttons: {
          accept: "Aceitar",
        },
        badge: {
          onreview: "Em avaliação",
          closed: "Fechado",
          expired: "Expirado"
        },
      },
      newTicketModal: {
        title: "Criar Ticket",
        fieldLabel: "Digite para pesquisar o contato",
        queueLabel: "Selecionar fila",
        connectionLabel: "Selecionar conexão",
        add: "Adicionar",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },
      exportMsgsModal: {
        title: "Exportar mensagens do ticket #{{ticketId}}",
        loading: "Carregando mensagens...",
        copyToClipboard: "Copiar para área de transferência",
        exportToTxt: "Exportar em formato TXT",
        exportToJson: "Exportar em formato JSON",
        toasts: {
          Clipboardsuccess: "Mensagens copiadas para a área de transferência. Use Ctrl+V ou equivalente para colar.",
          TXTsuccess: "Mensagens exportadas em formato TXT",
          JSONsuccess: "Mensagens exportadas em formato JSON"
        }
      },
      ticketQueueSelect: {
        title: "Definir uma fila para o ticket",
        fieldQueueLabel: "Selecionar fila",
        buttons: {
          ok: "Confirmar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          campaigns: "Campanhas",
          connections: "Conexões",
          tickets: "Tickets",
          contacts: "Contatos",
          quickAnswers: "Respostas Rápidas",
          fileList: "Listas de arquivos",
          queues: "Filas & Chatbot",
          schedules: "Agendamentos",
          tags: "Tags",
          categories: "Categorias",
          administration: "Administração",
          users: "Usuários",
          settings: "Configurações",
          integrations: "Integrações",
          reviews: "Avaliações",
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
          },
        },
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      schedules: {
        title: "Agendamentos",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir este Agendamento?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          user: "Remetente",
          contact: "Contato",
          body: "Mensagem",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
          status: "Status",
          statusTypes: {
            pending: "Pendente",
            sent: "Enviado",
            error: "Erro",
          },
          actions: "Ações",
        },

        buttons: {
          add: "Novo Agendamento",
        },
        toasts: {
          deleted: "Agendamento excluído com sucesso.",
        }
      },
      scheduleModal: {
        title: {
          add: "Novo Agendamento",
          edit: "Editar Agendamento",
        },
        form: {
          body: "Mensagem",
          contact: "Contato",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Agendamento salvo com sucesso.",
      },
      queues: {
        title: "Filas",
        table: {
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar fila",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os tickets dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.",
        },
        queueSaved: "Fila salva com sucesso",
        queueDeleted: "Fila excluída com sucesso"
      },
      chatbots: {
        title: "Opções",
        message: "Mensagem",
        confirmationModal: {
          deleteTitle: "Excluir {{option}}?",
          deleteMessage: "Tem certeza? Todas as opções internas também serão excluídas.",
        },
        tips: {
          mainTip: {
            title: "Adicione opções para construir um chatbot",
            message: "Se houver apenas uma opção, ela será escolhida automaticamente, fazendo com que o bot responda com a mensagem da opção e siga adiante.",
          },
          optionTip: {
            title: "A mensagem é obrigatória para seguir à próxima opção",
            message: "Se a mensagem não estiver definida, o bot não seguirá para a próxima opção.",
          }
        },
        addButton: "Adicionar opção",
        chatbotDeleted: "Opção excluída com sucesso",
        chatbotSaved: "Opção salva com sucesso"
      },
      queueSelect: {
        inputLabel: "Filas",
      },
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        toasts: {
          deleted: "Resposta Rápida excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      integrations: {
        title: "Integrações",
        table: {
          name: "Nome",
          type: {
            title: "Tipo",
            unknown: "Desconhecido",
            openai: "OpenAi",
            movidesk: "Movidesk",
            typebot:"Typebot",
            api: "API",
            webhook: "Webhook"
          },
          status: {
            title: "Status",
            unknown: "Desconhecido",
            active: "Ativo",
            alert: "Alerta",
            inactive: "Inativo"
          },
          actions: "Ações",
          openai: {
            usedTokens: "Tokens usados: {{count}}",
            promptTokens: "Prompt tokens: {{count}}",
            completionTokens: "Completion tokens: {{count}}",
            totalTokens: "Total tokens: {{count}}"
          }
        },
        deleteConfirmationModal: {
          title: 'Excluir integração',
          warning: 'Você tem certeza que quer excluir esta integração? Essa ação não pode ser revertida! ',
          deleteSuccess: 'Integração excluída com sucesso'
        },

        searchPlaceholder: "Pesquisar...",
        buttons: {
          create: "Adicionar integração"
        }
      },
      integrationModal: {
        title: {
          add: "Adicionar integração",
          edit: "Editar integração"
        },
        form: {
          type: "Tipo",
          name: "Nome",
          apiKey: "Chave da API",
          model: "Modelo",
          instructions: "Instruções",
          assName: "Assinatura do assistente virtual",
          temperature: "Temperatura",
          maxTokens: "Limite de tokens na resposta",
          historyLimit: "Máximo de mensagens no histórico",
          onlyTicket: "Apenas histórico do ticket",
          isOwnHosted:"Hospedagem própria",
          typebotId:"ID do fluxo Typebot",
          typebotUrl:"Domínio da API",
          options: {
            api: "API",
            webhook: "Webhook",
            openai: "OpenAi",
            movidesk: "Movidesk",
            typebot: "Typebot",
          }
        },
        success: "Integração salva com sucesso",
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar"
        }
      },
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
          editOpeningHours: "Editar horários"
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para a fila.",
        },
      },
      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        theme: {
          name: "Tema",
          options: {
            dark: "Escuro",
            light: "Claro",
          },
        },
        notifications: {
          name: "Som de notificações",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
        },
        timeCreateNewTicket: {
          name: "Mensagem de boas-vindas após",
          note: "Selecione o tempo que será necessário para abrir um novo ticket, caso o cliente entre em contatos novamente",
          options: {
            "10": "10 Segundos",
            "30": "30 Segundos",
            "60": "1 minuto",
            "300": "5 minutos",
            "1800": "30 minutos",
            "3600": "1 hora",
            "7200": "2 horas",
            "21600": "6 horas",
            "43200": "12 horas",
            "86400": "24 horas",
            "172800": "48 horas",
          },
        },
        call: {
          name: "Aceitar chamadas",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
        },
        acceptAudio:{
          name: "Aceitar mensagens de áudio",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
        },
        userCreation: {
          name: "Criação de usuário",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
        },
        anonymoustickets: {
          name: "Tickets pendentes anônimos",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
        },
        acceptonlyfirst: {
          name: "Aceitar apenas primeiro ticket",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
        },
        groupsasticket: {
          name: "Tratar grupos como tickets",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
        },
        reopenlastgroupticket: {
          name: "Reabrir último ticket fechado do grupo",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
        },
        seeAllTickets: {
          name: "Switch mostrar todos para usuários",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
        },
        requireticketsubject: {
          name: "Exigir assunto do ticket",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
        },
        showfarewellmessageswitch: {
          name: "Mostrar switch de mensagem de despedida",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
        },
        showreviewmessageswitch: {
          name: "Mostrar switch de mensagem de avaliação",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
        },
        privateticket: {
          name: "Tickets privados",
          options: {
            forusers: "Para usuários",
            forqueues: "Para filas",
            disabled: "Desativado",
          },
        },
        pendingticketorder: {
          name: "Ordenar tickets aguardando",
          options: {
            lastmsg: "Última mensagem",
            createdAt: "Por entrada"
          }
        },
        transfermsg: {
          name: "Mensagem de transferência",
          options: {
            enabled: "Ativado",
            simplified: "Simplificado",
            disabled: "Desativado",
          },
        },
        simpletransfermsg: {
          name: "Mensagem de transferência simplificada",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
        },
        showtransferswitch: {
          name: "Mostrar switch de transferência para usuários",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",
          },
        },
        openticketorder: {
          name: "Ordenar tickets em atendendo",
          options: {
            lastmsg: "Última mensagem",
            createdAt: "Por entrada"
          }
        },
        externalmsgsend: {
          title: "Envio externo de mensagens",
          obs: "(caso não houver ticket aberto)",
          options: {
            setpending: "Abrir ticket em aguardando",
            setclosed: "Abrir ticket como fechado",
            setlastclosed: "Enviar para último ticket fechado",
          }
        },
        showdeletedmsg: {
          name: "Mostrar conteúdo de mensagens excluídas",
          options: {
            enabled: "Ativado",
            disabled: "Desativado"
          }
        },
        general: {
          title: "Geral"
        },
        integration: {//settings.integration.externalmsgsend
          title: "Integração",
          description: "Informações necessárias para integração com o sistema",
          apiUrl: "URL de solicitação",
          headers: "Headers",
          jsonFormat: "Formato JSON",
          connections: "ID das conexões",
          queues: "ID das filas",
          users: "ID dos usuários",
          examples: {
            number: "NUMERO_DO_CONTATO",
            body: "MENSAGEM",
            userId: "ID_DO_USUARIO",
            queueId:"ID_DA_FILA",
            tag:"NOME_DA_TAG",
            whatsappId: "ID_DA_CONEXAO",
          }
        },
        helpers: {
          userCreation: "Habilita/Desabilita a criação do usuário na tela de registro",
          acceptCalls: `Ativado: Quando um contato realizar uma chamada e não for atendida será enviada uma mensagem.
          Desativado: Quando um contato realizar uma chamada a
          ligação será automaticamente rejeitada e enviará uma mensagem informando que não é possível realizar chamadas.`,
          acceptAudio:`Ativado: Aceita áudios como mensagem. Desativado: Envia uma mensagem informando que não aceita áudios.`,
          transferMsg: `*Ativado*: Habilita mensagens ao transferir um contato para outra fila, conexão e/ou usuário.\n
                        *Simplificado*: Habilita mensagens ao transferir um contato para outra fila, conexão e/ou usuário. A mensagem de transferência apenas irá informar o atendende e a fila que o contato foi transferido.\n
                        *Desativado*: Desabilita mensagens ao transferir um contato para outra fila, conexão e/ou usuário.`,
          simpletransfermsg: "Mensagem de transferência apenas irá informar o atendende e a fila que o contato foi transferido.",
          showtransferswitch: "Habilita/Desabilita a visualização do switch que permite desativar a mensagem de transferência ao transferir um ticket. Esta ação aplica-se somente para usuários, os admnitradores permanecem podendo visualizar o switch",
          externalSend: {
            title: "Qualquer mensagem que for enviada através das campanhas, ou direto pelo whatsapp, ou através da API vai ser tratado de acordo com essa configuração.",
            opt1: "-Abrir ticket em aguardando -> Quando enviar uma campanha para um contato e ele não tiver nenhum ticket aberto/pendente, será criado um novo ticket que cairá na aba de aguardando.",
            opt2: "-Abrir ticket como fechado -> Se você enviar uma campanha para um contato e ele não tiver nenhum ticket aberto/pendente, será criado um novo ticket que cairá direto como um ticket fechado.",
            opt3: "-Enviar para último ticket fechado -> Se você enviar uma campanha para um contato e ele não tiver nenhum ticket aberto/pendente, a mensagem irá aparecer no último ticket que foi fechado.",
          },
          showdeletedmsg: "Quando uma mensagem é excluída mantém o conteúdo da mensagem visível para os usuários.",
          anonymousTickets: "Tickets na aba aguardando ficam com as informações do contato ocultas, ficando visível apenas a hora de envio, conexão e fila. Apenas usuários com permissão de administrador podem visualizar as informações do contato.",
          acceptonlyfirst: "Apenas primeiro ticket na aba aguardando possui botão de aceitar. Somente usuários com permissão de administrador podem aceitar tickets qualquer ticket.",
          privateticket: `*Desativado*: Todos os usuários têm acesso completo a todos os tickets, adequado para transparência.
                          *Para Filas*: Acesso restrito aos membros de equipes específicas responsáveis pelas filas.
                          *Para Usuários*: Restrito aos usuários aos quais os tickets foram atribuídos, garantindo confidencialidade.`,
          seeAllTickets: "Os usuários podem visualizar o switch de mostrar todos os tickets das filas que ele possui.",
          pendingticketorder: "Ordena os tickets na aba aguardando",
          openticketorder: "Ordena os tickets na aba atendendo",
          groupsasticket: "Ative essa funcionalidade para gerenciar grupos de forma semelhante aos tickets. Com essa função habilitada, seus grupos passam a funcionar como tickets de contatos normais.",
          reopenlastgroupticket: "Ao ativar essa funcionalidade, o sistema abrirá sempre o último ticket fechado, assegurando uma identificação constante ao longo do tempo. É relevante observar que a contagem de atendimentos será registrada exclusivamente para o usuário ativo no ticket. Em casos de transferência, a contagem será reiniciada.",
        }
      },

      fowardingModal: {
        title: "Encaminhar mensagem",
        searchPlaceholder: "Pesquisar...",
        notices: {
          maxSelect: "Você pode selecionar no maximo {{max}} contatos"
        }
      },

      sendContactModal: {
        title: "Enviar contatos",
        searchPlaceholder: "Pesquisar",
        buttons: {
          cancel: "Cancelar",
          send: "Enviar",
        },
        nothingHere: "Nada aqui!",
        nothingHereDescription: "Nenhum contato encontrado"
      },
      closeTicketModal: {
        title: "Deseja resolver o ticket #{{ticketId}}?",
        form: {
          subject: "Assunto do ticket",
          startReviewService: "Solicitar avaliação do atendimento",
          sendFarewellMsg: "Enviar mensagem de despedida",
          sendTicketToMovidesk: "Criar ticket no Movidesk",
          defineMovideskId: "Atribuir à um ticket existente no Movidesk (deixe vazio para criar um novo)",
          category:"Categoria",
          status:"Status",
          justification:"Justificativa"
        },
        buttons: {
          cancel: "Cancelar",
          ok: "Resolver"
        }
      },
      campaignModal: {
        form: {
          name: "Nome",
          sendAt: "Data de envio",
          connection: 'Conexão',
          message: "Mensagem",
          min: "Mínimo",
          max: "Máximo",
          mean: "Médio",
          start: "Início",
          end: "Fim",
          interval: "Intervalo de envio entre mensagens:",
          restrictTime: "Não enviar entre:",
          estimatedTime: "Tempo estimado de envio para {{count}} contatos"
        },
        title: {
          edit: 'Editar campanha {{name}}',
          add: 'Adicionar campanha'
        },
        contacts: {
          selecteds: "Selecionados",
          sents: "Enviados",
          buttons: {
            selectMax: "Selecionar mais {{max}} contatos",
            unselectAll: "Desmarcar todos",
            importCSV:"Importar CSV"
          },
          notices: {
            maxSelect: "Você pode selecionar no máximo {{max}} contatos por campanha!",
            showingResults: "Exibindo {{count}} de {{total}} contatos",
            selecteds: "Contatos selecionados",
            sents: "Contatos enviados",
            alrdSent: "Já enviado",
          },
        },
        notices: {
          warning: 'Atenção: Uso excessivo de campanhas de mensagens pode bloquear seu número no WhatsApp. Evite abusos e spam.',
          sendLocked: 'ENVIO BLOQUEADO! -- Você pode enviar somente UMA campanha em intervalos de 3 horas!',
          sendUnlocked: 'ENVIO LIBERADO! -- Você pode enviar somente UMA campanha em intervalos de 3 horas!',
          noContacts: 'Não há nenhum contato na campanha!',
          helper: "A as mensagens são enviadas de forma aleatória para os contatos. A mensagem irá aparecer da seguinte forma para o contato: 'Olá bom dia {NOME DO CONTATO}! {MENSAGEM 1,2 OU 3}'.",
        },
      },
      campaigns: {
        title: 'Campanhas',
        buttons: {
          send: 'Enviar',
          create: 'Criar campanha',
          add: 'Adicionar',
          cancel: 'Cancelar',
          edit: 'Salvar',
        },
        sendConfirmationModal: {
          title: 'Você tem certeza que deseja enviar esta campanha',
          warning: 'Atenção! O uso de campanhas de envio de mensagens em massa pode resultar no bloqueio do seu número de WhatsApp. Isso acontece porque o aplicativo proíbe o envio de mensagens em massa para proteger os usuários de spam e abuso.',
          sentSuccess: 'Campanha enviada com sucesso!',
        },
        deleteConfirmationModal: {
          title: 'Excluir campanha',
          warning: 'Você tem certeza que quer excluir esta campanha? Essa ação não pode ser revertida! ',
          deleteSuccess: 'Campanha excluída com sucesso'
        },
        table: {
          name: 'Nome',
          status: 'Status',
          sendAt: 'Data de envio',
          messages: 'Mensagens',
          message: "Mensagem",
          whatsapp: 'Whatsapp',
          connection: 'Conexão',
          contacts: 'Contatos',
          lastSent: 'Último envio',
          neverSent: 'Nunca enviada',
          actions: 'Ações',
          statusTypes: {
            pending: "Pendente",
            sending: "Enviando",
            sent: "Concluído",
            error: "Erro",
          },
        },
      },
      tags: {
        title: 'Tags',
        buttons: {
          create: 'Adicionar Tag',
          add: 'Adicionar',
          cancel: 'Cancelar',
          edit: 'Salvar',
        },
        deleteConfirmationModal: {
          title: 'Excluir tag',
          warning: 'Você tem certeza que quer excluir esta tag? Esta ação não pode ser revertida!',
          deleteSuccess: 'Tag excluída com sucesso'
        },
        contactsModal: {
          title: 'Adicionar tag {{tagName}} aos contatos',
          buttons: {
            unselectAll: 'Desmarcar todos',
          },
          notices: {
            updateSuccess: 'Lista de contatos da tag foi atualizada!',
            selecteds: 'Contatos selecionados',
          },
        },
        tagModal: {
          title: {
            edit: 'Editar tag',
            add: 'Adicionar tag',
          },
          notices: {
            addSuccess: 'Tag adicionada com sucesso',
          }

        },
        form: {
          name: 'Nome',
          color: 'Cor',
        },
        table: {
          name: 'Nome',
          color: 'Cor',
          contacts: 'Contatos',
          actions: 'Ações'
        },
      },
      categories: {
        title: 'Categorias',
        buttons: {
          create: 'Adicionar categoria',
          add: 'Adicionar',
          cancel: 'Cancelar',
          edit: 'Salvar',
        },
        searchPlaceholder: "Pesquisar...",
        deleteConfirmationModal: {
          title: 'Excluir categoria',
          warning: 'Você tem certeza que quer excluir esta categoria? Esta ação não pode ser revertida!',
          deleteSuccess: 'Categoria excluída com sucesso'
        },
        ticketsModal: {
          title: 'Adicionar categoria {{categoryName}} aos tickets',
          buttons: {
            unselectAll: 'Desmarcar todos',
          },
          notices: {
            updateSuccess: 'Lista de tickets da categoria foi atualizada!',
            selecteds: 'Tickets selecionados',
          },
        },
        categoryModal: {
          title: {
            edit: 'Editar categoria',
            add: 'Adicionar categoria',
          },
          notices: {
            addSuccess: 'Categoria adicionada com sucesso',
          }

        },
        form: {
          name: 'Nome',
          color: 'Cor',
        },
        table: {
          name: 'Nome',
          number: 'Número',
          color: 'Cor',
          tickets: 'Tickets',
          actions: 'Ações',
          details: 'Detalhes',
          date: 'Data',
          createdAt: 'Criado em: ',
          updatedAt: 'Atualizado em: '
        },
      },
      reviews: {
        title: "Avaliações",
        table: {
          reviewId:"ID",
          contact: "Contato",
          company:"Empresa",
          email:"Email",
          user: "Usuário",
          ticket: "Ticket",
          comment: "Comentário",
          queue:"Fila",
          rating: "Avaliação",
          ratedIn: "Avaliado em"
        },
        filter: {
          user: "Usuário",
          queue: "Fila",
          dateStart: "Data de início",
          dateEnd: "Data de término",
          rating: "Avaliação"
        },
        buttons:{
          export:"Exportar CSV"
        },
        toasts:{
          CSVsuccess:"Avaliações exportadas com sucesso"
        }
      },
      fileList: {
        title: "Listas de arquivos",
        buttons: {
          add: "Adicionar lista"
        },
        table: {
          name: "Nome",
          files: "Arquivos",
          actions: "Ações"
        },
        deleteConfirmationModal: {
          title: "Excluir lista de arquivos",
          warning: "Você tem certeza que quer excluir esta lista de arquivos? Esta ação não pode ser revertida!",
          deleteSuccess: "Lista de arquivos excluída com sucesso"
        }
      },
      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            return: "Retornar",
            resolve: "Resolver",
            quickResolve:"Fechar rápido",
            new: "Novo ticket",
            reopen: "Reabrir",
            accept: "Aceitar",
            open:"Abrir",
          },
        },
        cannotScrollToMessage: "Não é possível ir até a mensagem pois ela é muito antiga ou não foi importada",
        logs:{
          chatbotEnabled:"Chatbot habilitado",
          chatbotDisabled:"Chatbot desabilitado"
        },
        noImage:"Não foi possível carregar a imagem"
      },
      messagesInput: {
        placeholderOpen: "Digite uma mensagem ou tecle ''/'' para utilizar respostas rápidas",
        placeholderClosed:
          "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
        attachments: {
          document: "Documento",
          medias: "Fotos e vídeos",
          fileList: "Lista de arquivos",
          contact: "Contato",
          commandList: "Comandos"
        },
      },
      openingHoursModal: {
        title: "Editar horários de atendimento dos usuários",
        form: {
          start: "Início",
          end: "Fim"
        },
        notices: {
          onCopy: "Você está copiando o horário do usuário {{userName}}. Selecione os usuários que também vão adotar esse horário.",
          nothingHere: "Nada aqui!",
          nothingHereDescription: "Não há nenhum usuário para listar"
        },
        buttons: {
          add: "Adicionar horário",
          copy: "Copiar horário",
          cancel: "Cancelar",
          edit: "Salvar",
          paste: "Colar"
        },
        toasts: {
          success: "Horários salvos com sucesso!",
        },
        days: {
          sunday: "Domingo",
          monday: "Segunda",
          tuesday: "Terça",
          wednesday: "Quarta",
          thursday: "Quinta",
          friday: "Sexta",
          saturday: "Sábado",

        },

      },
      ticketDrawer: {
        header: "Detalhes",

        name: "Nome",
        number: "Número",
        subject: "Assunto",
        email: "Email",
        company: "Empresa",
        queue: "Fila",
        connection: "Conexão",
        ticket: "Ticket",
        buttons: {
          edit: "Editar {{type}}",
          medias: "Ver mídias",
        },
        tags: "Tags do {{type}}",
        categories: "Categorias do ticket",
        group: "Grupo",
        contact: "Contato",
        extraInfo: "Outras informações",
        obs: "Observações",
      },
      searchDrawer: {
        header: "Pesquisar mensagens"
      },
      ticketOptionsMenu: {
        delete: "Deletar",
        transfer: "Transferir",
        search: "Pesquisar",
        schedule: "Agendar",
        exportMsgs: "Exportar mensagens",
        confirmationModal: {
          title: "Excluir ticket #{{ticketId}} do contato {{contactName}}?",
          message:
            "Todas as mensagens relacionadas ao ticket serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
        showLogs: "Ver logs",
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        chat: 'Conversar com',
        delete: "Deletar",
        reply: "Responder",
        foward: "Encaminhar",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      logs: {
        title: "Logs",
        showingText: "Exibindo {{count}} do total de {{total}} resultados",
        ticketAction: "O usuário {{username}}[{{userId}}] realizou {{action}} no Ticket #{{ticketId}} em {{time}}",
        actions: {
          transfer: "uma transferência",
          action: "um {{action}}",
          unchanged: "Nenhum valor alterado"
        },
        fields: {
          oldValues: "Valores antigos",
          newValues: "Valores novos",
          user: "Usuário: {{username}}",
          status: "Status: {{status}}",
          queue: "Fila: {{queue}}"
        },
        buttons: {
          close: "Fechar"
        }
      },
      changelog: {
        title: "Notas de atualização",
        loading: "Obtendo informações sobre atualizações...",
        loadfail: "Não foi possível obter informações sobre atualizações.",
      },
      backendErrors: {
        GENERIC_ERROR: "Ocorreu um erro",
        INTERNAL_SERVER_ERROR: "Erro interno do servidor",
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_DEF_WAPP_FOUND: "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED: "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT: "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA: "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS: "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG: "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_FB_NOT_INITIALIZED: "Esta sessão do Facebook não foi inicializada. Verifique a página de conexões.",
        ERR_IG_NOT_INITIALIZED: "Esta sessão do Instagram não foi inicializada. Verifique a página de conexões.",
        ERR_SENDING_FB_MSG: "Erro ao enviar mensagem do Facebook. Verifique a página de conexões.",
        ERR_SENDING_IG_MSG: "Erro ao enviar mensagem do Instagram. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um ticket aberto de número #{{content}} para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED: "A criação do usuário foi desabilitada pelo administrador.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_USER_OUT_OF_OPENING_HOUR: "Fora do seu horário de atendimento. Verifique seu horário e tente novamente.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_INVALID_CONTACT_NUMBER_FORMAT: "Número de contato inválido. É permitido apenas números.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum ticket encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_NO_CONN_FOUND: "Nenhuma conexão encontrada com este ID. ",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar ticket no banco de dados.",
        ERR_FETCH_WAPP_MSG: "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
        ERR_QUEUE_NAME_ALREADY_EXISTS: "Já existe uma fila com este nome.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS: "Esta cor já está em uso, escolha outra.",
        ERR_QUEUE_INVALID_COLOR: "Cor inválida.",
        ERR_QUEUE_NOT_FOUND: "Nenhuma fila encontrada com este ID.",
        ERR_CATEGORY_NAME_ALREADY_EXISTS: "Já existe uma categoria com este nome.",
        ERR_CATEGORY_COLOR_ALREADY_EXISTS: "Esta cor já está em uso, escolha outra.",
        ERR_CATEGORY_INVALID_COLOR: "Cor inválida.",
        ERR_CATEGORY_NOT_FOUND: "Nenhuma categoria encontrada com este ID.",
        ERR_TAG_NAME_ALREADY_EXISTS: "Já existe uma tag com este nome.",
        ERR_TAG_COLOR_ALREADY_EXISTS: "Esta cor já está em uso, escolha outra.",
        ERR_TAG_INVALID_COLOR: "Cor inválida.",
        ERR_TAG_NOT_FOUND:"Nenhuma tag encontrada com este ID.",
        ERR_CAMPAIGN_NAME_ALREADY_EXISTS: "Já existe uma campanha com este nome.",
        ERR_CAMPAIGN_ALREADY_STARTED: "Não é possível editar uma campanha que já iniciou.",
        ERR_WAPP_GREETING_REQUIRED: "A mensagem de saudação é obrigatório quando há mais de uma fila.",
        ERR_FWD_MSG_CONTACT_NOT_FOUND: "Erro ao encaminhar para os contatos {{content}}. Verifique se todos possuem tickets abertos.",
        ERR_FWD_MSG_NOT_FOUND: "Não foi possível encontrar a mensagem no Whatsapp, pode ser que ela tenha sido excluída.",
        ERR_NO_FILELIST_FOUND: "Nenhuma lista de arquivos encontrada com este ID.",
        ERR_FILELIST_NAME_ALREADY_EXISTS: "Já existe uma lista de arquivos com este nome.",
        MESSAGE_NOT_SUPPORTED: "_As características desta mensagem, não permitem a visualização dela fora do WhatsApp. Consulte o WhatsApp em seu celular para ver detalhes da mensagem._",
        ERR_FAILED_TO_WRITE_FILES: "Não foi possível salvar os arquivos! Verifique se não existem arquivos com o mesmo nome selecionados.",
        ERR_FAILED_TO_CONNECT_SESSION: "Não foi possível reconectar esta sessão.",
        ERR_INTEGRATION_NOT_FOUND: "Integração não encontrada!",
        ERR_CANNOT_CREATE_MORE_MOVIDESK_INTEGRATIONS: "No momento não é possível criar mais integrações com o Movidesk",
        ERR_MOVIDESK_API_BAD_REQUEST: "Houve um erro ao comunicar com a API do Movidesk",
        ERR_INVALID_MOVIDESK_AGENT_DATA_SET: "Dados de agente Movidesk inválidos!",
        ERR_MOVIDESK_API_CANNOT_CREATE_PERSON:"Não foi possível criar ccliente (person) no Movidesk",
        ERR_SOCKET_DISCONNECTED: "Houve um erro de conexão com websocket",
        ERR_NETWORK: "Não foi possível conectar-se ao servidor",
        ERR_BAD_REQUEST: "Houve um erro ao realizar solicitações ao servidor"
      },
    },
  },
}

export { messages };
