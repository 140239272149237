import React, { useState } from 'react';

const ControlPanel =() =>{
    const [valor,setaValor]=useState('');
    setInterval(()=>setaValor(window.location.hash.replace('#access_token=', '')),1000)
    return(
        <>
            {valor}
            <div style={{backgroundColor:'#ffffff'}}></div>
        </>
    )
}
export default ControlPanel;