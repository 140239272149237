import { Avatar, Badge, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, InputAdornment, List, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MainHeader from "../MainHeader";
import { i18n } from "../../translate/i18n";
import { useEffect, useState } from "react";
import useContacts from "../../hooks/useContacts";
import AvatarTooltip from "../AvatarTooltip";
import { Close, Info, Remove, Search } from "@mui/icons-material";
import ButtonWithSpinner from "../ButtonWithSpinner";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles((theme) => ({
    listItemButton: {
        borderRadius: 4,
    },
    noFileListText: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4",
    },

    noFileListTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px",
    },

    noFileListDiv: {
        display: "flex",
        height: "100px",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const SendContactModal = ({ open, onClose }) => {
    const classes = useStyles();
    const { ticketId } = useParams();
    const [searchParam, setSearchParam] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const { contacts, loading, hasMore, count } = useContacts({ searchParam, pageNumber });

    useEffect(() => {
        setPageNumber(1);
    }, [searchParam]);

    const handleSelectContact = (contact) => {
        let newValues;
        if (selectedContacts.includes(contact)) {
            newValues = selectedContacts.filter((value) => value !== contact);
        } else {
            newValues = [...selectedContacts, contact];
        }
        setSelectedContacts(newValues);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleClose = () => {
        setSelectedContacts([]);
        setSearchParam('');
        setPageNumber(1);
        onClose();
    };

    const handleSendContacts = async () => {
        if (!ticketId) return;
        setIsSubmiting(true);

        try {
            await api.post(`/messages/${ticketId}/send-contact`, {contacts:selectedContacts});
        } catch (err) {
            toastError(err);
        }
        setIsSubmiting(false);
        handleClose();
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <MainHeader>
                <DialogTitle
                    color="textPrimary"
                    id="contactlist-dialog-title"
                >
                    {i18n.t("sendContactModal.title")}
                </DialogTitle>
                <TextField
                    fullWidth
                    placeholder={i18n.t("sendContactModal.searchPlaceholder")}
                    type="search"
                    value={searchParam}
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search style={{ color: "gray" }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </MainHeader>
            <div>
                {selectedContacts.length > 0 && (
                    <>
                        <Divider />
                        <div
                            style={{
                                display: 'flex',
                                overflowX: 'auto',
                                overflowY: 'hidden',

                            }}
                        >
                            {selectedContacts.map((contact, index) => (


                                <Button
                                    key={index}
                                    style={{
                                        flex: '0 0 auto',
                                        width: '100px',
                                        padding: '8px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'start',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                    }}
                                    onClick={() => handleSelectContact(contact)}
                                >
                                    <Badge
                                        variant="outlined"
                                        color="secondary"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        size="small"
                                        badgeContent={<Close fontSize="6px" />}>

                                        <Avatar src={contact.profilePicUrl} />
                                    </Badge>
                                    <Typography
                                        style={{ maxWidth: 100, align: "center" }}
                                        noWrap
                                        variant="body2"
                                    >
                                        {contact.name}
                                    </Typography>
                                </Button>

                            ))}
                        </div>
                    </>
                )}
            </div>
            <DialogContent dividers padding={0} onScroll={handleScroll} style={{ minHeight: 230 }}>
                <List style={{ paddingTop: 0 }}>
                    {contacts.length > 0 ? contacts
                        .filter((contact) => !contact.isGroup)
                        .map((contact) =>
                            <>
                                <ListItemButton key={contact.id} className={classes.listItemButton} onClick={() => handleSelectContact(contact)}>
                                    <ListItemIcon>
                                        <FormControlLabel
                                            control={<Checkbox
                                                value={contact.id}
                                                checked={selectedContacts.some(current => current.id === contact.id)}
                                                size='small'
                                            />}
                                        />
                                    </ListItemIcon>
                                    <ListItemAvatar>
                                        {contact.extraInfo?.length > 0 ?
                                            <AvatarTooltip
                                                arrow
                                                icon={<Info />}
                                                placement="right"
                                                title={
                                                    <>
                                                        {contact.extraInfo?.length > 0 && contact.extraInfo.map((info) =>
                                                            <Typography>
                                                                {`${info.name}: ${info.value}`}
                                                            </Typography>
                                                        )}
                                                    </>
                                                }
                                            >
                                                <Avatar src={contact.profilePicUrl} />
                                            </AvatarTooltip>
                                            : <Avatar src={contact.profilePicUrl} />
                                        }
                                    </ListItemAvatar>
                                    <ListItemText primary={
                                        <span className={classes.contactNameWrapper}>
                                            <Typography
                                                className={classes.contactNameBreaker}
                                                noWrap
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                                {contact.company ?
                                                    <>
                                                        {contact.name}
                                                        <Typography
                                                            noWrap
                                                            component="span"
                                                            variant="body2"
                                                            color="textSecondary"
                                                        >
                                                            {` (${contact.company}) `}
                                                        </Typography>
                                                        - {contact.number}

                                                    </>
                                                    : `${contact.name} - ${contact.number}`
                                                }
                                            </Typography>
                                        </span>
                                    }
                                        secondary={
                                            <div className={classes.chipContainer}>
                                                {contact.tags && contact?.tags.map(tag => (
                                                    <Chip style={{ backgroundColor: tag.color, height: '13px', margin: '0px 2px', fontSize: '11px' }} size='small' label={tag?.name} />
                                                ))}
                                            </div>
                                        }
                                    />
                                </ListItemButton>
                            </>
                        ) : !loading &&
                    <div className={classes.noFileListDiv}>
                        <span className={classes.noFileListTitle}>
                            {i18n.t("sendContactModal.nothingHere")}
                        </span>
                        <p className={classes.noFileListText}>
                            {i18n.t("sendContactModal.nothingHereDescription")}
                        </p>
                    </div>}
                    {loading &&
                        <div style={{ textAlign: "center", padding: 10 }}>
                            <CircularProgress />
                        </div>
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleClose}
                >
                    {i18n.t("sendContactModal.buttons.cancel")}
                </Button>
                <ButtonWithSpinner
                    variant="contained"
                    onClick={handleSendContacts}
                    color="primary"
                    disabled={isSubmiting}
                    loading={isSubmiting}
                >
                    {i18n.t("sendContactModal.buttons.send")}
                </ButtonWithSpinner>
            </DialogActions>
        </Dialog>
    )
}
export default SendContactModal;