import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import makeStyles from '@mui/styles/makeStyles';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import api from "../../services/api";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import { Formik, Field, FieldArray } from "formik";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import ConfirmationModal from "../ConfirmationModal";
import { i18n } from "../../translate/i18n";
import Switch from "@mui/material/Switch";
import { FormControlLabel } from "@mui/material";
import { Help } from "@mui/icons-material";
import useUsers from "../../hooks/useUsers";
import useQueues from "../../hooks/useQueues";
import useFileLists from "../../hooks/useFileList";
import useIntegrations from "../../hooks/useIntegrations";

const QueueSchema = Yup.object().shape({
    options: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().min(4, "too short").required("Required"),
            })
        )
        .required("Must have friends"),
});

const useStyles = makeStyles((theme) => ({
    greetingMessage: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
    Box: {
        cursor: "pointer",
        alignItems: "center",
    },
    textField: {
        marginRight: theme.spacing(1),
    },
    chatbotOptions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
}));

function getStepContent(step) {
    return <ChatbotOptions chatBotId={step} />;
}

const ChatbotOptions = (props) => {
    const initialState = {
        name: "",
        greetingMessage: "",
        type: "text",
        toQueueId: null,
        toUserId: null,
        fileListId: null,
        options: [],
    };

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(-1);
    const [steps, setSteps] = React.useState(initialState);
    const [loading, setLoading] = React.useState(false);
    const [isStepContent, setIsStepContent] = React.useState(true);
    const [isNameEdit, setIsNamedEdit] = React.useState(null);
    const [queues, setQueues] = useState([]);
    const { findAll } = useQueues();
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const { users, loading: loadingUsers } = useUsers({ searchParam, pageNumber });
    const { fileLists } = useFileLists({ showAll: true });
    const { integrations } = useIntegrations({ showAll: true });
    const [isGreetingMessageEdit, setGreetingMessageEdit] = React.useState(null);
    const [selectedQueue, setSelectedQueue] = React.useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

    const handleSaveBot = async (values) => {
        try {
            if (props.chatBotId) {
                await api.put(`/chatbot/${props.chatBotId}`, values);
            } else {
                await api.post("/chatbot", values);
            }
            toast.success(i18n.t("chatbots.chatbotSaved"));
            // setActiveStep(-1)
            const { data } = await api.get(`/chatbot/${props.chatBotId}`);

            setSteps(initialState);
            setSteps(data);
            setIsNamedEdit(null);
            setGreetingMessageEdit(null);

            setSteps(data);
        } catch (err) {
            toastError(err);
        }
    };
    useEffect(() => {
        const loadQueues = async () => {
            const list = await findAll();
            //setAllQueues(list);
            setQueues(list);
        }
        loadQueues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLoading(true);

        const delayDebounceFn = setTimeout(() => {
            const fetchList = async () => {
                try {
                    const { data } = await api.get(`/chatbot/${props.chatBotId}`);
                    setSteps(data);
                    setLoading(false);
                } catch (err) {
                    toastError(err?.message || i18n.t("Error on fetch chatbot list"))
                }
            };
            fetchList();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [props.chatBotId]);

    useEffect(() => {
        if (activeStep === isNameEdit) {
            setIsStepContent(false);
        } else {
            setIsStepContent(true);
        }
    }, [isNameEdit, activeStep]);

    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
        setSelectedQueue(null);
    };

    const handleDeleteQueue = async (queueId) => {
        try {
            await api.delete(`/chatbot/${queueId}`);
            const { data } = await api.get(`/chatbot/${props.chatBotId}`);
            setSteps(initialState);
            setSteps(data);
            setIsNamedEdit(null);
            setGreetingMessageEdit(null);
            setSteps(data);
            toast.success(i18n.t("chatbots.chatbotDeleted"));
        } catch (err) {
            toastError(err);
        }
        setSelectedQueue(null);
    };

    return (
        <div className={classes.root}>
            <ConfirmationModal
                title={
                    selectedQueue &&
                    `${i18n.t("chatbots.confirmationModal.deleteTitle", { option: selectedQueue.name })}`
                }
                open={confirmModalOpen}
                onClose={handleCloseConfirmationModal}
                onConfirm={() => handleDeleteQueue(selectedQueue.id)}
            >
                {i18n.t("chatbots.confirmationModal.deleteMessage")}
            </ConfirmationModal>

            {!loading && (
                <div>
                    <Formik
                        initialValues={steps}
                        validateOnChange={false}
                        enableReinitialize={true}
                        validationSchema={QueueSchema}
                        children={({
                            touched,
                            errors,
                            isSubmitting,
                            values,
                            handleSubmit,
                        }) => (
                            <FieldArray name="options">
                                {({ push, remove }) => (
                                    <>
                                        <Stepper
                                            nonLinear
                                            activeStep={activeStep}
                                            orientation="vertical"
                                        >
                                            {values.options &&
                                                values.options.length > 0 &&
                                                values.options.map((info, index) => (
                                                    <Step
                                                        key={`${info.id ? info.id : index}-options`}
                                                        onClick={() => setActiveStep(index)}
                                                    >
                                                        <StepLabel key={`${info.id}-options`}>
                                                            {isNameEdit !== index &&
                                                                steps.options[index]?.name ? (
                                                                <div
                                                                    className={classes.greetingMessage}
                                                                    variant="body1"
                                                                >
                                                                    <div>
                                                                        <div
                                                                            className={classes.greetingMessage}
                                                                        >
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="span"
                                                                            >
                                                                                {i18n.t("Opção") + ":"}
                                                                            </Typography>
                                                                            {values.options[index].name}
                                                                        </div>
                                                                        <div
                                                                            className={classes.greetingMessage}
                                                                            variant="body1"
                                                                        >
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="body1"
                                                                            >
                                                                                {i18n.t("Tipo") + ":"}
                                                                            </Typography>
                                                                            {steps.options[index].type === "text" && i18n.t("Texto com opções")}
                                                                            {steps.options[index].type === "queue" && i18n.t("Redirecionar para fila")}
                                                                            {steps.options[index].type === "attendant" && i18n.t("Redirecionar para atendente")}
                                                                            {steps.options[index].type === "fileList" && i18n.t("Lista de arquivos")}
                                                                            {steps.options[index].type === "integration" && i18n.t("Integração")}
                                                                        </div>
                                                                    </div>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => {
                                                                            setIsNamedEdit(index);
                                                                            setIsStepContent(false);
                                                                        }}
                                                                        className={classes.iconButton}
                                                                    >
                                                                        <EditIcon />
                                                                    </IconButton>

                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setSelectedQueue(info);
                                                                            setConfirmModalOpen(true);
                                                                        }}
                                                                        size="small"
                                                                        color="secondary"
                                                                    >
                                                                        <DeleteOutline />
                                                                    </IconButton>
                                                                </div>
                                                            ) : (
                                                                <div className={classes.chatbotOptions}>
                                                                    <Field
                                                                        as={TextField}
                                                                        label={i18n.t("Nome da opção")}
                                                                        name={`options[${index}].name`}
                                                                        variant="outlined"
                                                                        margin="dense"
                                                                        color="primary"
                                                                        disabled={isSubmitting}
                                                                        autoFocus
                                                                        error={
                                                                            touched?.options?.[index]?.name &&
                                                                            Boolean(errors.options?.[index]?.name)
                                                                        }
                                                                        className={classes.textField}
                                                                    />
                                                                    <FormControl style={{ minWidth: "150px" }} className={classes.settingOption}>
                                                                        <InputLabel id="option-selection-input-label">
                                                                            {"Selecione o tipo da opção"}
                                                                        </InputLabel>
                                                                        <Field
                                                                            as={Select}
                                                                            margin="dense"
                                                                            label="Selecione o tipo da opção"
                                                                            labelId="option-selection-input-label"
                                                                            name={`options[${index}].type`}
                                                                            error={
                                                                                touched?.options?.[index]?.type &&
                                                                                Boolean(
                                                                                    errors.options?.[index]?.type
                                                                                )
                                                                            }
                                                                            variant="outlined"
                                                                            className={classes.settingOption}
                                                                        >
                                                                            <MenuItem value="text">Texto com opções</MenuItem>
                                                                            <MenuItem value="queue">Fila</MenuItem>
                                                                            <MenuItem value="attendant">Atendente</MenuItem>
                                                                            <MenuItem value="fileList">Lista de arquivos</MenuItem>
                                                                            <MenuItem value="integration">Integração</MenuItem>
                                                                        </Field>
                                                                    </FormControl>

                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() =>
                                                                            values.options[index].name
                                                                                ? handleSaveBot(values)
                                                                                : null
                                                                        }
                                                                        disabled={isSubmitting}
                                                                        color="primary"
                                                                    >
                                                                        <SaveIcon />
                                                                    </IconButton>

                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => remove(index)}
                                                                        disabled={isSubmitting}
                                                                        color="secondary"
                                                                    >
                                                                        <DeleteOutline />
                                                                    </IconButton>
                                                                </div>
                                                            )}
                                                        </StepLabel>

                                                        {isStepContent && steps.options[index] && (
                                                            <StepContent>
                                                                <>
                                                                    {isGreetingMessageEdit !== index ? (
                                                                        <div className={classes.greetingMessage}>
                                                                            <div>
                                                                                {(steps.options[index].type !== "queue" || steps.options[index]?.type !== "integration") &&
                                                                                    <div
                                                                                        className={classes.greetingMessage}
                                                                                    >
                                                                                        <Typography
                                                                                            color="textSecondary"
                                                                                            variant="body1"
                                                                                        >
                                                                                            {i18n.t("chatbots.message") + ":"}
                                                                                        </Typography>
                                                                                        {values.options[index].greetingMessage}
                                                                                    </div>
                                                                                }
                                                                                {steps.options[index].type !== "text" &&
                                                                                    <div
                                                                                        className={classes.greetingMessage}
                                                                                    >
                                                                                        <Typography
                                                                                            color="textSecondary"
                                                                                            variant="body1"
                                                                                        >
                                                                                            {steps.options[index].type === "queue" && i18n.t("Fila")}
                                                                                            {steps.options[index].type === "attendant" && i18n.t("Atendente")}
                                                                                            {steps.options[index].type === "fileList" && i18n.t("Lista de arquivos")}
                                                                                            {steps.options[index].type === "integration" && i18n.t("Integração")}
                                                                                        </Typography>
                                                                                        {steps.options[index].type === "queue" &&
                                                                                            queues.find((q) => q.id === steps.options?.[index]?.toQueueId)?.name
                                                                                        }
                                                                                        {steps.options[index].type === "attendant" &&
                                                                                            users.find((user) => user.id === steps.options?.[index]?.toUserId)?.name
                                                                                        }
                                                                                        {steps.options[index].type === "fileList" &&
                                                                                            fileLists.find((fileList) => fileList.id === steps.options?.[index]?.fileListId)?.name
                                                                                        }
                                                                                        {steps.options[index].type === "integration" &&
                                                                                            integrations.find((integration) => integration.id === steps.options?.[index]?.integrationId)?.name
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                            {!steps.options[index]
                                                                                ?.greetingMessage && (
                                                                                    <Tooltip arrow interactive title={
                                                                                        <React.Fragment>
                                                                                            <Typography variant="body2">{i18n.t("chatbots.tips.optionTip.title")}</Typography>
                                                                                            <p>{i18n.t("chatbots.tips.optionTip.message")}</p>
                                                                                        </React.Fragment>}
                                                                                    >
                                                                                        <IconButton
                                                                                            size="small"
                                                                                            className={classes.iconButton}
                                                                                        >
                                                                                            <Help />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                )}

                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={() =>
                                                                                    setGreetingMessageEdit(index)
                                                                                }
                                                                                className={classes.iconButton}
                                                                            >
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                    ) : (
                                                                        <div className={classes.greetingMessage}>
                                                                            <div>
                                                                                <div
                                                                                    className={classes.greetingMessage}
                                                                                >
                                                                                    {(values.options[index]?.type !== "queue" || values.options[index]?.type !== "integration") &&
                                                                                        <Field
                                                                                            as={TextField}
                                                                                            label={i18n.t("chatbots.message")}
                                                                                            name={`options[${index}].greetingMessage`}
                                                                                            variant="outlined"
                                                                                            margin="dense"
                                                                                            fullWidth
                                                                                            multiline
                                                                                            error={
                                                                                                touched.greetingMessage &&
                                                                                                Boolean(errors.greetingMessage)
                                                                                            }
                                                                                            helperText={
                                                                                                touched.greetingMessage &&
                                                                                                errors.greetingMessage
                                                                                            }
                                                                                            className={classes.textField}
                                                                                        />
                                                                                    }
                                                                                </div>
                                                                                <div
                                                                                    className={classes.greetingMessage}
                                                                                >

                                                                                    {values.options[index]?.type !== "text" &&
                                                                                        <FormControl style={{ minWidth: "150px" }} className={classes.settingOption}>
                                                                                            <InputLabel id="chattype-selection-input-label">
                                                                                                {steps.options[index].type === "queue" && i18n.t("Selecione uma fila")}
                                                                                                {steps.options[index].type === "attendant" && i18n.t("Selecione um atendente")}
                                                                                                {steps.options[index].type === "fileList" && i18n.t("Selecione uma lista de arquivos")}
                                                                                                {steps.options[index].type === "integration" && i18n.t("Selecione uma integração")}
                                                                                            </InputLabel>
                                                                                            <Field
                                                                                                as={Select}
                                                                                                label={<>
                                                                                                    {steps.options[index].type === "queue" && i18n.t("Selecione uma fila")}
                                                                                                    {steps.options[index].type === "attendant" && i18n.t("Selecione um atendente")}
                                                                                                    {steps.options[index].type === "integration" && i18n.t("Selecione uma integração")}
                                                                                                </>}
                                                                                                labelId="chattype-selection-input-label"
                                                                                                error={
                                                                                                    touched?.options?.[index]?.toUserId &&
                                                                                                    Boolean(
                                                                                                        errors.options?.[index]?.toUserId
                                                                                                    )
                                                                                                }
                                                                                                variant="outlined"
                                                                                                className={classes.settingOption}
                                                                                                name={(() => {
                                                                                                    switch (values.options[index].type) {
                                                                                                        case "queue":
                                                                                                            return `options[${index}].toQueueId`;
                                                                                                        case "attendant":
                                                                                                            return `options[${index}].toUserId`;
                                                                                                        case "fileList":
                                                                                                            return `options[${index}].fileListId`;
                                                                                                        case "integration":
                                                                                                            return `options[${index}].integrationId`;
                                                                                                    }
                                                                                                })()}

                                                                                            >
                                                                                                {values.options[index]?.type == "queue" && queues && queues.map((queue) =>
                                                                                                    <MenuItem value={queue.id}>{queue.name}</MenuItem>
                                                                                                )}
                                                                                                {values.options[index]?.type === "attendant" && users && users.map((user) =>
                                                                                                    <MenuItem value={user.id}>{user.name}</MenuItem>
                                                                                                )}
                                                                                                {values.options[index]?.type === "fileList" && fileLists && fileLists.map((fileList) =>
                                                                                                    <MenuItem value={fileList.id}>{fileList.name}</MenuItem>
                                                                                                )}
                                                                                                {values.options[index]?.type === "integration" && integrations && integrations.map((integration) =>
                                                                                                    <MenuItem value={integration.id}>{integration.name}</MenuItem>
                                                                                                )}
                                                                                            </Field>
                                                                                        </FormControl>
                                                                                    }
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={() => handleSaveBot(values)}
                                                                                        color="primary"
                                                                                        disabled={isSubmitting}
                                                                                    >
                                                                                        {" "}
                                                                                        <SaveIcon />
                                                                                    </IconButton>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    )}
                                                                    <Divider />

                                                                    {getStepContent(info.id)}
                                                                </>
                                                            </StepContent>
                                                        )}
                                                    </Step>
                                                ))}

                                            {steps?.type === "text" &&
                                                <Step>
                                                    <StepLabel>
                                                        <Button
                                                            style={{ flex: 1, marginTop: 8 }}
                                                            color="primary"
                                                            onClick={() =>
                                                                push({
                                                                    name: undefined,
                                                                    greetingMessage: undefined,
                                                                })
                                                            }
                                                        >
                                                            {`+ ${i18n.t("chatbots.addButton")}`}
                                                        </Button>
                                                    </StepLabel>
                                                </Step>
                                            }
                                        </Stepper>
                                    </>
                                )}
                            </FieldArray>
                        )}
                    />
                </div>
            )}
        </div>
    );
}
export default ChatbotOptions;
