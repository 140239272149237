import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TagsWrapper from '../TagsWrapper';
import TableRowSkeleton from '../TableRowSkeleton';
import { i18n } from '../../translate/i18n';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { toast } from 'react-toastify';
import ButtonWithSpinner from '../ButtonWithSpinner';

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        marginTop: 0,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
    trashIcon: {
        color: theme.palette.secondary.main
    },
    tableCustomRow: {
        '& td, & th': {
            borderColor: theme.palette.divider,
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 130,
    },
    switchLabel: {
        display: 'flex',
        alignItems: 'center',
    }
}));

const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const ImportContactsFromFile = ({ open, onClose, file, onConfirm, maxContacts }) => {
    const classes = useStyles();
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [importing, setImporting] = useState(false);
    const [overrideData, setOverrideData] = useState(false); // Estado para o Switch
    const [error, setError] = useState('');

    useEffect(() => {
        setContacts([]);
        if (file) {
            setLoading(true);
            setError('');

            const reader = new FileReader();

            reader.onload = (e) => {
                const csvContent = e.target.result;
                if (typeof csvContent === 'string') {
                    const lines = csvContent.split('\n');
                    const [headerLine, ...rows] = lines;
                    const headers = headerLine.split(',').map(col => col.trim());

                    // Verificar se as colunas obrigatórias estão presentes
                    const requiredColumns = ['name', 'number'];
                    const missingColumns = requiredColumns.filter(col => !headers.includes(col));

                    if (missingColumns.length > 0) {
                        setError(`O arquivo CSV deve conter as seguintes colunas obrigatórias: ${requiredColumns.join(', ')}`);
                        setLoading(false);
                        return;
                    }

                    // Mapeia os índices das colunas
                    const columnIndices = {
                        name: headers.indexOf('name'),
                        number: headers.indexOf('number'),
                        tags: headers.indexOf('tags'),
                        company: headers.indexOf('company'),
                        email: headers.indexOf('email'),
                        obs: headers.indexOf('obs')
                    };

                    // Função para limpar o número de telefone
                    const cleanPhoneNumber = (number) => {
                        return number
                            .replace(/[\+\-\s]/g, '') // Remove +, -, e espaços
                            .replace(/[^\d]/g, ''); // Remove letras e caracteres não numéricos
                    };

                    if (maxContacts && rows.length > maxContacts) {
                        toast.warn(`O arquivo contém mais de ${maxContacts} contatos. Somente os primeiros ${maxContacts} contatos foram exibidos.`);
                    }

                    const parsedData = rows
                        .filter(row => row.trim())
                        .slice(0, maxContacts ?? rows.length) // Limita a quantidade de contatos a maxContacts se definido
                        .map(row => {
                            const columns = row.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/).map(cell => cell.replace(/^"|"$/g, '').trim());

                            const tags = columnIndices.tags >= 0
                                ? (columns[columnIndices.tags] || '')
                                    .split(',')
                                    .map(tag => tag.trim())
                                    .filter(tagName => tagName !== '') // Filtra tags vazias
                                    .map(tagName => ({
                                        name: tagName,
                                        color: getRandomColor()
                                    }))
                                : [];


                            return {
                                name: columns[columnIndices.name] || '',
                                number: cleanPhoneNumber(columns[columnIndices.number] || ''),
                                tags: tags,
                                company: columnIndices.company >= 0 ? columns[columnIndices.company] || '' : '',
                                email: columnIndices.email >= 0 ? columns[columnIndices.email] || '' : '',
                                obs: columnIndices.obs >= 0 ? columns[columnIndices.obs] || '' : ''
                            };
                        });

                    setContacts(parsedData);
                }
                setLoading(false);
            };

            reader.readAsText(file);
        } else {
            setContacts([]);
            setError('');
            setLoading(false);
        }
    }, [file]);

    const handleClose = () => {
        setContacts([]);
        setError('');
        onClose();
    };

    const handleConfirm = async () => {
        setImporting(true);
        const chunkSize = 500; // Ajuste o tamanho do chunk conforme necessário
        const contactsChunks = [];

        // Divida o array de contatos em partes menores
        for (let i = 0; i < contacts.length; i += chunkSize) {
            contactsChunks.push(contacts.slice(i, i + chunkSize));
        }

        let allAddedContacts = [];
        let hasError = false;

        try {
            // Processa as requisições em série, garantindo que todas sejam resolvidas
            for (const chunk of contactsChunks) {
                const { data } = await api.post("/contacts/importfromfile", { contacts: chunk, overrideData });
                if (data.withError) {
                    hasError = true;
                    toast.warn("Alguns contatos não foram importados devido a erros, mas a importação continuou sem interrupções.");
                }
                allAddedContacts = [...allAddedContacts, ...data.addedContacts];
            }

            if (!hasError) {
                toast.success("Contatos importados com sucesso");
            }
            onConfirm(allAddedContacts);
        } catch (err) {
            toastError(err);
        } finally {
            setImporting(false);
            handleClose();
        }
    };

    const handleSwitchChange = (event) => {
        setOverrideData(event.target.checked);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle
                id="form-dialog-title"
                color="textPrimary"
            >
                Importar Contatos
            </DialogTitle>
            <DialogContent dividers>
                {importing ?
                    <Paper>
                        <Typography color="warning">{i18n.t("Importando contatos, aguarde! Não feche esta janela até concluir a importação.")}</Typography>
                        <CircularProgress />
                    </Paper> :

                    <TableContainer component={Paper} variant='outlined' className={classes.mainPaper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow className={classes.tableCustomRow}>
                                    <TableCell>{i18n.t("contacts.table.name")}</TableCell>
                                    <TableCell align="center">
                                        {i18n.t("contacts.table.whatsapp")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t("contacts.table.tags")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t("contacts.table.email")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t("contacts.table.company")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contacts.map((contact, index) => (
                                    <TableRow className={classes.tableCustomRow} key={index}>
                                        <TableCell>{contact.name}</TableCell>
                                        <TableCell align="center">{contact.number}</TableCell>
                                        <TableCell align="center"><TagsWrapper tags={contact.tags} maxWidth={400} /></TableCell>
                                        <TableCell align="center">{contact.email}</TableCell>
                                        <TableCell align="center">{contact.company}</TableCell>
                                    </TableRow>
                                ))}
                                {loading && <TableRowSkeleton columns={5} />}
                                {error && <Typography color="error">{error}</Typography>}
                            </TableBody>
                        </Table>
                    </TableContainer>}
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>

                <div className={classes.switchLabel}>
                    <Typography variant="body2" sx={{ marginRight: 1 }}>
                        Substituir dados de contatos existentes
                    </Typography>
                    <Switch
                        checked={overrideData}
                        onChange={handleSwitchChange}
                        disabled={importing}
                    />
                </div>
                <div style={{ display: "flex", gap: 4 }}>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        disabled={importing}
                        variant="outlined"
                    >
                        Cancelar
                    </Button>
                    <ButtonWithSpinner
                        onClick={handleConfirm}
                        color="primary"
                        variant="contained"
                        loading={importing}
                        disabled={loading || importing || error}
                    >
                        Confirmar
                    </ButtonWithSpinner>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default ImportContactsFromFile;
