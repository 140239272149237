import React from "react";

import { Card, Button, IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import TicketHeaderSkeleton from "../TicketHeaderSkeleton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { useHistory } from "react-router-dom";
import { ChevronLeft, ChevronLeftRounded } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  ticketHeader: {
    display: "flex",
    backgroundColor: theme.palette.background.header,
    alignItems: "center",
    flex: "none",
    minHeight: "73px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down('md')]: {
      flexWrap: "wrap",
    },
  },
}));

const TicketHeader = ({ loading, children }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleBack = () => {
    history.push("/tickets");
  };

  return (
    <>
      {loading ? (
        <TicketHeaderSkeleton />
      ) : (
        <Card square className={classes.ticketHeader}>
          <IconButton size="small" color="primary" onClick={handleBack}>
            <ChevronLeftRounded fontSize="large" />
          </IconButton>
          {children}
        </Card>
      )}
    </>
  );
};

export default TicketHeader;
