import React from "react";
import Typography from "@mui/material/Typography";

export default function Title({color = "primary",children}) {

	return (
		<Typography variant="h5" color={color} gutterBottom>
			{children}
		</Typography>
	);
}
