import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { i18n } from "../../translate/i18n";


const useStyles = makeStyles(theme => ({
	customTooltip:{
		display:'flex',
		flexDirection:'column',
		alignItems:'center',
		letterSpacing: 2,
		textTransform: 'uppercase',
		backgroundColor: `${theme.palette.background.default}bd`,
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: 7,
		padding: '10px 10px',
		fontWeight: 500,
		//boxShadow: theme.palette.shadow
	},
    count:{color:theme.palette.primary.main,}

}))

const CustomTooltip = ({ active, payload, label }) => {
	const classes = useStyles();
	if (active && payload && payload.length) {
	  return (
		  <div className={classes.customTooltip}>
			  {payload?.map((row, index) => {
				  return (
					  <div key={index}>
						  <div style={{
							  color: row.color,
							  fontSize: 10,
							  letterSpacing: 2,
							  textTransform: 'uppercase'
						  }}>
							  {label}
						  </div>
						  <div style={{
							  //color: row.color,
							  fontSize: 14
						  }}
						  >{payload[0].value}
						  </div>
					  </div>
				  )
			  })}
		  </div>
	  );
	}
  
	return null;
}
export default CustomTooltip