import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useState } from "react";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { Star } from "@mui/icons-material";
import useUsers from "../../hooks/useUsers";
import useQueues from "../../hooks/useQueues";

const ReviewsFilter = ({ values, onChange, ...props }) => {
    const { classes } = props;
    const {users} = useUsers({});
    const [queues, setQueues] = useState([]);
    const { findAll } = useQueues();

    useEffect(() => {
        const loadQueues = async () => {
            const list = await findAll();
            //setAllQueues(list);
            setQueues(list);
        }
        loadQueues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleChange = (value, name) => {
        switch (name) {
            case ('date-start'):
                if (values.end && value > values.end) {
                    value = values.end;
                    toast.info(i18n.t("dashboard.errors.dateMax"));
                }
                onChange({ ...values, start: value });
                break;
            case ('date-end'):
                if (value < values.start) {
                    value = values.start;
                    toast.info(i18n.t("dashboard.errors.dateMin"));
                }
                onChange({ ...values, end: value });
                break;
            case ('rating-select'):
                onChange({ ...values, rating: value });
                break;
            case ('user-select'):
                onChange({ ...values, user: value });
                break;
            case ('queue-select'):
                onChange({ ...values, queue: value });
                break;
            default:
                toastError(i18n.t("dashboard.errors.generic"));
                break;
        }
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl
                variant="outlined"
                className={classes.formControl}
                margin="dense"
            >
                <InputLabel id="user-selection-input-label">
                    {i18n.t("reviews.filter.user")}
                </InputLabel>
                <Select
                    label={i18n.t("reviews.filter.user")}
                    labelId="user-selection-input-label"
                    id="user-select"
                    name="user-select"
                    value={values.user}
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                    variant="outlined"
                    className={classes.settingOption}
                >
                    <MenuItem value=''>Todos</MenuItem>
                    {users.map(user => (
                        <MenuItem key={user.id} value={user.id}>
                            {user.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl
                variant="outlined"
                className={classes.formControl}
                margin="dense"
            >
                <InputLabel id="queue-selection-input-label">
                    {i18n.t("reviews.filter.queue")}
                </InputLabel>
                <Select
                    label={i18n.t("reviews.filter.queues")}
                    labelId="queue-selection-input-label"
                    id="queue-select"
                    name="queue-select"
                    value={values.queue}
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                    variant="outlined"
                    className={classes.settingOption}
                >
                    <MenuItem value=''>Todos</MenuItem>
                    {queues && queues.map(queue => (
                        <MenuItem key={queue.id} value={queue.id}>
                            {queue.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl
                variant="outlined"
                className={classes.formControl}
                sx={{width:160}}
                margin="dense"
            >
                <DatePicker
                    onChange={(value) => handleChange(value, "date-start")}
                    format="dd/MM/yyyy"
                    value={new Date(values.start)}
                    maxDate={new Date(values.end)}
                    label={i18n.t("dashboard.filters.startDate")}
                />
            </FormControl>
            <FormControl
                variant="outlined"
                className={classes.formControl}
                sx={{ width: 160 }}
                margin="dense"
            >
                <DatePicker
                    onChange={(value) => handleChange(value, "date-end")}
                    format="dd/MM/yyyy"
                    value={new Date(values.end)}
                    minDate={new Date(values.start)}
                    label={i18n.t("dashboard.filters.endDate")}
                />
            </FormControl>
            <FormControl
                variant="outlined"
                className={classes.formControl}
                margin="dense"
            >
                <InputLabel id="rating-selection-input-label">
                    {i18n.t("reviews.filter.rating")}
                </InputLabel>
                <Select
                    label={i18n.t("reviews.filter.rating")}
                    labelId="rating-selection-input-label"
                    id="rating-select"
                    name="rating-select"
                    value={values.rating}
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                    variant="outlined"
                    className={classes.settingOption}
                >
                    <MenuItem value=''>Todos</MenuItem>
                    {[1, 2, 3, 4, 5].map(rate => (
                        <MenuItem key={rate} value={rate}>
                            {Array.from({ length: rate }, (_, index) => (
                                <Star fontSize="small" key={index} />
                            ))}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            

        </LocalizationProvider>
    )
}
export default ReviewsFilter;