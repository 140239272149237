import React, { useState } from "react";
import { Add, AttachFile, Feedback, ContactPhone, Delete, Description, Edit, FileUpload, FolderCopy, Image, PhotoLibrary, Send } from "@mui/icons-material";
import { IconButton, Popover, Typography, Button, List, ListItemButton, ListItemText, ListItem, ListItemIcon, MenuItem, MenuList } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FileListSelectModal from "../FileListSelectModal";
import { i18n } from "../../translate/i18n";
import SendContactModal from "../SendContactModal";
import CommandListModal from "../CommandListModal";


const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.text.secondary,
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  uploadInput: {
    display: "none",
  },

}));

const Attachments = ({ choseMedias, connType, ticketId }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileListsModalOpen, setFileListsModalOpen] = useState(false);
  const [sendContactModalOpen, setSendContactModalOpen] = useState(false);
  const [commandListModalOpen, setCommandListModalOpen] = useState(false);

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenFileListsModal = () => {
    setFileListsModalOpen(true);
  }
  const handleCloseFileListsModal = () => {
    setFileListsModalOpen(false);
  }
  const handleOpenSendContactModal = () => {
    setSendContactModalOpen(true);
  }
  const handleCloseSendContactModal = () => {
    setSendContactModalOpen(false);
  }

  const handleOpenCommandListModal = () => {
    setCommandListModalOpen(true);
  }
  const handleCloseCommandListModal = () => {
    setCommandListModalOpen(false);
    handleClose();
  }


  const handleClose = () => {
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);

  return (
    <div>
      <FileListSelectModal open={fileListsModalOpen} onClose={handleCloseFileListsModal} choseFileList={choseMedias} />
      <SendContactModal open={sendContactModalOpen} onClose={handleCloseSendContactModal} />
      <CommandListModal open={commandListModalOpen} onClose={handleCloseCommandListModal} ticketId={ticketId}/>
      <IconButton
        aria-label="attachments"
        onClick={handleButtonClick}
      >
        <Add className={classes.iconButton} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center", // Centralizado horizontalmente
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center", // Centralizado horizontalmente
        }}
      >
        <MenuList>
          <MenuItem
            aria-label="commandlist"
            component="span"
            variant="text"
            onClick={handleOpenCommandListModal}
          >
            <ListItemIcon>
              <Feedback className={classes.iconButton} />
            </ListItemIcon>
            <ListItemText primary={i18n.t("messagesInput.attachments.commandList")} />
          </MenuItem>
          {connType === "whatsapp" || connType === "bwhatsapp" && <span>
            <input
              multiple
              type="file"
              id="upload-document"
              //disabled={loading || recording || ticketStatus !== "open"}
              className={classes.uploadInput}
              onChange={choseMedias}
            />
            <label fullWidth htmlFor="upload-document">
              <MenuItem
                aria-label="upload-document"
                component="span"
                variant="text"
              >
                <ListItemIcon>
                  <Description className={classes.iconButton} />
                </ListItemIcon>
                <ListItemText primary={i18n.t("messagesInput.attachments.document")} />
              </MenuItem>
            </label>
          </span>}
          <input
            multiple
            type="file"
            accept="image/*, video/*"
            id="upload-image"
            //disabled={loading || recording || ticketStatus !== "open"}
            className={classes.uploadInput}
            onChange={choseMedias}
          />
          <label fullWidth htmlFor="upload-image">
            <MenuItem
              aria-label="upload-image"
              component="span"
              variant="text"
            >
              <ListItemIcon>
                <PhotoLibrary className={classes.iconButton} />
              </ListItemIcon>
              <ListItemText primary={i18n.t("messagesInput.attachments.medias")} />
            </MenuItem>
          </label>
          <MenuItem
            aria-label="filelist"
            component="span"
            variant="text"
            onClick={handleOpenFileListsModal}
          >
            <ListItemIcon>
              <FolderCopy className={classes.iconButton} />
            </ListItemIcon>
            <ListItemText primary={i18n.t("messagesInput.attachments.fileList")} />
          </MenuItem>
          {connType === "whatsapp" || connType === "bwhatsapp" &&
          <span>
            <MenuItem
              aria-label="contacts"
              component="span"
              variant="text"
              onClick={handleOpenSendContactModal}
            >
              <ListItemIcon>
                <ContactPhone className={classes.iconButton} />
              </ListItemIcon>
              <ListItemText primary={i18n.t("messagesInput.attachments.contact")} />
            </MenuItem>
          </span>
          }
        </MenuList>
      </Popover>
    </div>
  );
};

export default Attachments;
