import { Avatar, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({

}));

const AvatarTooltip = ({ children, icon, ...props }) => {
    const classes = useStyles();
    const [showAvatarIcon, setShowAvatarIcon] = useState(false);
    
    return (
        <>
            <Tooltip
                {...props}
            >
                <div
                    className={classes.hoverHandler}
                    onMouseEnter={() => setShowAvatarIcon(true)}
                    onMouseLeave={() => setShowAvatarIcon(false)}
                >
                    {showAvatarIcon ? <Avatar>{icon}</Avatar> : children}
                </div>
            </Tooltip>
        </>
    )
}
export default AvatarTooltip;