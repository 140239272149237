import { useTheme } from "@emotion/react";
import Title from "../../components/Title";
import { Area, AreaChart, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import CustomTooltip from "../../components/CustomTooltip";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { i18n } from "../../translate/i18n";
import NumberEasing from "../../components/NumberEasing";
import { addMilliseconds, format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";

const HourChart = ({ filter, counter, chartData, classes, responseTimeData, ...props }) => {
    const theme = useTheme();
    const [meanTime, setMeanTime] = useState("00:00:00")
    const [medianTime, setMedianTime] = useState("00:00:00")

    function calculateMeanMedian(data) {
        // Obtem os tempos em segundos
        const timesInSeconds = data.map(item => item.responseTimeInSeconds);

        // Calcular a média
        const mean = timesInSeconds.reduce((total, time) => total + time, 0) / timesInSeconds.length;

        // Calcular a mediana
        const sortedTimes = [...timesInSeconds].sort((a, b) => a - b);
        const middle = Math.floor(sortedTimes.length / 2);
        let median;
        if (sortedTimes.length % 2 === 0) {
            median = (sortedTimes[middle - 1] + sortedTimes[middle]) / 2;
        } else {
            median = sortedTimes[middle];
        }

        // Função para formatar os resultados de volta para o formato desejado "HH:mm:ss"
        function formatTime(seconds) {
            const hours = Math.floor(seconds / 3600);
            seconds %= 3600;
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(remainingSeconds.toFixed(0)).padStart(2, "0")}`;
        }

        return {
            mean: formatTime(mean),
            median: formatTime(median)
        };
    }


    useEffect(() => {
        if (!responseTimeData?.length > 1) return;
        const result = calculateMeanMedian(responseTimeData);
        setMeanTime(result?.mean);
        setMedianTime(result?.median);
    }, [responseTimeData]);

    return (
        <div
            style={{
                //padding: theme.spacing(2),
                display: "flex",
                //flexDirection: "row",
                gap:4,
                height: "100%",
                flexWrap: "wrap-reverse",
                overflow: "hidden",
            }}
        ><div
            className={classes.data}
            style={{ flex: "1 0 73%", minWidth: 300 }}
        >
                <Paper variant="outlined" style={{
                    padding: theme.spacing(2),
                    display: "flex",
                    overflow: "auto",
                    flexDirection: "column",
                    height: 240,
                }}>
                    <Title>
                        {`${i18n.t("dashboard.charts.ticketCount.title")}: `}<NumberEasing value={counter} duration={100} decimals={0} />
                    </Title>
                    <ResponsiveContainer debounce={1}>
                        <AreaChart
                            width={500}
                            height={400}
                            data={chartData}
                            margin={{
                                top: 16,
                                right: 16,
                                bottom: 0,
                                left: 16,
                            }}
                        >
                            {/* <CartesianGrid strokeLinecap="3 3" /> */}
                            <XAxis dataKey="hour" stroke={theme.palette.text.secondary} />
                            {/* <YAxis
                            type="number"
                            allowDecimals={false}
                            stroke={theme.palette.text.secondary}
                        >
                            <Label
                                angle={270}
                                position="left"
                                style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
                            >
                                {i18n.t("dashboard.charts.perHour.title")}
                            </Label>
                        </YAxis> */}
                            <Tooltip
                                cursor={{ fill: theme.palette.divider }}
                                animationEasing={"ease-in-out"}
                                content={<CustomTooltip />}
                            />
                            <Area
                                isAnimationActive={true}
                                type="monotone"
                                dataKey="count"
                                stroke={theme.palette.primary.main}
                                strokeWidth={'3px'}
                                fill={theme.palette.primary.light}
                            />
                        </AreaChart>
                    </ResponsiveContainer>

                </Paper>
            </div>
            <div
                className={classes.data}
                style={{ display: "flex", flexDirection:"column",flexWrap:"wrap",flex: "1", minWidth: 300, gap:4 }}
            >
                <Paper className={classes.ticketStatusCounter} style={{ overflow: "hidden" }} variant="outlined">
                    <Title>
                        {i18n.t("dashboard.charts.meanMedian.mean")}
                    </Title>
                    <Typography variant="h6" color="textPrimary" style={{ marginBottom: 8 }}>
                        {meanTime.split(":").map((part, index) => (
                            <React.Fragment key={index}>
                                <NumberEasing value={+part} duration={100} decimals={0} minDigits={2}/>
                                {index < meanTime.split(":").length - 1 && ":"}
                            </React.Fragment>
                        ))}
                    </Typography>

                    
                    <Typography variant="p" color="textSecondary" style={{ fontSize: 11,marginTop: 8 }}>
                        {i18n.t("dashboard.charts.meanMedian.groupWarning")}
                    </Typography>
                </Paper>
                <Paper className={classes.ticketStatusCounter} style={{ overflow: "hidden" }} variant="outlined">
                    <Title>
                        {i18n.t("dashboard.charts.meanMedian.median")}
                    </Title>
                    <Typography variant="h6" color="textPrimary">
                        {medianTime.split(":").map((part, index) => (
                            <React.Fragment key={index}>
                                <NumberEasing value={+part} duration={100} decimals={0} minDigits={2} />
                                {index < medianTime.split(":").length - 1 && ":"}
                            </React.Fragment>
                        ))}
                    </Typography>
                    <Typography variant="p" color="textSecondary" style={{ fontSize: 11, marginTop: 8 }}>
                        {i18n.t("dashboard.charts.meanMedian.groupWarning")}
                    </Typography>
                </Paper>
            </div>
        </div>

    )
}
export default HourChart;