import { useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";
import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, ImageList, ImageListItem, ImageListItemBar, Tab, Tabs, Typography } from "@mui/material";
import MainHeader from "../MainHeader";
import makeStyles from '@mui/styles/makeStyles';
import ModalImageCors from "../ModalImageCors";
import { GetApp } from "@mui/icons-material";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    imageList: {
        display: "flex",
        flexWrap: "wrap",
        padding: "0 5px",
        textAlign: "center"
    },
    imageItem: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 8,
        height: 200,
        width: 200,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img:not(.MuiImageListItemBar-root)": {
            height: "100%",
            width: "auto",
        }
    },
    downloadMedia: {
        backgroundColor: theme.palette.background.default,
        display: "flex",
        flexDirection:"column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 200,
    },
    noMediasText: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4",
    },

    noMediasTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px",
    },

    noMediasDiv: {
        display: "flex",
        height: "100px",
        width:"100%",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_MEDIAS") {
        const messages = action.payload;
        const newMessages = [];

        messages.forEach((message) => {
            const messageIndex = state.findIndex((m) => m.id === message.id);
            if (messageIndex !== -1) {
                state[messageIndex] = message;
            } else {
                newMessages.push(message);
            }
        });

        return [...state, ...newMessages];
    }

    if (action.type === "ADD_MESSAGE") {
        const newMessage = action.payload;
        const messageIndex = state.findIndex((m) => m.id === newMessage.id);

        if (messageIndex !== -1) {
            state[messageIndex] = newMessage;
        } else {
            state.push(newMessage);
        }

        return [...state];
    }
    function ToastDisplay(props) {
        return <><h4>Mensagem apagada:</h4><p>{props.body}</p></>;
    }


    if (action.type === "UPDATE_MESSAGE") {
        const messageToUpdate = action.payload;
        const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

        if (messageToUpdate.isDeleted === true) {
            //toast.info(`Mensagem apagada: ${messageToUpdate.body}  `,{autoClose: false});
            toast.info(<ToastDisplay
                body={messageToUpdate.body}
            >
            </ToastDisplay>, { autoClose: false });
        }

        if (messageIndex !== -1) {
            state[messageIndex] = messageToUpdate;
        }

        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const ContactMediaModal = ({ open, onClose, ticketId }) => {
    const classes = useStyles();
    const [messagesList, dispatch] = useReducer(reducer, []);
    const [contact, setContact] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState("image");

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [ticketId]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchMessages = async () => {
                try {
                    const { data } = await api.get(`/messages/${ticketId}/medias`, {
                        params: { pageNumber },
                    });

                    if (ticketId) {
                        dispatch({ type: "LOAD_MEDIAS", payload: data.messages });
                        setContact(data.ticket.contact)
                        setHasMore(data.hasMore);
                        setLoading(false);
                    }
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };
            fetchMessages();
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [pageNumber, ticketId, open]);

    useEffect(() => {
        const socket = openSocket();

        socket.on("connect", () => socket.emit("joinChatBox", ticketId));

        socket.on("appMessage", (data) => {

            if (data.action === "update") {
                dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, [ticketId]);

    const handleChangeTabs = (event, newValue) => {
        setTab(newValue);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };
    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose} scroll="paper">
            <MainHeader>
                <DialogTitle
                    color="textPrimary"
                    className={classes.diagTitle}
                >
                    {i18n.t("contactMediaModal.title", { contactName: contact?.name })}
                </DialogTitle>
            </MainHeader>
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={tab}
                        variant="fullWidth"
                        onChange={handleChangeTabs}
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        <Tab value="image" label={i18n.t("contactMediaModal.tabs.image")} />
                        <Tab value="video" label={i18n.t("contactMediaModal.tabs.video")} />
                        <Tab value="application" label={i18n.t("contactMediaModal.tabs.application")} />
                    </Tabs>
                </Box>
            <DialogContent style={{ display: "flex", minHeight:230,flexDirection: "column", padding: 0 }} dividers>
                <ImageList className={classes.imageList} onScroll={handleScroll} cols={{ xs: 2, sm: 3, md: 3, lg: 6 }} rowHeight={200} gap={6}>
                    {messagesList.filter((message) => message.mediaType === tab).length > 0 ? (
                        messagesList
                            .filter((message) => message.mediaType === tab)
                            .map((message) => (
                                <ImageListItem className={classes.imageItem} key={message.id}>
                                    {tab === "image" &&
                                        <ModalImageCors 
                                        imageUrl={message.mediaUrl} 
                                        filename={`${message.fromMe ? i18n.t("contactMediaModal.you") : message.contact.name ? message.contact.name : contact.name}: ${message.body||message.filename}`}
                                        />
                                    }
                                    {tab === "video" &&
                                        <video
                                            src={message.mediaUrl}
                                            controls
                                        />
                                    }
                                    {tab === "application" &&
                                        <div className={classes.downloadMedia}>
                                            <Button
                                                startIcon={<GetApp />}
                                                className={classes.downloadBtn}
                                                variant="outlined"
                                                target="_blank"
                                                href={message.mediaUrl}
                                            >
                                                Download
                                            </Button>
                                        </div>
                                    }
                                    <ImageListItemBar
                                        title={message.fromMe ? i18n.t("contactMediaModal.you")  : message.contact.name ? message.contact.name : contact.name}
                                        subtitle={tab === "application" ? `${i18n.t("contactMediaModal.file")}: ${message.filename}` : message.body}
                                        classes={{
                                            root: classes.titleBar,
                                            title: classes.title,
                                        }}
                                    />
                                </ImageListItem>
                            ))
                    ) : !loading &&
                        <div className={classes.noMediasDiv}>
                        <span className={classes.noMediasTitle}>
                            {i18n.t("Nada Aqui!")}
                        </span>
                        <p className={classes.noMediasText}>
                            {i18n.t(`contactMediaModal.noMedia.${tab}`)}
                        </p>
                    </div>
                    }{
                        loading && <div className={classes.noMediasDiv}>
                        <CircularProgress className={classes.circleLoading} />
                    </div>}
                    

                </ImageList>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="outlined"
                >
                    {i18n.t("contactMediaModal.buttons.close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ContactMediaModal;