import { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const reducer = (state, action) => {
    if (action.type === "LOAD_TAGS") {
        const tags = action.payload;
        const newTags = [];

        tags.forEach((tag) => {
            const tagIndex = state.findIndex((q) => q.id === tag.id);
            if (tagIndex !== -1) {
                state[tagIndex] = tag;
            } else {
                newTags.push(tag);
            }
        });

        return [...state, ...newTags];
    }

    if (action.type === "UPDATE_TAG") {
        const tag = action.payload;
        const tagIndex = state.findIndex((u) => u.id === tag.id);

        if (tagIndex !== -1) {
            state[tagIndex] = tag;
            return [...state];
        } else {
            return [tag, ...state];
        }
    }

    if (action.type === "DELETE_TAG") {
        const tagId = action.payload;
        const tagIndex = state.findIndex((q) => q.id === tagId);
        if (tagIndex !== -1) {
            state.splice(tagIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useTags = ({ searchParam, pageNumber, showAll = false }) => {
    const [tags, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        dispatch({ type: "RESET" });
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {

            const fetchTags = async () => {
                try {
                    const { data } = await api.get("/tags", { params: { searchParam, pageNumber, showAll } });
                    dispatch({ type: "LOAD_TAGS", payload: data.tags });
                    setHasMore(data.hasMore);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchTags();
            setLoading(false);
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchParam, pageNumber]);


    useEffect(() => {
        const socket = openSocket();

        socket.on("tag", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_TAG", payload: data.tag });

            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_TAG", payload: data.tagId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return { tags, hasMore, loading };
};

export default useTags;