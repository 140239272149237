import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    flex: 1,
    // padding: theme.spacing(2),
    height: `calc(100% - 48px)`,
    padding: 0,
    //height: '100vh',
  },

  contentWrapper: {
    height: "100%",
    overflowY: "hidden",
    display: "flex",
    flexDirection: "column",
  },
}));

const MainContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <Container className={classes.mainContainer} maxWidth={false}>
      <div className={classes.contentWrapper}>{children}</div>
    </Container>
  );
};

export default MainContainer;
