import React, { useState } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import lightTheme from "./themes/light";
import darkTheme from "./themes/dark";
import wappLight from "./themes/wappLight";
import wappDark from "./themes/wappDark";
import crayolaDark from "./themes/crayolaDark";
import crayolaLight from "./themes/crayolaLight";
import ErrorBoundary from "./errors/ErrorBoundary";
import adwDarkTheme from "./themes/AdwDark";
import adwLightTheme from "./themes/AdwLight";
import MetroTheme from "./themes/Metro";
// import { makeStyles } from "@mui/material";

const App = () => {

  const currentTheme = (theme) => {
    switch (theme) {
      case "light":
        return lightTheme;
      case "dark":
        return darkTheme;
      case "wappLight":
        return wappLight;
      case "wappDark":
        return wappDark;
      case "crayolaLight":
        return crayolaLight;
      case "crayolaDark":
        return crayolaDark;
      case "adwDark":
        return adwDarkTheme;
      case "adwLight":
        return adwLightTheme;
      case "metro":
        return MetroTheme;
      default:
        return lightTheme;
    }
  }

  const [theme] = useState(localStorage.getItem("theme") === null ? "light" : localStorage.getItem("theme"));

  /*useEffect(() => {
    setTimeout(() => {
      setCount((count) => count + 1);
      if (theme != localStorage.getItem("theme")){
        setTheme(localStorage.getItem("theme"));
      }
    }, 1000);
  });*/

  if (localStorage.getItem("notificationsnd") === null) {
    localStorage.setItem("notificationsnd", "enabled");
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={currentTheme(theme)}>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
        <CssBaseline />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;