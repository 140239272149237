import React, { useEffect, useReducer, useState } from "react";
import {
    Avatar,
    Button,
    Checkbox,
    Chip,
    colors,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ButtonWithSpinner from "../ButtonWithSpinner";
import { i18n } from "../../translate/i18n";
import SearchIcon from "@mui/icons-material/Search";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import TableRowSkeleton from "../TableRowSkeleton";
import AvatarTooltip from "../AvatarTooltip";
import { Info } from "@mui/icons-material";
import useCategories from "../../hooks/useCategories";
import { format, isSameDay, parseISO } from "date-fns";
import useQueues from "../../hooks/useQueues";
import useTags from "../../hooks/useTags";
import useWhatsApps from "../../hooks/useWhatsApps";
import TagsWrapper from "../TagsWrapper";


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",

    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: theme.palette.primary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
    tableCustomRow: {
        '& td, & th': {
            borderColor: theme.palette.divider,
        },
    },
    chipContainer: {
        whiteSpace: 'nowrap',
        maxWidth: '170px',
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '50px',
    }
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_TICKETS") {
        const tickets = action.payload;
        const newTickets = [];

        tickets.forEach((ticket) => {
            const ticketIndex = state.findIndex((c) => c.id === ticket.id);
            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;
            } else {
                newTickets.push(ticket);
            }
        });

        return [...state, ...newTickets];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const CategoryTicketsModal = ({ open, onClose, categoryId }) => {
    const classes = useStyles();
    const [searchParam, setSearchParam] = useState("");
    const [tickets, dispatch] = useReducer(reducer, []);
    const [listedTickets, setTickets] = useState([]);
    const [selectedTickets, setSelectedTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingTickets, setLoadingTickets] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const { categories } = useCategories({ showAll: true });
    const [status, setStatus] = useState('');
    const {queues} = useQueues();
    const {tags} = useTags({showAll:true})
    const {whatsApps}=useWhatsApps()
    

    const [category, setCategory] = useState('');

    useEffect(() => {
        (async () => {
            setLoading(true)
            if (!categoryId) return;
            try {
                const { data } = await api.get(`/categories/${categoryId}`);
                setSelectedTickets(data.tickets);
                setTickets(data.tickets);
                setCategory(data);
                setLoading(false);
            } catch (err) {
                toastError(err);
                setLoading(false);

            }
        })();

        return () => {

        };
    }, [categoryId, open]);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam, open, status]);

    useEffect(() => {
        setLoadingTickets(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async () => {
                const queueIds = queues ? queues.map((q) => q.id):[];
                const tagIds = tags ? tags?.map((t) => t.id):[];
                const whatsAppsIds =whatsApps ? whatsApps?.map((w) => w.id):[];
                try {
                    const { data } = await api.get("/tickets/", {
                        params: {
                            searchParam, 
                            pageNumber, 
                            status, 
                            showAll: true, 
                            queueIds: JSON.stringify(queueIds),
                            tagIds: JSON.stringify(tagIds),
                            connectionIds: JSON.stringify(whatsAppsIds), },
                    });
                    dispatch({ type: "LOAD_TICKETS", payload: data.tickets });
                    setHasMore(data.hasMore);
                    setLoadingTickets(false);
                } catch (err) {
                    toastError(err);
                    setLoadingTickets(false);
                }
            };
            fetchTickets();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, status,queues, tags, whatsApps, open]);

    const handleSearch = (event) => {
        setSearchParam(event.target.value);
    };

    const handleScroll = (e) => {
        if (!hasMore || loadingTickets) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    }

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleChange = (e) => {
        let value = +e.target.value;
        let TicketsList = tickets.filter((ticket) => ticket.id === value)
        let checked = e.target.checked;
        if (checked === true) {
            setSelectedTickets([...selectedTickets, TicketsList[0]])
        } else if (checked === false) {
            setSelectedTickets((current) => current.filter((ticket) => ticket.id !== value));
        }
    }

    const handleClose = () => {
        setSelectedTickets([])
        setLoading(false)
        onClose();
    }

    const unselectAll = () => {
        setSelectedTickets([]);
    }
    const handleChangeFilter = (event) => {
        setStatus(event.target.value);
    };

    const handleAddTickets = async () => {
        setLoading(true);
        let values = { ...categories, tickets: selectedTickets }
        try {
            if (categoryId) {
                await api.put(`/categories/${categoryId}`, values);
            } else {
                await api.post("/categories", values);
            }
            toast.success(i18n.t('Categoria adicionada aos tickets selecionados'));
        } catch (err) {
            toastError(err);
        }
        handleClose()
    }

    const Sidebar = () => {
        return (
            <Grid item xs={5}>
                <Paper style={{ padding: '20px' }} elevation={0} variant='outlined'>
                    <Typography variant="body2" gutterBottom>
                        {`${i18n.t('categories.ticketsModal.notices.selecteds')} ${selectedTickets.length}`}
                    </Typography>
                    <div style={{ height: 300, overflowY: "scroll" }}>
                        <FormGroup row>
                            <Table size="small">
                                <TableHead>
                                    <TableRow className={classes.tableCustomRow} >
                                        <TableCell align="center" padding="checkbox" />
                                        <TableCell align="center" padding="checkbox" />
                                        <TableCell align="left">{i18n.t("categories.table.number")}</TableCell>
                                        <TableCell align="center">
                                            {i18n.t("categories.table.details")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {i18n.t("categories.table.date")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedTickets?.length > 0 &&
                                        selectedTickets.map(ticket => (
                                            <TableRow className={classes.tableCustomRow}>
                                                <TableCell align="center" padding="checkbox">
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            size="small"
                                                            color="primary"
                                                            value={ticket.id}
                                                            checked={selectedTickets.some(current => current.id === ticket.id)}
                                                            onChange={handleChange}
                                                            name={ticket.id}

                                                        />} />
                                                </TableCell>
                                                <TableCell align="center">
                                                    {ticket.extraInfo?.length > 0 ?
                                                        <AvatarTooltip
                                                            arrow
                                                            icon={<Info />}
                                                            placement="right"
                                                            title={
                                                                <>
                                                                    {ticket.contact?.extraInfo?.length > 0 && ticket.contact?.extraInfo.map((info) =>
                                                                        <Typography>
                                                                            {`${info.name}: ${info.value}`}
                                                                        </Typography>
                                                                    )}
                                                                </>
                                                            }
                                                        >
                                                            <Avatar src={ticket.contact?.profilePicUrl} />
                                                        </AvatarTooltip>
                                                        : <Avatar src={ticket.contact?.profilePicUrl} />
                                                    }

                                                </TableCell>
                                                <TableCell align="center">{`#${ticket.id}`} </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        noWrap
                                                        style={{ maxWidth: 150, fontSize: 14 }}
                                                    >
                                                        {ticket.contact?.name}
                                                        {ticket.contact?.company &&
                                                            <div>
                                                                <Typography
                                                                    noWrap
                                                                    style={{ marginTop: "-5px", maxWidth: 150, fontSize: 12 }}
                                                                    color="textSecondary"
                                                                >
                                                                    {`${ticket.contact?.company}`}
                                                                </Typography>
                                                            </div>
                                                        }
                                                        <Typography
                                                            noWrap
                                                            style={{ marginTop: "-5px", maxWidth: 150, fontSize: 12 }}
                                                            color="textSecondary"
                                                        >
                                                            {`${ticket?.lastMessage??""}`}
                                                        </Typography>
                                                        <TagsWrapper tags={ticket.categories} maxWidth={170} />
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography
                                                        style={{ minWidth: 150, fontSize: 12 }}
                                                        color="textSecondary"
                                                    >
                                                        {i18n.t("categories.table.createdAt")}
                                                        {
                                                            isSameDay(parseISO(ticket.createdAt), new Date())
                                                                ? format(parseISO(ticket.createdAt), "HH:mm")
                                                                : format(parseISO(ticket.createdAt), "dd/MM/yy")
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        style={{ minWidth: 150, fontSize: 12 }}
                                                        color="textSecondary"
                                                    >
                                                        {i18n.t("categories.table.updatedAt")}
                                                        {
                                                            isSameDay(parseISO(ticket.updatedAt), new Date())
                                                                ? format(parseISO(ticket.updatedAt), "HH:mm")
                                                                : format(parseISO(ticket.updatedAt), "dd/MM/yy")
                                                        }
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}{loading && <TableRowSkeleton checkboxes={2} avatar={1} columns={2} />}
                                </TableBody>
                            </Table>
                        </FormGroup>
                    </div>
                    <Button onClick={unselectAll}>{i18n.t('categories.ticketsModal.buttons.unselectAll')}</Button>
                </Paper>

            </Grid>
        )
    }

    return (
        <div className={classes.root}>
            <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose} scroll="paper">
                <DialogTitle
                    color="textPrimary"
                >
                    {i18n.t("categories.ticketsModal.title", { categoryName: category.name })}
                </DialogTitle>
                <DialogContent dividers>
                    <>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={7}>
                                <div className={classes.root}>
                                    <TextField
                                        placeholder={i18n.t("categories.searchPlaceholder")}
                                        type="search"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        value={searchParam}
                                        onChange={handleSearch}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon style={{ color: "gray" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormControl variant="outlined" margin='dense' className={classes.formControl}>
                                        <InputLabel>{i18n.t("dashboard.filters.status")}</InputLabel>
                                        <Select
                                            value={status}
                                            onChange={handleChangeFilter}
                                            label={i18n.t("dashboard.filters.status")}
                                        >
                                            <MenuItem value={''}>Todos</MenuItem>
                                            <MenuItem value={'pending'}>Aguardando</MenuItem>
                                            <MenuItem value={'open'}>Atendendo</MenuItem>
                                            <MenuItem value={'closed'}>Finalizado</MenuItem>
                                            
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ height: 330, overflowY: "scroll" }} onScroll={handleScroll}>
                                    <FormGroup row>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow className={classes.tableCustomRow} >
                                                    <TableCell padding="checkbox" />
                                                    <TableCell padding="checkbox" />
                                                    <TableCell align="center">{i18n.t("categories.table.number")}</TableCell>
                                                    <TableCell align="center">
                                                        {i18n.t("categories.table.details")}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {i18n.t("categories.table.date")}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tickets?.length > 0 &&
                                                    tickets.map(ticket => (


                                                        <TableRow className={classes.tableCustomRow} key={ticket.id}>
                                                            <TableCell align="center" padding="checkbox">
                                                                <FormControlLabel
                                                                    control={<Checkbox
                                                                        size="small"
                                                                        color="primary"
                                                                        value={ticket.id}
                                                                        checked={selectedTickets.some(current => current.id === ticket.id)}
                                                                        onChange={handleChange}
                                                                        name={ticket.id}
                                                                    />} />
                                                            </TableCell>
                                                            <TableCell style={{ paddingRight: 0 }}>
                                                                {ticket.extraInfo?.length > 0 ?
                                                                    <AvatarTooltip
                                                                        arrow
                                                                        icon={<Info />}
                                                                        placement="right"
                                                                        title={
                                                                            <>
                                                                                {ticket.extraInfo?.length > 0 && ticket.extraInfo.map((info) =>
                                                                                    <Typography>
                                                                                        {`${info.name}: ${info.value}`}
                                                                                    </Typography>
                                                                                )}
                                                                            </>
                                                                        }
                                                                    >
                                                                        <Avatar src={ticket.contact.profilePicUrl} />
                                                                    </AvatarTooltip>
                                                                    : <Avatar src={ticket.contact.profilePicUrl} />
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center">{`#${ticket.id}`} </TableCell>
                                                            <TableCell align="left">
                                                                <Typography
                                                                    noWrap
                                                                    style={{ maxWidth: 150, fontSize: 14 }}
                                                                >
                                                                    {ticket.contact.name}
                                                                    {ticket.company &&
                                                                        <div>
                                                                            <Typography
                                                                                noWrap
                                                                                style={{ marginTop: "-5px", maxWidth: 150, fontSize: 12 }}
                                                                                color="textSecondary"
                                                                            >
                                                                                {`${ticket.contact.company}`}
                                                                            </Typography>
                                                                        </div>
                                                                    }
                                                                    <Typography
                                                                        noWrap
                                                                        style={{ marginTop: "-5px", maxWidth: 150, fontSize: 12 }}
                                                                        color="textSecondary"
                                                                    >
                                                                        {`${ticket?.lastMessage}`}
                                                                    </Typography>
                                                                    <TagsWrapper tags={ticket.categories} maxWidth={170} />
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Typography
                                                                    style={{ minWidth: 150, fontSize: 12 }}
                                                                    color="textSecondary"
                                                                >
                                                                    {i18n.t("categories.table.createdAt")}
                                                                    {
                                                                        isSameDay(parseISO(ticket.createdAt), new Date())
                                                                        ? format(parseISO(ticket.createdAt), "HH:mm")
                                                                        : format(parseISO(ticket.createdAt), "dd/MM/yy")
                                                                    }
                                                                </Typography>
                                                                <Typography
                                                                    style={{ minWidth: 150, fontSize: 12 }}
                                                                    color="textSecondary"
                                                                >
                                                                    {i18n.t("categories.table.updatedAt")}
                                                                    {
                                                                        isSameDay(parseISO(ticket.updatedAt), new Date())
                                                                        ? format(parseISO(ticket.updatedAt), "HH:mm")
                                                                        : format(parseISO(ticket.updatedAt), "dd/MM/yy")
                                                                    }
                                                                </Typography>
                                                            </TableCell>

                                                        </TableRow>

                                                    ))}{loadingTickets && <TableRowSkeleton checkboxes={1} avatar={1} columns={2} />}
                                            </TableBody>
                                        </Table>
                                    </FormGroup>
                                </div>


                            </Grid>
                            <Sidebar />
                        </Grid>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        disabled={loading}
                        variant="outlined"
                    >
                        {i18n.t("categories.buttons.cancel")}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        onClick={handleAddTickets}
                        color="primary"
                        disabled={loading}
                        loading={loading}
                    >
                        {i18n.t("categories.buttons.add")}
                    </ButtonWithSpinner>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default CategoryTicketsModal;