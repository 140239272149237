import { Box, Rating, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon color="error" />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon color="warning" />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon color="success" />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon color="success" />,
        label: 'Very Satisfied',
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};

const RADIAN = Math.PI / 180;

const cx = 150;
const cy = 200;
const iR = 90;
const oR = 100;
const needle = (value, data, cx, cy, iR, oR, color) => {
    let total = 0;
    data.forEach((v) => {
        total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
        <circle key="needle-circle" cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
        <path key="needle-path" d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />
    ];
};

const labels = {
    0.5: 'Muito Ruim',
    1: 'Muito Ruim+',
    1.5: 'Ruim',
    2: 'Ruim+',
    2.5: 'Regular',
    3: 'Regular+',
    3.5: 'Bom',
    4: 'Bom+',
    4.5: 'Excelente',
    5: 'Excelente+',
};

function convertToFive(value) {
    // Calcula a proporção
    var proportion = value / 100;

    // Multiplica pela escala desejada (5)
    var valueInFive = proportion * 5;

    // Arredonda para o inteiro mais próximo
    var roundedValue = Math.round(valueInFive);

    return roundedValue;
}

// Função de easing (easeInOutQuad)
const easeInOutQuad = (t) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
};

const ReviewsChart = ({ promotorsAmnt, totalAmnt }) => {
    const theme = useTheme();
    const value = Math.round((promotorsAmnt / totalAmnt) * 100) || 0;
    const [animatedValue, setAnimatedValue] = useState(0);
    const animationRef = useRef(null);

    const data = [
        { name: 'detractors', value: 50, color: theme.palette.error.main },
        { name: 'neutral', value: 25, color: theme.palette.warning.main },
        { name: 'promotors', value: 25, color: theme.palette.success.main },
    ];

    const animateValue = (start, end, duration) => {
        const startTime = performance.now();

        const stepFunction = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            const easedProgress = easeInOutQuad(progress);
            const current = start + (end - start) * easedProgress;
            setAnimatedValue(current);

            if (progress < 1) {
                animationRef.current = requestAnimationFrame(stepFunction);
            }
        };

        animationRef.current = requestAnimationFrame(stepFunction);
    };

    useEffect(() => {
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
        }
        animateValue(animatedValue, value, 1000); // duração da animação em milissegundos
    }, [value]);

    return (
        <Box sx={{ textAlign: "center" }}>
            <PieChart width={400} height={250}>
                <Pie
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    data={data}
                    cx={cx}
                    cy={cy}
                    innerRadius={iR}
                    outerRadius={oR}
                    fill="#8884d8"
                    stroke="none"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
                {needle(animatedValue, data, cx, cy, iR, 60, "gray")}
            </PieChart>
            <Typography>
                {`${animatedValue.toFixed(0)}% ${labels[convertToFive(animatedValue)] || "Sem avaliação"}`}
            </Typography>
            <StyledRating
                name="highlight-selected-only"
                value={convertToFive(animatedValue)}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value]?.label}
                highlightSelectedOnly
                readOnly
            />
            <Typography>
                {`Promotores: ${promotorsAmnt}`}
            </Typography>
            <Typography>
                {`Avaliações: ${totalAmnt}`}
            </Typography>
        </Box>
    );
};

export default ReviewsChart;
