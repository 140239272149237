import { Chip, List, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import useCategories from "../../hooks/useCategories";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > * + *": {
            marginTop: theme.spacing(3),
        },
    },
}));

const CategoryTicketManager = ({ ticketId, ticketData, onChange }) => {
    const classes = useStyles();
    const [ticketCategories, setTicketCategories] = useState(ticketData.categories||[]);
    const [searchParam, setSearchParam] = useState();
    const { categories, loading, hasMore } = useCategories({ searchParam, showAll: true });

    useEffect(()=>{
        if (ticketCategories !== ticketData.categories)
        onChange(ticketCategories);
    },[ticketCategories]);

    const filterCategories = (categories, inputValue) => {
        const filteredOptions = categories.filter(
            (category) =>
                category.name.toLowerCase().includes(inputValue.toLowerCase().trim()) ||
                category.id.toString().includes(inputValue)
        );

        if (filteredOptions.length === 0 && inputValue !== "") {
            const newOption = { id: -1, name: inputValue };
            filteredOptions.push(newOption);
        }
        return filteredOptions;
    };


    const getRandomColor = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const handleCreateCategory = async (name) => {
        const newCategory = { name, color: getRandomColor(), tickets: [ticketData] };
        try {
            if (newCategory) {
                const { data } = await api.post("/categories/", newCategory);
                setTicketCategories([...ticketCategories, data]);
            }
            onChange(ticketCategories)
            toast.success("Categoria salva com sucesso!");
        } catch (err) {
            toastError(err);
        }
    }
    const renderCategories = (values, props) => {
        return (
            values.map((option, index) => (
                <Chip
                    key={option.id}
                    className={classes.chip}
                    label={option.name}
                    style={{ backgroundColor: option.color }}
                    {...props({ index })}
                />
            ))
        )
    }

    const renderOption = (props, option) => {
        if (option.id !== -1) {
            return (
                <li {...props}>
                    {option.name}
                </li>
            )
        } else {
            return (<li {...props}>{`${i18n.t("newTicketModal.add")} ${option.name}`}</li>);
        }
    }

    const handleAddCategories = async (event, value) => {
        if (!value) return;
        value.map((category) => {
            if (category.id === -1) {
                handleCreateCategory(category?.name);
            }
        })
        setTicketCategories(value);
    }

    return (
        <div className={classes.root}>
            <Autocomplete
                multiple
                loading={loading}
                clearOnBlur
                autoHighlight
                freeSolo
                clearOnEscape
                options={categories}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => renderOption(props, option)}
                filterOptions={(options, params) => filterCategories(options, params.inputValue)}
                onChange={(event, value) => handleAddCategories(event, value)}
                value={ticketCategories}
                renderTags={(value, getCategoryProps) =>
                    renderCategories(value, getCategoryProps)
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="dense"
                        variant="outlined"
                        label={i18n.t("ticketDrawer.categories", { type: ticketData.isGroup ? i18n.t("ticketDrawer.group") : i18n.t("ticketDrawer.contact") })}
                        onChange={(e) => setSearchParam(e.target.value)}
                    />
                )}
            />
        </div>
    );
};

export default CategoryTicketManager;
