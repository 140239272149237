import React, { useState, useContext } from 'react';
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import { AuthContext } from "../../context/Auth/AuthContext";

import { Menu } from "@mui/material";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import FowardingModal from "../FowardingModal";


const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl, isGroup, connType }) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { setReplyingMessage } = useContext(ReplyMessageContext);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [fowardModalOpen, setFowardModalOpen] = useState(false);

  const handleNewChat = async () => {
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: message.contact?.id,
        userId: user.id,
        status: "open",
      });
      history.push(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
    handleClose();
  }
  const handleDeleteMessage = async () => {
    try {
      await api.delete(`/messages/${message.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleReplyMessage = () => {
    setReplyingMessage(message);
    handleClose();
  };

  const handleOpenConfirmationModal = (e) => {
    setConfirmationOpen(true);
    handleClose();
  };
  const handleFowardMessage = () => {
    setFowardModalOpen(true);
    handleClose();
  }

  const isWhatsapp = (e) => {
    if (connType === 'whatsapp'
      || connType === 'bwhatsapp'
			/* || !ticket.contact?.connectionId */) {
      return true;
    }
    return false;
  }
  return (
    <>
      <FowardingModal
        modalOpen={fowardModalOpen}
        onClose={setFowardModalOpen}
        message={message}
      />
      <ConfirmationModal
        title={i18n.t("messageOptionsMenu.confirmationModal.title")}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteMessage}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {(!message.fromMe && isGroup) && (
          <MenuItem disabled={!message.contact?.number || !isWhatsapp()} onClick={handleNewChat}>
            {`${i18n.t("messageOptionsMenu.chat")} ${message.contact?.name}`}
          </MenuItem>
        )}
        <MenuItem disabled={!isWhatsapp()} onClick={handleReplyMessage}>
          {i18n.t("messageOptionsMenu.reply")}
        </MenuItem>

        {!isGroup && isWhatsapp() &&
          <MenuItem onClick={handleFowardMessage}>
            {i18n.t("messageOptionsMenu.foward")}
          </MenuItem>
        }
        {message.fromMe && (
          <MenuItem disabled={!isWhatsapp()} onClick={handleOpenConfirmationModal}>
            {i18n.t("messageOptionsMenu.delete")}
          </MenuItem>
        )}
        {/* <MenuItem onClick={()=>console.log(message.id)}>
            Reagir
          </MenuItem> */}
      </Menu>
    </> 
  );


};

export default MessageOptionsMenu;
