import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tab, Tabs, Tooltip, colors } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import React, { useEffect, useState } from "react";
import MainHeader from "../MainHeader";
import { i18n } from "../../translate/i18n";
import MainHeaderButtonsWrapper from "../MainHeaderButtonsWrapper";
import { Help } from "@mui/icons-material";
import CampaignConfig from "./config";
import CampaignContacts from "./contacts";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import { addHours, format, parse } from "date-fns";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        gap: 10
    },
    inlineWrapper: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        flexWrap: "wrap",
        gap: 4,
        /* [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            '& > *': {
                marginBottom: 4,
            },
        }, */
    },
    tableCustomRow: {
        '& td, & th': {
            borderColor: theme.palette.divider,
        },
    },
    multiLine: {
        display: "flex",
        //margin:"auto",
        gap: 4,
        flex: 1,
    },
    formControl: {
        minWidth: 160,
    },
    searchBar: {
        display: "flex",
        margin: `0 ${theme.spacing(1)}`,
    },
    textField: {
        flex: "1 0 30%",
        minWidth: "200px",
        //paddingBottom: "60px !important",
        "& textarea": {
            marginBottom: "30px !important",
        }
    },
    mediaField: {
        flex: "1 0 30%",
        minWidth: "200px",
        // display: "flex",
        // //margin:"auto",
        // gap: 4,
        // flex: 1,
    },

    btnWrapper: {
        position: "relative",
    },
    spaceBetween: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between"
    },

    buttonProgress: {
        color: theme.palette.primary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
    errorIcon: {
        color: theme.palette.secondary.main
    },
    emojiBox: {
        position: "absolute",
        bottom: 0,
        width: 40,
        borderTop: "1px solid #e8e8e8",
    },
    uploadInput: {
        cursor: "default",
        minHeight: "56px",
        "& input": {
            display: "none",
        }
    },
    uploadInputHide: {
        minHeight: "56px",
        "& input": {
            cursor: "pointer",
            opacity: 0
        }
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
        maxWidth: "100%",
        marginTop: "-45px",
        padding: "5px 0px"
    },
    flexContainer: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        width: "100%"
    },
    fileNameBreaker: {
        maxWidth: "calc(100% - 10px)",
        textAlign: "center",
        wordWrap: "break-word",
        [theme.breakpoints.down('md')]: {
            maxWidth: "calc(100% - 10px)",
        },
    },

}));


const CampaignSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Muito curto!")
        .max(100, "Muito longo")
        .required("Obrigatório!"),
    sendAt: Yup.date()
        .required("Obrigatório")
        .min(new Date(), "A data precisa ser maior que atual"),
    minInterval: Yup.string()
        .required("Obrigatório"),
    maxInterval: Yup.string()
        .required("Obrigatório")
        .when('minInterval', (minInterval, schema) => {
            return schema.test({
                test: value => parse(value, 'HH:mm:ss', new Date()) > parse(minInterval, 'HH:mm:ss', new Date()),
                message: 'O tempo máximo do intervalo deve ser maior que o mínimo'
            });
        }),
    startRestrict: Yup.string()
        .required("Obrigatório"),
    endRestrict: Yup.string()
        .required("Obrigatório"),
    message: Yup.string().required("Obrigatório"),
    message2: Yup.string(),
    message3: Yup.string(),
    message4: Yup.string(),
    message5: Yup.string(),
    message6: Yup.string(),
});

const CampaignModal = ({ open, onClose, campaignId }) => {
    const classes = useStyles();
    const initialState = {
        name: "",
        sendAt: format(addHours(new Date(), 1), "yyyy-MM-dd'T'HH:mm"),
        minInterval: "00:02:00",
        maxInterval: "00:06:00",
        startRestrict: "20:00:00",
        endRestrict: "08:00:00",
        whatsappId: "",
        message: "",
        message2: "",
        message3: "",
        message4: "",
        message5: "",
        message6: "",
        messageMedia: [],
        messageMedia2: [],
        messageMedia3: [],
        messageMedia4: [],
        messageMedia5: [],
        messageMedia6: [],
        contacts: [],
    };


    const [campaign, setCampaign] = useState(initialState);
    const [value, setValue] = useState("config");

    useEffect(() => {
        (async () => {
            if (!campaignId) return;
            try {
                const { data } = await api.get(`/campaigns/${campaignId}`);
                setCampaign(prevState => {
                    return { ...prevState, ...data };
                });
            } catch (err) {
                toastError(err);
            }
        })();

        return () => {
            setCampaign(initialState);
        };
    }, [campaignId, open]);

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    const handleSaveCampaign = async values => {
        const formData = new FormData();
        let campaignData = { ...values };
        formData.append("name", campaignData.name);
        formData.append("sendAt", campaignData.sendAt);
        formData.append("minInterval", campaignData.minInterval);
        formData.append("maxInterval", campaignData.maxInterval);
        formData.append("startRestrict", campaignData.startRestrict);
        formData.append("endRestrict", campaignData.endRestrict);
        formData.append("message", campaignData.message);
        formData.append("message2", campaignData.message2);
        formData.append("message3", campaignData.message3);
        formData.append("message4", campaignData.message4);
        formData.append("message5", campaignData.message5);
        formData.append("message6", campaignData.message6);
        formData.append("contacts", JSON.stringify(campaignData.contacts));
        formData.append("whatsappId", campaignData.whatsappId);
        formData.append("messageMedia", campaignData.messageMedia[0]);
        formData.append("messageMedia2", campaignData.messageMedia2[0]);
        formData.append("messageMedia3", campaignData.messageMedia3[0]);
        formData.append("messageMedia4", campaignData.messageMedia4[0]);
        formData.append("messageMedia5", campaignData.messageMedia5[0]);
        formData.append("messageMedia6", campaignData.messageMedia6[0]);

        try {
            if (campaignId) {
                await api.put(`/campaigns/${campaignId}`, formData);
            } else {
                await api.post("/campaigns/", formData);
            }
            toast.success("Campanha salva com sucesso!");
            handleClose();
        } catch (err) {
            toastError(err);
        }
    };

    const handleClose = () => {
        setCampaign(initialState);
        setValue("config");
        onClose();
    };
    return (
        <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={handleClose}>
            <MainHeader fullWidth>
                <DialogTitle
                    color="textPrimary"
                >
                    {campaignId
                        ? i18n.t('campaignModal.title.edit', { name: campaign.name })
                        : i18n.t('campaignModal.title.add')}
                </DialogTitle>

                {/* <MainHeaderButtonsWrapper>
                    <Tooltip arrow interactive title={
                        <React.Fragment>
                            <Alert severity="info">{i18n.t('campaignModal.notices.helper')}</Alert>
                        </React.Fragment>}
                    >
                        <IconButton
                            size="small"
                            className={classes.iconButton}
                        >
                            <Help />
                        </IconButton>
                    </Tooltip>
                </MainHeaderButtonsWrapper> */}
            </MainHeader>
            <Box sx={{ width: '100%' }}>
                <Tabs
                    value={value}
                    variant="fullWidth"
                    onChange={handleChangeTabs}
                    textColor="primary"
                    indicatorColor="primary"
                >
                    <Tab value="config" label="Definições" />
                    {/* <Tab value="messages" label="Mensagens" /> */}
                    <Tab value="contacts" label="Contatos" />
                </Tabs>
            </Box>

            <Formik
                initialValues={campaign}
                enableReinitialize={true}
                validationSchema={CampaignSchema}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleSaveCampaign(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <DialogContent dividers>
                            <div style={{ height: "53vh" }}>
                                {value === "config" &&
                                    <CampaignConfig
                                        setFieldValue={setFieldValue}
                                        campaign={campaign}
                                        classes={classes}
                                        touched={touched}
                                        errors={errors}
                                        values={values}
                                    />
                                }
                                {value === "contacts" &&
                                    <CampaignContacts
                                        campaign={campaign}
                                        classes={classes}
                                        touched={touched}
                                        errors={errors}
                                        values={values}
                                    />
                                }
                            </div>

                        </DialogContent>
                        <DialogActions style={{ flexWrap: "wrap" }}>
                            <Alert style={{ margin: 4 }} severity="warning">{i18n.t("campaignModal.notices.warning")}</Alert>
                            <Button
                                onClick={handleClose}
                                color="secondary"
                                disabled={isSubmitting}
                                variant="outlined"
                            >
                                {i18n.t("campaigns.buttons.cancel")}
                            </Button>
                            <ButtonWithSpinner
                                type="submit"
                                color="primary"
                                loading={isSubmitting}
                                variant="contained"
                                className={classes.btnWrapper}

                            >
                                {campaignId
                                    ? `${i18n.t("campaigns.buttons.edit")}`
                                    : `${i18n.t("campaigns.buttons.add")}`}
                            </ButtonWithSpinner>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}
export default CampaignModal;