import React, { useEffect, useState } from 'react';
import { IconButton, List, MenuList, Popover } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { i18n } from '../../translate/i18n';
import { AccountTreeOutlined, FilterListOutlined, LocalOfferOutlined, SyncAltOutlined } from '@mui/icons-material';
import ExpandableList from '../ExpandableList';


const useStyles = makeStyles((theme) => ({
    iconButton: {
        color: theme.palette.text.secondary
    },
}))
const TicketsFilter = ({
    connections, queues, tags,
    selectedConnectionsIds, selectedQueuesIds, selectedTagsIds,
    onChange
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedQueueIds, setSelectedQueuesIds] = useState(selectedQueuesIds)
    const [selectedTagIds, setSelectedTagsIds] = useState(selectedTagsIds)
    const [selectedConnectionIds, setSelectedConnectionsIds] = useState(selectedConnectionsIds)
    const [tagsLoaded, setTagsLoaded] = useState(false);
    const [whatsAppsLoaded, setWhatsAppsLoaded] = useState(false);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    useEffect(() => {
        if (connections.length > 0 && !whatsAppsLoaded) {
            setSelectedConnectionsIds(connections.map((c) => c.id));
            setWhatsAppsLoaded(true);
        }
    }, [connections, whatsAppsLoaded]);

    useEffect(() => {
        if (tags.length > 0 && !tagsLoaded) {
            setSelectedTagsIds(tags.map((t) => t.id));
            setTagsLoaded(true);
        }
    }, [tags, tagsLoaded]);

    useEffect(() => {
        onChange(selectedQueueIds, selectedTagIds, selectedConnectionIds)
    }, [selectedQueueIds, selectedTagIds, selectedConnectionIds]);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const colorMap = {
        'whatsapp': '#1cd742',
        'bwhatsapp': '#1cd742',
        'facebook': '#1877f2',
        'instagram': '#C13584',
    };

    connections = connections.map(conn => ({
        ...conn,
        color: colorMap[conn.connectionType]
    }));

    return <>
        <IconButton
            className={classes.iconButton}
            aria-describedby={id}
            onClick={handleOpen}
            variant="outlined"
            size="large">
            <FilterListOutlined />
        </IconButton>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <MenuList>
                <ExpandableList
                    icon={<AccountTreeOutlined />}
                    label={i18n.t("ticketsFilter.filters.queues")}
                    items={queues}
                    onChange={(values) => setSelectedQueuesIds(values)}
                    selectedItemsId={selectedQueueIds}
                />
                <ExpandableList
                    icon={<LocalOfferOutlined />}
                    label={i18n.t("ticketsFilter.filters.tags")}
                    items={tags}
                    onChange={(values) => setSelectedTagsIds(values)}
                    selectedItemsId={selectedTagIds}
                />
                <ExpandableList
                    icon={<SyncAltOutlined />}
                    label={i18n.t("ticketsFilter.filters.connections")}
                    items={connections}
                    onChange={(values) => setSelectedConnectionsIds(values)}
                    selectedItemsId={selectedConnectionIds}
                />
            </MenuList>
        </Popover>
    </>;
}

export default TicketsFilter;