import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useTickets = ({
    searchParam,
    pageNumber,
    status,
    isGroup,
    date,
    showAll,
    queueIds,
    tagIds,
    connectionIds,
    withUnreadMessages,
}) => {
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [count, setCount] = useState(0);
    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {

            const fetchTickets = async () => {
                try {
                    const { data } = await api.get("/tickets", {
                        params: {
                            searchParam,
                            pageNumber,
                            status,
                            isGroup,
                            date,
                            showAll,
                            queueIds,
                            tagIds,
                            connectionIds,
                            withUnreadMessages,
                        },
                    })
                    setTickets(data.tickets)

                    let hoursCloseTicketsAut = 0;

                    if (status === "open" && hoursCloseTicketsAut && hoursCloseTicketsAut !== "" &&
                        hoursCloseTicketsAut !== "0" && Number(hoursCloseTicketsAut) > 0) {

                        let dateLimit = new Date()
                        dateLimit.setHours(dateLimit.getHours() - Number(hoursCloseTicketsAut))

                        data.tickets.forEach(ticket => {
                            if (ticket.status !== "closed") {
                                let dateLastInteraction = new Date(ticket.updatedAt)
                                if (dateLastInteraction < dateLimit)
                                    closeTicket(ticket, "closed")
                            }
                        })
                    }
                    // let hoursExpireTicketsAut = 24;

                    // if (hoursExpireTicketsAut && hoursExpireTicketsAut !== "" &&
                    //     hoursExpireTicketsAut !== "0" && Number(hoursExpireTicketsAut) > 0) {

                    //     let dateLimit = new Date()
                    //     dateLimit.setHours(dateLimit.getHours() - Number(hoursExpireTicketsAut))

                    //     data.tickets.forEach(ticket => {
                    //         if (ticket.status !== "expired" && (ticket.whatsapp?.connectionType === 'facebook' || ticket.whatsapp?.connectionType === 'instagram')) {
                    //             let dateLastInteraction = new Date(ticket.updatedAt)
                    //             if (dateLastInteraction < dateLimit)
                    //                 closeTicket(ticket, "expired")
                    //         }
                    //     })
                    // }

                    setHasMore(data.hasMore);
                    setCount(data.count);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            }

            const closeTicket = async (ticket, status) => {
                await api.put(`/tickets/${ticket.id}`, {
                    status: status,
                    userId: ticket.userId || null,
                })
            }
            fetchTickets()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [
        searchParam,
        pageNumber,
        isGroup,
        status,
        date,
        showAll,
        queueIds,
        tagIds,
        connectionIds,
        withUnreadMessages,
    ])

    return { tickets, loading, hasMore, count };
};

export default useTickets;