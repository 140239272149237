import React, { useState } from "react";
import { MenuItem, FormControl, Divider, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    mainpaper: {
        padding: theme.spacing(2),
        alignItems: "center",
        marginBottom: 12,
    },
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightMedium,
    },
    settingOption: {
        marginLeft: "auto",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        backgroundColor: 'transparent'
    },
    childpaper: {
        backgroundColor: theme.palette.background.default + '5b',
        
    },
}));

const UserSettings = () => {
    const classes = useStyles();
    const [theme, setTheme] = useState(localStorage.getItem("theme") === null ? "light" : localStorage.getItem("theme"));
    const [checkedTheme, setCheckedTheme] = useState(theme);
    const [notificationSetting, setNotification] = useState(localStorage.getItem("notificationsnd") === null ? "enabled" : localStorage.getItem("notificationsnd"));
    const [checkedNotification, setCheckedNotification] = useState(notificationSetting === "disabled" ? false : true);

    const handleChangeTheme = (e) => {
        setCheckedTheme(e.target.value);
        localStorage.setItem("theme", e.target.value);
        window.location.reload(false);
    };

    const notificationToggler = () => {
        notificationSetting === "disabled" ? setNotification("enabled") : setNotification("disabled");
        localStorage.setItem("notificationsnd", notificationSetting === "disabled" ? "enabled" : "disabled");
    }
    const handleChangeNotification = (event) => {
        setCheckedNotification(event.target.value);
        if (checkedNotification === false || checkedNotification === true) {
            notificationToggler();
        }
    };

    return (
        <>
            <Paper elevation={0} variant="outlined" className={classes.childpaper}>
                <Paper elevation={0} className={classes.paper}>
                    <Typography fullWidth variant="body1">
                        {i18n.t("settings.theme.name")}
                    </Typography>
                    <FormControl className={classes.settingOption}>
                        <Select
                            margin="dense"
                            id="theme-select"
                            variant="outlined"
                            value={checkedTheme}
                            onChange={handleChangeTheme}
                            className={classes.settingOption}
                        >
                            <MenuItem value={"light"}>{i18n.t("settings.theme.options.light")}</MenuItem>
                            <MenuItem value={"dark"}>{i18n.t("settings.theme.options.dark")}</MenuItem>
                            <MenuItem value={"wappLight"}>{i18n.t("Wapp Claro")}</MenuItem>
                            <MenuItem value={"wappDark"}>{i18n.t("Wapp Escuro")}</MenuItem>
                            <MenuItem value={"crayolaLight"}>{i18n.t("Crayola Claro")}</MenuItem>
                            <MenuItem value={"crayolaDark"}>{i18n.t("Crayola Escuro")}</MenuItem>
                            <MenuItem value={"metro"}>{i18n.t("Metro")}</MenuItem>
                            <MenuItem value={"adwLight"}>{i18n.t("Adwaita Claro")}</MenuItem>
                            <MenuItem value={"adwDark"}>{i18n.t("Adwaita Escuro")}</MenuItem>
                        </Select>
                    </FormControl>
                </Paper>
                <Paper elevation={0} className={classes.paper}>
                    <Typography fullWidth variant="body1">
                        {i18n.t("settings.notifications.name")}
                    </Typography>
                    <FormControl className={classes.settingOption}>
                        <Select
                            margin="dense"
                            id="notificationsnd-select"
                            variant="outlined"
                            value={checkedNotification}
                            onChange={handleChangeNotification}
                            className={classes.settingOption}
                        >
                            <MenuItem value={true}>{i18n.t("settings.notifications.options.enabled")}</MenuItem>
                            <MenuItem value={false}>{i18n.t("settings.notifications.options.disabled")}</MenuItem>
                        </Select>
                    </FormControl>
                </Paper>
            </Paper>
        </>
    );
};

export default UserSettings;