import React, { useState, useEffect, useReducer, useContext } from "react";
import Title from "../../components/Title";
import MainHeader from "../../components/MainHeader";
import makeStyles from '@mui/styles/makeStyles';
import { Button, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import MainContainer from "../../components/MainContainer";
import { i18n } from "../../translate/i18n";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { DeleteOutline, Edit, GroupAdd } from "@mui/icons-material";
import TagsModal from "../../components/TagsModal";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import openSocket from "../../services/socket-io";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import TagContactsModal from "../../components/TagContactsModal";
import SearchIcon from "@mui/icons-material/Search";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    margin: theme.spacing(1),
    marginTop: 0,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    color: theme.palette.text.secondary
  },
  trashIcon: {
    color: theme.palette.secondary.main
  },
  tableCustomRow: {
    '& td, & th': {
      borderColor: theme.palette.divider,
    },
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_TAGS") {
    const tags = action.payload;
    const newTags = [];

    tags.forEach((tag) => {
      const tagIndex = state.findIndex((q) => q.id === tag.id);
      if (tagIndex !== -1) {
        state[tagIndex] = tag;
      } else {
        newTags.push(tag);
      }
    });

    return [...state, ...newTags];
  }

  if (action.type === "UPDATE_TAG") {
    const tag = action.payload;
    const tagIndex = state.findIndex((u) => u.id === tag.id);

    if (tagIndex !== -1) {
      state[tagIndex] = tag;
      return [...state];
    } else {
      return [tag, ...state];
    }
  }

  if (action.type === "DELETE_TAG") {
    const tagId = action.payload;
    const tagIndex = state.findIndex((q) => q.id === tagId);
    if (tagIndex !== -1) {
      state.splice(tagIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Tags = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [tags, dispatch] = useReducer(reducer, []);
  const [tagModal, setTagModalOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [count,setCount]=useState(0);
  const [addTagsModal, setAddTagsModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const {user}= useContext(AuthContext);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);


  useEffect(() => {
    setLoading(true);
      const fetchTags = async () => {
        try {
          const { data } = await api.get("/tags", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_TAGS", payload: data.tags });
          setCount(data.count)
          setHasMore(data.hasMore);
          setLoading(false);

        } catch (err) {
          toastError(err);
          setLoading(false);
        }
      };
      fetchTags();
  }, [searchParam, pageNumber]);


  useEffect(() => {
    const socket = openSocket();

    socket.on("tag", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_TAG", payload: data.tag });

      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_TAG", payload: data.tagId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
      setLoading(true);
    }
  };

  const handleOpenTagsModal = () => {
    setTagModalOpen(true);
    setSelectedTags(null);
  }
  const handleEditTag = (tag) => {
    setSelectedTags(tag);
    setTagModalOpen(true);
  };
  const handleCloseTagsModal = () => {
    setTagModalOpen(false);
    setSelectedTags(null);
  };

  const handleCloseAddContactsToTag = () => {
    setAddTagsModalOpen(false);
    setSelectedTags(null);
  };
  const handleAddContactsToTag = (tag) => {
    setSelectedTags(tag);
    setAddTagsModalOpen(true);
  };

  const handleDeleteTag = async (tagId) => {
    try {
      await api.delete(`/tags/${tagId}`);
      toast.success(i18n.t("tags.deleteConfirmationModal.deleteSuccess"));
    } catch (err) {
      toastError(err);
    }
    setSelectedTags(null);
  };
  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedTags(null);
  };

  return (
    <>
      <TagsModal
        open={tagModal}
        onClose={handleCloseTagsModal}
        tagId={selectedTags?.id}
      />
      <TagContactsModal
        open={addTagsModal}
        onClose={handleCloseAddContactsToTag}
        tagId={selectedTags?.id}
      />
      <ConfirmationModal
        title={
          selectedTags &&
          `${i18n.t("tags.deleteConfirmationModal.title")} ${selectedTags.name
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteTag(selectedTags.id)}
      >

        {i18n.t("tags.deleteConfirmationModal.warning")}
      </ConfirmationModal>
      <MainContainer>
        <MainHeader>
          <Title>{`${i18n.t("tags.title")} (${count})`}</Title>
          <MainHeaderButtonsWrapper>
            <TextField
              placeholder={i18n.t("contacts.searchPlaceholder")}
              type="search"
              value={searchParam}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenTagsModal}
            >
              {i18n.t("tags.buttons.create")}
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Paper
          className={classes.mainPaper}
          variant="outlined"
          onScroll={handleScroll}
        >
          <Table size='small'>
            <TableHead>
              <TableRow className={classes.tableCustomRow}>
                <TableCell align="center">
                  {i18n.t("tags.table.name")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("tags.table.color")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("tags.table.contacts")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("tags.table.actions")}
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {tags.map((tag) => (
                <TableRow className={classes.tableCustomRow} key={tag.id} >
                  <TableCell align="center">
                    {tag.name}
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <span
                        style={{
                          backgroundColor: tag.color,
                          width: 60,
                          height: 20,
                          borderRadius: '50px',
                          alignSelf: "center",
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    {tag.contacts?.length}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      className={classes.iconButton}
                      onClick={() => handleAddContactsToTag(tag)}
                    >
                      <GroupAdd />
                    </IconButton>
                    <IconButton
                      size="small"
                      className={classes.iconButton}
                      onClick={() => handleEditTag(tag)}
                    >
                      <Edit />
                    </IconButton>
                    <Can
                      role={user.profile}
                      perform="drawer-admin-items:view"
                      yes={() => (
                        <IconButton
                          size="small"
                          className={classes.trashIcon}
                          onClick={() => {
                            setSelectedTags(tag);
                            setConfirmModalOpen(true);
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </TableBody>
          </Table>
        </Paper>
      </MainContainer>
    </>
  );
};

export default Tags;
