import { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const reducer = (state, action) => {
    if (action.type === "LOAD_USERS") {
        const users = action.payload;
        const newUsers = [];

        users.forEach((user) => {
            const userIndex = state.findIndex((q) => q.id === user.id);
            if (userIndex !== -1) {
                state[userIndex] = user;
            } else {
                newUsers.push(user);
            }
        });

        return [...state, ...newUsers];
    }

    if (action.type === "UPDATE_USER") {
        const user = action.payload;
        const userIndex = state.findIndex((u) => u.id === user.id);

        if (userIndex !== -1) {
            state[userIndex] = user;
            return [...state];
        } else {
            return [user, ...state];
        }
    }

    if (action.type === "DELETE_USER") {
        const userId = action.payload;
        const userIndex = state.findIndex((q) => q.id === userId);
        if (userIndex !== -1) {
            state.splice(userIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useUsers = ({ searchParam, pageNumber }) => {
    const [users, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        dispatch({ type: "RESET" });
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {

            const fetchUsers = async () => {
                try {
                    const { data } = await api.get("/users", { params: { searchParam, pageNumber } });
                    dispatch({ type: "LOAD_USERS", payload: data.users });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };
            fetchUsers();
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchParam, pageNumber]);


    useEffect(() => {
        const socket = openSocket();

        socket.on("user", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_USER", payload: data.user });

            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_USER", payload: +data.userId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return { users, hasMore, loading };
};

export default useUsers;