import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import MainHeader from "../MainHeader";
import { Add, ContentCopy, DeleteOutline, ExpandMore } from "@mui/icons-material";
import React,{ useContext, useEffect, useState } from "react";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { Field, FieldArray, Form, Formik } from "formik";
import { i18n } from "../../translate/i18n";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import makeStyles from '@mui/styles/makeStyles';
import { format, parse } from "date-fns";
import { toast } from "react-toastify";
import useUsers from "../../hooks/useUsers";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import * as Yup from "yup";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
    inlineWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        flexWrap: "wrap",
        gap: 4,
        margin: "4px 0"
    },
    multiLine: {
        display: "flex",
        flex: 1,
    },
    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: theme.palette.primary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    hourConfigContainer: {
        borderTop: `1px solid ${theme.palette.divider}`
    },
    accordion: {
        width: "100%",
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
    accordionSummary: {
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
        //flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    },
    accordionDetails: {
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }
}));

const openingHoursSchema = Yup.object().shape({
    users: Yup.array().of(
        Yup.object().shape({
            openingHours: Yup.array().of(
                Yup.object().shape({
                    start: Yup.string()
                        .required('Start time is required')
                        .matches(
                            /^([01]\d|2[0-3]):[0-5]\d$/,
                            'Invalid time format (HH:mm)'
                        ),
                    end: Yup.string()
                        .required('End time is required')
                        .matches(
                            /^([01]\d|2[0-3]):[0-5]\d$/,
                            'Invalid time format (HH:mm)'
                        )
                        .test('is-later-than-start', 'End time must be later than start time', function (end) {
                            const start = this.resolve(Yup.ref('start'));
                            return start && end ? start < end : true;
                        }),
                    days: Yup.array()
                        .min(1, 'At least one day must be selected')
                        .of(
                            Yup.string().oneOf(
                                ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
                                'Invalid day'
                            )
                        ),
                })
            ),
        })
    ),
});


const OpeningHoursModal = ({ open, onClose }) => {
    const classes = useStyles();
    const [pageNumber, setPageNumber] = useState(1);
    const { user: currentUser } = useContext(AuthContext);
    const { users, loading, hasMore } = useUsers(pageNumber);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUsersId, setSelectedUsersId] = useState([]);

    const handleClose = () => {
        setSelectedUser(null);
        setSelectedUsersId([]);
        onClose();
    }

    const onCopyHour = (e, user) => {
        e.stopPropagation();
        if (user?.openingHours?.length > 0) {
            setSelectedUser(user);
        } else {
            toast.error(i18n.t("openingHoursModal.noOpeningHours"))
        }
    }

    const handleSaveOpeningHours = async (values) => {
        try {
            const valuesData = values
                .map(user => ({ userId: user.id, bypassOpeningHours: user.bypassOpeningHours, openingHours: user.openingHours }));
            await api.put(`/users`, valuesData);
            toast.success(i18n.t("openingHoursModal.toasts.success"));
            handleClose();
        } catch (err) {
            toastError(err);
        }
    }

    const handleSelectUserToCopy = (e) => {
        e.stopPropagation();
        const checked = e.target.checked;
        const value = e.target.value;
        if (checked === true) {
            if (!selectedUsersId.includes(value)) {
                setSelectedUsersId([...selectedUsersId, value]);
            }
        } else if (checked === false) {
            setSelectedUsersId((current) => current.filter((index) => index !== value));
        }
    }

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
            <MainHeader fullWidth>
                <DialogTitle
                    color="textPrimary"
                    id="form-dialog-title"
                >
                    {i18n.t("openingHoursModal.title")}
                </DialogTitle>
            </MainHeader><LocalizationProvider dateAdapter={AdapterDateFns}>
                <Formik
                    initialValues={users}
                    enableReinitialize={true}
                    validationSchema={openingHoursSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveOpeningHours(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                        <Form>
                            <DialogContent dividers onScroll={handleScroll}>
                                <div style={{ height: "53vh" }}>
                                    {selectedUser &&
                                        <Typography>
                                            {i18n.t("openingHoursModal.notices.onCopy", { userName: selectedUser.name })}
                                        </Typography>
                                    }
                                    <List disablePadding>
                                        {users.length > 0 ? users.map((user, uindex) => (currentUser.email === user?.email || user?.email !== "sites@soluque.com.br") &&
                                            <ListItem style={{ padding: 2 }}>
                                                <Accordion
                                                    disableGutters
                                                    //elevation={0} 
                                                    className={classes.accordion}
                                                //variant="outlined"
                                                >
                                                    <AccordionSummary
                                                        className={classes.accordionSummary}
                                                        expandIcon={<ExpandMore />}

                                                    >
                                                        {selectedUser && <ListItemIcon>
                                                            <FormControlLabel
                                                                value={user.id}
                                                                control={<Checkbox
                                                                    disabled={selectedUser.id === user.id}
                                                                    size='small'
                                                                    onClick={handleSelectUserToCopy}
                                                                />}
                                                            />
                                                        </ListItemIcon>}
                                                        <ListItemText
                                                            style={{ flex: 1 }}
                                                            primary={<Typography variant="body2">{user.name}</Typography>}
                                                            secondary={user.company}
                                                        />
                                                        <ListItemText
                                                            style={{ flex: 1 }}
                                                            primary={<Typography variant="body2">{
                                                                values[uindex]?.openingHours?.map((info) => {
                                                                    info.days = typeof info.days === "string" ? JSON.parse(info?.days) : info.days;
                                                                    return (<div className={classes.inlineWrapper}>
                                                                        <Typography variant="subtitle2">{info.start}{"->"}{info.end}</Typography>
                                                                        <Typography>{info.days.map(day => i18n.t(`openingHoursModal.days.${day}`).substring(0, 3)).join(', ')}</Typography>
                                                                    </div>)
                                                                }
                                                                )
                                                            }</Typography>}
                                                            secondary={user.company}
                                                        />
                                                        <div
                                                            style={{ flex: 1 }}
                                                        >
                                                            {values[uindex]?.openingHours?.length > 0 && !selectedUser &&
                                                                <Button
                                                                    onClick={(e) => onCopyHour(e, values[uindex])}
                                                                >
                                                                    {i18n.t("openingHoursModal.buttons.copy")}
                                                                </Button>}
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails className={classes.accordionDetails}>
                                                        <FormControl fullWidth className={classes.inlineWrapper}>
                                                            <InputLabel id="option-selection-input-label"
                                                                shrink={true}
                                                                style={{marginTop: -5}}
                                                            >
                                                                {"Selecione o modo de acesso e atividade"}
                                                            </InputLabel>
                                                            <Field
                                                                as={ToggleButtonGroup}
                                                                name={`[${uindex}].bypassOpeningHours`}
                                                                label={"Salve"}
                                                                labelId="option-selection-input-label"
                                                                exclusive={true}
                                                                margin="dense"
                                                                disabled={selectedUser}
                                                                size="small"
                                                                value={values[uindex]?.bypassOpeningHours}
                                                                onChange={(e, val) => val!== null && setFieldValue(`[${uindex}].bypassOpeningHours`, val)}
                                                                aria-label="bypassOpeningHours"
                                                            >
                                                                <ToggleButton value={0} aria-label="default">
                                                                    {i18n.t(`Horário fixo`)}
                                                                </ToggleButton>
                                                                <ToggleButton value={1} aria-label="ignore">
                                                                    {i18n.t(`Acesso livre`)}
                                                                </ToggleButton>
                                                                <ToggleButton value={2} aria-label="block">
                                                                    {i18n.t(`Sem acesso`)}
                                                                </ToggleButton>
                                                            </Field>
                                                        </FormControl>
                                                        <Divider textAlign="left">{"Horários do usuário"}</Divider>
                                                        <FieldArray name={`[${uindex}].openingHours`}>
                                                            {({ push, remove }) => (
                                                                <>
                                                                    {values[uindex]?.openingHours &&
                                                                        values[uindex]?.openingHours.length > 0 &&
                                                                        values[uindex]?.openingHours.map((info, index) => {
                                                                            info = {
                                                                                start: parse(info.start, 'HH:mm', new Date()),
                                                                                end: parse(info.end, 'HH:mm', new Date()),
                                                                                days: typeof info.days === "string" ? JSON.parse(info?.days) : info.days
                                                                            }
                                                                            return (
                                                                                <div className={classes.inlineWrapper}>
                                                                                    <Field
                                                                                        as={TimePicker}
                                                                                        ampm={false}
                                                                                        label={i18n.t("openingHoursModal.form.start")}
                                                                                        value={info.start}
                                                                                        onChange={(time) => setFieldValue(`[${uindex}].openingHours[${index}].start`, format(new Date(time), "HH:mm"))}
                                                                                        maxTime={parse(info.end, 'HH:mm', new Date())}
                                                                                        name={`[${uindex}].openingHours[${index}].start`}
                                                                                        className={classes.multiLine}
                                                                                        color="primary"
                                                                                        disabled={selectedUser}
                                                                                        views={['hours', 'minutes']}
                                                                                        slotProps={{
                                                                                            textField: {
                                                                                                style: { minWidth: 50 },
                                                                                            }
                                                                                        }}
                                                                                        InputLabelProps={{
                                                                                            shrink: true,
                                                                                        }}
                                                                                    />
                                                                                    <Field
                                                                                        as={TimePicker}
                                                                                        ampm={false}
                                                                                        label={i18n.t("openingHoursModal.form.end")}
                                                                                        value={info.end}
                                                                                        onChange={(time) => setFieldValue(`[${uindex}].openingHours[${index}].end`, format(new Date(time), "HH:mm"))}
                                                                                        minTime={parse(info.start, 'HH:mm', new Date())}
                                                                                        name={`[${uindex}].openingHours[${index}].end`}
                                                                                        className={classes.multiLine}
                                                                                        color="primary"
                                                                                        disabled={selectedUser}
                                                                                        views={['hours', 'minutes']}
                                                                                        slotProps={{
                                                                                            textField: {
                                                                                                style: { minWidth: 50 },
                                                                                                helperText: touched[uindex]?.openingHours[index]?.end || errors[uindex]?.openingHours[index]?.end ? errors[uindex]?.openingHours[index]?.end : ""
                                                                                            }
                                                                                        }}
                                                                                        error={touched[uindex]?.openingHours[index]?.end || Boolean(errors[uindex]?.openingHours[index]?.end)}
                                                                                        InputLabelProps={{
                                                                                            shrink: true,
                                                                                        }}
                                                                                    />
                                                                                    <Field
                                                                                        as={ToggleButtonGroup}
                                                                                        size="small"
                                                                                        disabled={selectedUser}
                                                                                        value={info.days}
                                                                                        name={`[${uindex}].openingHours[${index}].days`}
                                                                                        className={classes.multiLine}
                                                                                        onChange={(e, days) => setFieldValue(`[${uindex}].openingHours[${index}].days`, days)}
                                                                                        aria-label="weekdays"
                                                                                    >
                                                                                        <ToggleButton value="monday" aria-label="monday">
                                                                                            {i18n.t(`openingHoursModal.days.monday`)}
                                                                                        </ToggleButton>
                                                                                        <ToggleButton value="tuesday" aria-label="tuesday">
                                                                                            {i18n.t(`openingHoursModal.days.tuesday`)}
                                                                                        </ToggleButton>
                                                                                        <ToggleButton value="wednesday" aria-label="wednesday">
                                                                                            {i18n.t(`openingHoursModal.days.wednesday`)}
                                                                                        </ToggleButton>
                                                                                        <ToggleButton value="thursday" aria-label="thursday">
                                                                                            {i18n.t(`openingHoursModal.days.thursday`)}
                                                                                        </ToggleButton>
                                                                                        <ToggleButton value="friday" aria-label="friday">
                                                                                            {i18n.t(`openingHoursModal.days.friday`)}
                                                                                        </ToggleButton>
                                                                                        <ToggleButton value="saturday" aria-label="saturday">
                                                                                            {i18n.t(`openingHoursModal.days.saturday`)}
                                                                                        </ToggleButton>
                                                                                        <ToggleButton value="sunday" aria-label="sunday">
                                                                                            {i18n.t(`openingHoursModal.days.sunday`)}
                                                                                        </ToggleButton>
                                                                                    </Field>
                                                                                    <IconButton
                                                                                        disabled={selectedUser}
                                                                                        onClick={() => remove(index)}
                                                                                    >
                                                                                        <DeleteOutline color="secondary" />
                                                                                    </IconButton>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    {!selectedUser &&
                                                                        <Button
                                                                            variant="outlined"
                                                                            startIcon={<Add />}
                                                                            fullWidth

                                                                            onClick={() => push({ start: "08:00", end: "18:00", days: [] })}
                                                                        >
                                                                            {i18n.t("openingHoursModal.buttons.add")}
                                                                        </Button>
                                                                    }
                                                                </>
                                                            )}
                                                        </FieldArray>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </ListItem>
                                        ) : !loading &&
                                        <div className={classes.noContactDiv}>
                                            <span className={classes.noContactTitle}>
                                                {i18n.t("openingHoursModal.notices.nothingHere")}
                                            </span>
                                            <p className={classes.noContactText}>
                                                {i18n.t("openingHoursModal.notices.nothingHereDescription")}
                                            </p>
                                        </div>
                                        }
                                        {loading &&
                                            <div style={{ textAlign: "center", padding: 10 }}>
                                                <CircularProgress />
                                            </div>
                                        }

                                    </List>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                {selectedUser ?
                                    <>
                                        <Button
                                            color="secondary"
                                            variant="outlined"
                                            onClick={() => setSelectedUser(null)}
                                        >
                                            {i18n.t("openingHoursModal.buttons.cancel")}
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            disabled={!selectedUser}
                                            onClick={() => {
                                                selectedUsersId.forEach((userId) => {
                                                    const index = users.findIndex((user) => +user.id === +userId);
                                                    if (index !== -1) {
                                                        const openingHoursWithoutId = selectedUser.openingHours.map(({ id, ...rest }) => rest);
                                                        setFieldValue(`[${index}].openingHours`, openingHoursWithoutId);
                                                    }
                                                });
                                                setSelectedUsersId([])
                                                setTimeout(() => setSelectedUser(null), 500);
                                            }}
                                        >
                                            {i18n.t("openingHoursModal.buttons.paste")}
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Button
                                            color="secondary"
                                            variant="outlined"
                                            disabled={isSubmitting || selectedUser}
                                            onClick={handleClose}
                                        >
                                            {i18n.t("openingHoursModal.buttons.cancel")}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting || selectedUser}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {i18n.t("openingHoursModal.buttons.edit")}
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </>
                                }
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </LocalizationProvider>
        </Dialog>
    )
}
export default OpeningHoursModal;