import whatsBackground from "../../src/assets/wa-background-hires.png";
//import whatsBackgroundDark from "../../src/assets/wa-background-hires-dark.png";
import { alpha, createTheme } from "@mui/material";
import "../assets/fonts/roboto/styles.css";

const MetroTheme = createTheme(
    {
        typography: {

            fontFamily: 'Segoe UI, Arial',
            fontSize: 14,

            h1: {
                fontSize: '1.5rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h2: {
                fontSize: '1.4rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h3: {
                fontSize: '1.25rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h4: {
                fontSize: '1.1rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h5: {
                fontSize: '1rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h6: {
                fontSize: '.875rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            body1: {
                fontSize: '.875rem',
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {

                    "::-webkit-scrollbar": {
                        width: "10px",
                        height: "10px"
                    },

                    "::-webkit-scrollbar-track": {
                        background: "#f0f0f0",
                    },

                    "::-webkit-scrollbar-thumb": {
                        background: "#cdcdcd",
                    },

                    "::-webkit-scrollbar-thumb:hover": {
                        background: "#5d6469",
                    },
                },
            },
            MuiButtonBase:{
                defaultProps:{
                    disableRipple: true,
                }
            },
            MuiDrawer:{
                styleOverrides:{
                    paper:{
                        "&.MuiDrawer-paperAnchorLeft":{
                            backgroundColor: "#101010",
                            "& .MuiListSubheader-root": {
                                backgroundColor: "#00000000",
                            },
                            "& .MuiListItemText-root": {
                                color: "#f7f7f7"
                            },
                            "& .MuiSvgIcon-root": {
                                color: "#cdcdcd"
                            },
                        }
                        
                    }
                }
            },
            /* MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: 'inherit',
                        background: '#FFFFFF',
                        '&:hover': {
                            background: '#FFFFFF',
                        }
                    }
                },
            }, */
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        '&.mainListItems': {
                            borderLeft: "5px solid transparent",
                            "&:hover": {
                                borderLeft: "5px solid #5b83a3b4"
                            },
                            '&.Mui-selected': {
                                borderLeft: "5px solid #5b83a3",
                                backgroundColor: "#353535"
                            }
                        }
                    }
                }
            },
            MuiTextField: {
                defaultProps: {
                    size: 'small', // Define o tamanho small como padrão
                },
            },
            MuiOutlinedInput:{
                styleOverrides: {
                    root: {
                        borderRadius: 0
                    }
                }
            },
            MuiFormControl: {
                defaultProps: {
                    size: 'small', // Define o tamanho small como padrão
                },
            },
            MuiPaper: {
                defaultProps: {
                    elevation: 0
                },
                styleOverrides: {
                    rounded: {
                        borderRadius: 0,
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderColor: alpha('#000', 0.1),
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        minHeight: 'auto',
                        margin: "0px 4px",
                        //marginRight: "5px",
                        border: "1px solid transparent",
                        "&:hover": {
                            border: "1px solid #0077d7",
                            backgroundColor: "#0077d70f"
                        }
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true
                },
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                    },
                    text:{
                        "&:hover": {
                            outline: "1px solid #0077d7"
                        }
                    }
                }
            },
        },
        palette: {
            primary: {
                main: "#459add",
                light: "#dde3eb",
                dark: '#0077d7',
                contrastText: '#FFF'
            },
            secondary: {
                main: "#f44336",
                light: '#ff867e',
                dark: '#c50b28',
                contrastText: '#FFF'
            },
            error: {
                main: '#E73145',
                light: '#FF6A70',
                dark: '#AD001E',
                contrastText: '#FFF'
            },
            warning: {
                main: '#F39711',
                light: '#FFC84C',
                dark: '#BB6900',
                contrastText: '#FFF'
            },
            info: {
                main: '#2EB5C9',
                light: '#9bdee8',
                dark: '#008598',
                contrastText: '#FFF'
            },
            success: {
                main: '#3BD2A2',
                light: '#78FFD3',
                dark: '#00A073',
                contrastText: '#FFF'
            },
            text: {
                primary: '#475259',
                secondary: '#6a6a6a',
                disabled: '#A2B2C3',
            },
            shadow: "0 1px .5px #0b141231",
            //divider: "#9494941f",
            background: {
                default: "#f0f2f5",
                paper: "#ffffff",
                header: "#f5f6f8",
                list: "#ffffff",
                messagesList: {
                    image: `url(${whatsBackground})`,
                    light: "#dcf8c6",
                    dark: "#efeae2",
                }
            },
            action: {
                active: '#475259',
                hover: '#3535353f',
            },
        },
    }
);
export default MetroTheme;