import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";

import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";

import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { getSettings } from "../../hooks/useSettings";
import { Can } from "../Can";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { TransitionGroup } from 'react-transition-group';
import { Button, Collapse, IconButton, Tooltip } from "@mui/material";
import { Check, Checklist, Clear, DoneAll, LowPriority, MarkChatReadOutlined, MarkChatUnread, MarkChatUnreadOutlined, MarkUnreadChatAltOutlined, PlaylistAddCheck, PlaylistAddCheckOutlined, PlusOne, Replay } from "@mui/icons-material";
import api from "../../services/api";
import TicketListContextMenu from "../TicketListContextMenu";


const useStyles = makeStyles(theme => ({
	ticketsListWrapper: {
		position: "relative",
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflow: "hidden",
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,

	},

	ticketsList: {
		flex: 1,
		overflowX: "hidden",
		overflowY: "scroll",
		...theme.scrollbarStyles,
		borderTop: "2px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: theme.palette.background.list,

	},

	ticketsListHeader: {
		color: "rgb(67, 83, 105)",
		zIndex: 2,
		backgroundColor: "white",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},

	ticketsCount: {
		fontWeight: "normal",
		color: "rgb(104, 121, 146)",
		marginLeft: "8px",
		fontSize: "14px",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	iconButton: {
		color: theme.palette.text.secondary
	},
}));

const reducer = (state, action) => {
	if (action.type === "LOAD_TICKETS") {
		const newTickets = action.payload;

		newTickets.forEach((ticket) => {
			const ticketIndex = state.findIndex(t => t.id === ticket.id);
			if (ticketIndex !== -1) {
				state[ticketIndex] = ticket;
				if (ticket.unreadMessages > 0 && action.order === 'lastmsg') {
					state.unshift(state.splice(ticketIndex, 1)[0]);
				}
			} else {
				state.push(ticket);
			}
		});

		return [...state];
	}

	if (action.type === "RESET_UNREAD") {
		const ticketId = action.payload;

		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1 && action.order === 'lastmsg') {
			state[ticketIndex].unreadMessages = 0;
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKETS") {
		const updatedTickets = action.payload;
		const order = action.order; // Supondo que a ordem seja passada através da ação
		let updatedState;
		if (order === "lastmsg") {
			const filteredState = state.filter((stateTicket) => !updatedTickets.some((ticket) => ticket.id === stateTicket.id));
			updatedState = [...updatedTickets, ...filteredState];
		} else {
			const filteredState = state.filter((stateTicket) => !updatedTickets.some((ticket) => ticket.id === stateTicket.id));
			updatedState = [...filteredState, ...updatedTickets].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
		}

		return updatedState;
	}

	if (action.type === "UPDATE_TICKET") {
		const ticket = action.payload;
		let ticketIndex = state.findIndex(t => t.id === ticket.id);
		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
		} else {
			if (action.order === 'lastmsg') {
				state.unshift(ticket);
			} else {
				ticketIndex = state.findIndex(t => new Date(t.createdAt) > new Date(ticket.createdAt));
				if (ticketIndex === -1) {
					state.push(ticket);
				} else {
					state.splice(ticketIndex, 0, ticket);
				}
			}
		}
		return [...state];
	}

	if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
		const ticket = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticket.id);

		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
			if (action.order === 'lastmsg') {
				state.unshift(state.splice(ticketIndex, 1)[0])
			}
		} else {
			if (action.order === 'lastmsg') {
				state.unshift(ticket);
			} else {
				state.push(ticket);
			}
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKET_CONTACT") {
		const contact = action.payload;
		const ticketIndex = state.findIndex(t => t.contactId === contact.id);
		if (ticketIndex !== -1) {
			state[ticketIndex].contact = contact;
		}
		return [...state];
	}

	if (action.type === "DELETE_TICKET") {
		const ticketId = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) {
			state.splice(ticketIndex, 1);
		}

		return [...state];
	}

	if (action.type === "DELETE_TICKETS") {
		const removedIds = action.payload.map((removedTicket) => removedTicket.id);

		// Remover os tickets no estado com base no ID
		const updatedState = state.filter((ticket) => !removedIds.includes(ticket.id));

		return updatedState;
	}


	if (action.type === "RESET") {
		return [];
	}
};

const TicketsList = (props) => {
	const { status, searchParam, showAll, selectedQueueIds, selectedTagIds, selectedConnectionIds, updateCount, style } = props;
	const classes = useStyles();
	const [pageNumber, setPageNumber] = useState(1);
	const [ticketsList, dispatch] = useReducer(reducer, []);
	const { user } = useContext(AuthContext);
	const { settings, loading: loadingSettings } = useContext(SettingsContext);
	const [selectedTickets, setSelectedTickets] = useState([]);
	const [selectedTicket, setSelectedTicket] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const ticketContextMenuOpen = Boolean(anchorEl);

	const getSettingValue = (key) => {

		if (settings && settings.length > 0) {
			const { value } = settings.find(s => s.key === key);
			return value;
		}
	};

	const isGroupAsTicket = getSettingValue('groupsasticket') === 'enabled' ? true : false;

	useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);
		setSelectedTickets([])
	}, [status, searchParam, dispatch, showAll, selectedQueueIds, selectedTagIds, selectedConnectionIds]);

	const { tickets, hasMore, loading } = useTickets({
		pageNumber,
		searchParam,
		isGroup: status === "groups" ? true : false,
		status: status === "groups" && !isGroupAsTicket ? undefined : status,
		showAll,
		queueIds: JSON.stringify(selectedQueueIds),
		tagIds: JSON.stringify(selectedTagIds),
		connectionIds: JSON.stringify(selectedConnectionIds),
	});

	useEffect(async () => {
		if (!status && !searchParam) return;
		dispatch({
			type: "LOAD_TICKETS",
			payload: tickets,
		});
		const ticketsIds = ticketsList.map(ticket => ticket.id);
		const selectedTicketsOnIds = selectedTickets.filter(selectedId =>
			ticketsIds.includes(selectedId)
		);
		setSelectedTickets(selectedTicketsOnIds)


	}, [tickets, status, searchParam]);


	useEffect(() => {
		if (!settings && loadingSettings) return;

		const socket = openSocket();

		const shouldUpdateTicket = (ticket) => !searchParam &&
			(!ticket.userId || ticket.userId === user?.id || showAll) &&
			(!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);
		const shouldUpdateTickets = (tickets) =>
			!searchParam &&
			tickets.filter(ticket =>
				(!ticket.userId || ticket.userId === user?.id || showAll) &&
				(!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1)
			);

		const notBelongsToUserQueues = (ticket) =>
			(ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1);
		socket.on("connect", () => {
			if (status) {
				socket.emit("joinTickets", status);
			} else {
				socket.emit("joinNotification");
			}
		});

		socket.on("ticket", data => {
			if (data.action === "bulkUpdate") {
				const getOrder = () => {
					if (settings && settings.length > 0) {
						if (data.tickets[0].status === 'open') {
							return getSettingValue('openticketorder');
						} else if (data.tickets[0].status === 'pending') {
							return getSettingValue('pendingticketorder');
						} else {
							return 'lastmsg';
						}
					}
				}
				let order = getOrder();
				dispatch({
					type: "UPDATE_TICKETS",
					payload: shouldUpdateTickets(data.tickets),
					order: order
				});
			}

			if (data.action === "bulkDelete") {
				dispatch({
					type: "DELETE_TICKETS",
					payload: data.tickets,
				});
			}
			if (data.action === "updateUnread") {
				let order = data.ticket?.status === 'open' ? getSettingValue('openticketorder') : data.ticket?.status === 'pending' ? getSettingValue('pendingticketorder') : 'lastmsg';
				dispatch({
					type: "RESET_UNREAD",
					payload: data.ticketId,
					order: order,
				});
			}

			if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
				let order = data.ticket.status === 'open' ? getSettingValue('openticketorder') : data.ticket.status === 'pending' ? getSettingValue('pendingticketorder') : 'lastmsg';
				dispatch({
					type: "UPDATE_TICKET",
					payload: data.ticket,
					order: order
				});
			}

			if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
				dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
			}
		});

		socket.on("appMessage", data => {
			if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
				const getOrder = () => {
					if (settings && settings.length > 0) {
						if (data.ticket.status === 'open') {
							return getSettingValue('openticketorder');
						} else if (data.ticket.status === 'pending') {
							return getSettingValue('pendingticketorder');
						} else {
							return 'lastmsg';
						}
					}
				}
				let order = getOrder();
				dispatch({
					type: "UPDATE_TICKET_UNREAD_MESSAGES",
					payload: data.ticket,
					order: order,
				});
			}
		});

		socket.on("contact", data => {
			if (data.action === "update") {
				dispatch({
					type: "UPDATE_TICKET_CONTACT",
					payload: data.contact,
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [status, searchParam, showAll, user, selectedQueueIds, selectedTagIds, selectedConnectionIds, settings]);

	useEffect(() => {
		if (typeof updateCount === "function") {
			if (status === 'groups') {
				updateCount(ticketsList.filter(ticket => ticket.unreadMessages > 0).length);
			} else {
				updateCount(ticketsList.length);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketsList]);



	const loadMore = () => {
		setPageNumber(prevState => prevState + 1);
	};

	const handleScroll = (e) => {
		if (!hasMore || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			e.currentTarget.scrollTop = scrollTop - 100;
			loadMore();
		}
	};
	const handleSelectTicket = (id) => {
		// Verifica se o ID já está presente em selectedTickets
		if (selectedTickets.includes(id)) {
			// Se presente, remove o ID
			setSelectedTickets((prevValues) => prevValues.filter((value) => value !== id));
		} else {
			// Se não presente, adiciona o ID
			setSelectedTickets((prevValues) => [...prevValues, id]);
		}
	};
	const handleSelectAllTickets = () => {
		const allTicketIds = ticketsList.map((ticket) => ticket.id);
		setSelectedTickets(allTicketIds)
	}

	const handleUpdateTickets = async (ticketData) => {
		if (selectedTickets && selectedTickets.length === 0) return;
		await api.put(`/tickets`, {
			ids: selectedTickets,
			ticketData
		});
		if (ticketData.status) {
			setSelectedTickets([])
		}
	}


	const handleOpenTicketContextMenu = (e, ticket) => {
		setSelectedTicket(ticket)
		if (!selectedTickets) return;
		e.preventDefault()
		setAnchorEl({ left: e.clientX, top: e.clientY });
	};
	const handleCloseTicketContextMenu = e => {
		setAnchorEl(null);
	};

	return (

		<Paper square className={classes.ticketsListWrapper} style={style}>
			<TransitionGroup>

				{selectedTickets.length >= 1 &&
					<Collapse>
						<Paper
							square
							style={{ padding: 5, display: "flex", alignItems: "center", justifyContent: "space-between" }}
						>
							Tickets selecionados {selectedTickets.length}
							<div>
								{status !== "closed" ?
									<>
										{status !== "groups" && ((status === "pending" && user.profile === "admin") || status !== "pending") &&
											<Tooltip title={status === "open" ? "Resolver" : "Aceitar"}>

												<IconButton
													className={classes.iconButton}
													onClick={() => handleUpdateTickets({
														status: status === "open" ? "closed" : "open",
														userId: status === "pending" ? user.id : undefined
													})}
												>
													{status === "pending" ? <DoneAll /> : <PlaylistAddCheckOutlined />}
												</IconButton>
											</Tooltip>

										}
										<Tooltip title="Marcar como não lido">

											<IconButton
												className={classes.iconButton}
												onClick={() => handleUpdateTickets({
													unreadMessages: 1,
												})}
											>
												<MarkChatUnreadOutlined />
											</IconButton>
										</Tooltip>

										<Tooltip title="Marcar como lido">

											<IconButton
												className={classes.iconButton}
												onClick={() => handleUpdateTickets({
													unreadMessages: 0,
												})}
											>
												<MarkChatReadOutlined />
											</IconButton>
										</Tooltip>


									</>
									:
									<Can
										role={user.profile}
										perform="ticket-options:deleteTicket"
										yes={() => (
											<Tooltip title="Reabrir">
												<IconButton
													className={classes.iconButton}
													onClick={() => handleUpdateTickets({
														status: "open",
														userId: user.id
													})}
												>
													<Replay />
												</IconButton>
											</Tooltip>
										)}
									/>
								}
							</div>
							<div>
								<Tooltip title="Selecionar toda lista">
									<IconButton
										className={classes.iconButton}
										disabled={selectedTickets.length === ticketsList.length}
										onClick={handleSelectAllTickets}
									>
										<Checklist />
									</IconButton>
								</Tooltip>

								<Tooltip title="Fechar">
									<IconButton
										className={classes.iconButton}
										onClick={() => setSelectedTickets([])}
									>
										<Clear />
									</IconButton>
								</Tooltip>

							</div>
						</Paper>
					</Collapse>
				}

			</TransitionGroup>

			<Paper
				square
				name="closed"
				elevation={0}
				className={classes.ticketsList}
				onScroll={handleScroll}
			>
				<List style={{ paddingTop: 0 }}>
					{ticketsList.length === 0 && !loading ? (
						<div className={classes.noTicketsDiv}>
							<span className={classes.noTicketsTitle}>
								{i18n.t("ticketsList.noTicketsTitle")}
							</span>
							<p className={classes.noTicketsText}>
								{i18n.t("ticketsList.noTicketsMessage")}
							</p>
						</div>
					) :
						ticketsList.map((ticket, index) => (
							<div key={ticket.id} onContextMenu={(e) => handleOpenTicketContextMenu(e, ticket)}>

								<TicketListItem settings={settings} index={index} ticket={ticket} onSelectTicket={handleSelectTicket} selectedTickets={selectedTickets} />
							</div>
						))}



					{(loading) && <TicketsListSkeleton />}
				</List>
				{selectedTickets &&
					<TicketListContextMenu
						ticket={selectedTicket}
						anchorEl={anchorEl}
						menuOpen={ticketContextMenuOpen}
						handleClose={handleCloseTicketContextMenu}
						onSelectTicket={handleSelectTicket}
						selectedTickets={selectedTickets}
					/>
				}
			</Paper>
		</Paper>
	);
};

export default TicketsList;