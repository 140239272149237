import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";
import clsx from "clsx";

import { Paper } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import MessageInput from "../MessageInput/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import SearchMessagesDrawer from "../SearchMessageDrawer";
import TicketDrawer from "../TicketDrawer";
import { SearchContext } from "../../context/Search/SearchContext";
import TicketInfoDrawer from "../TicketInfoDrawer";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },

  ticketInfo: {
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down('md')]: {
      maxWidth: "80%",
      flexBasis: "80%",
    },
  },
  ticketActionButtons: {
    maxWidth: "50%",
    flexBasis: "50%",
    display: "flex",
    [theme.breakpoints.down('md')]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginBottom: "5px",
    },
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Ticket = () => {
  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [showHistory, setShowHistory] = useState(true);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const { user } = useContext(AuthContext);
  const { isSearching, setIsSearching } = useContext(SearchContext);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/" + ticketId);

          setContact(data.contact);
          setShowHistory(true);
          setTicket(data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchTicket();
      
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId,history]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("ticket", (data) => {
      if (data.action === "update") {
        setTicket(data.ticket);
      }

      if (data.action === "delete") {
        toast.success("Ticket deletado com sucesso!");
        history.push("/tickets");
      }
    });

    socket.on("contact", (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, history]);

  useEffect(() => {
    if (isSearching === true) {
      setDrawerOpen(false);
    }
  }, [isSearching]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
    setIsSearching(false);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setIsSearching(false);
  };



  const handleShowAll = () => {
    setShowHistory(showHistory ? false : true);
  };

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        square
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: (drawerOpen || isSearching),
        })}
      >
        <TicketHeader loading={loading}>
          <div className={classes.ticketInfo}>
            <TicketInfo
              contact={contact}
              ticket={ticket}
              onClick={handleDrawerOpen}
            />
          </div>
          <div className={classes.ticketActionButtons}>
            <TicketActionButtons ticket={ticket} showHistory={showHistory} setShowHistory={handleShowAll} />
          </div>
        </TicketHeader>
        <ReplyMessageProvider>
          <MessagesList
            showHistory={showHistory}
            ticketId={ticketId}
            isGroup={ticket.isGroup}
            connType={ticket?.whatsapp?.connectionType}
            ticketQueue={ticket?.queue}
          />
          <MessageInput ticketStatus={ticket.status} connType={ticket?.whatsapp?.connectionType} />
        </ReplyMessageProvider>
      </Paper>
      <TicketDrawer
        open={drawerOpen || isSearching}
      >
        {isSearching &&
          < SearchMessagesDrawer
            handleDrawerClose={handleDrawerClose}
            ticketId={ticketId}
            ticket={ticket}
          />
        }
        {drawerOpen &&
          <TicketInfoDrawer
            handleDrawerClose={handleDrawerClose}
            contact={contact}
            loading={loading}
            ticket={ticket}
          />
        }

      </TicketDrawer>

    </div>
  );
};

export default Ticket;
