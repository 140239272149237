import {
    Button,
    CircularProgress,
    colors,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Field, Form, Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { i18n } from "../../translate/i18n";
import MainHeader from "../MainHeader";
import ColorPicker from "../ColorPicker";
import { Colorize } from "@mui/icons-material";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: theme.palette.primary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
    iconButton: {
        color: theme.palette.text.secondary
    }
}));

const CategoriesSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Muito curto!")
        .max(100, "Muito longo")
        .required("Obrigatório!"),
    color: Yup.string().required("Obrigatório"),

});

const CategoriesModal = ({ open, onClose, categoryId }) => {
    const classes = useStyles();
    const initialState = {
        name: '',
        color: '',
        tickets: []
    }
    const colorRef = useRef();
    const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
    const [category, setCategory] = useState(initialState);

    useEffect(() => {
        (async () => {
            if (!categoryId) return;
            try {
                const { data } = await api.get(`/categories/${categoryId}`);
                setCategory(prevState => {
                    return { ...prevState, ...data };
                });
            } catch (err) {
                toastError(err);
            }
        })();

        return () => {
            setCategory({
                name: "",
                color: "",
                tickets: []
            });
        };
    }, [categoryId, open]);

    const handleClose = () => {
        setCategory(initialState);
        onClose();
    }
    const handleSaveCategory = async values => {
        let categoryData = values;
        try {
            if (categoryId) {
                await api.put(`/categories/${categoryId}`, categoryData);
            } else {
                await api.post("/categories/", categoryData);
            }
            toast.success(i18n.t("categories.categoryModal.notices.addSuccess"));
            handleClose();
        } catch (err) {
            toastError(err);
        }
    }

    return (
        <div className={classes.root}>
            <Dialog maxWidth={'xs'} open={open} onClose={handleClose} scroll="paper">
                <MainHeader>
                    <DialogTitle
                        color="textPrimary"
                    >{categoryId
                        ? i18n.t('categories.categoryModal.title.edit')
                        : i18n.t('categories.categoryModal.title.add')}
                    </DialogTitle>
                </MainHeader>
                <Formik
                    initialValues={category}
                    enableReinitialize={true}
                    validationSchema={CategoriesSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveCategory(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ touched, errors, isSubmitting, values }) => (
                        <Form>
                            <DialogContent dividers>
                                <Field
                                    as={TextField}
                                    label={i18n.t("categories.form.name")}
                                    fullWidth
                                    name="name"
                                    inputRef={colorRef}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                    variant="outlined"
                                    margin="dense"

                                />
                                <Field
                                    as={TextField}
                                    label={i18n.t("categories.form.color")}
                                    name="color"
                                    id="color"
                                    fullWidth
                                    onFocus={() => {
                                        setColorPickerModalOpen(true);
                                        colorRef.current.focus();
                                    }}
                                    error={touched.color && Boolean(errors.color)}
                                    helperText={touched.color && errors.color}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <div
                                                    style={{ backgroundColor: values.color }}
                                                    className={classes.colorAdorment}
                                                ></div>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <IconButton className={classes.iconButton}
                                                size="small"
                                                color="default"
                                                onClick={() => setColorPickerModalOpen(true)}
                                            >
                                                <Colorize />
                                            </IconButton>
                                        ),
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                />
                                <ColorPicker
                                    open={colorPickerModalOpen}
                                    handleClose={() => setColorPickerModalOpen(false)}
                                    onChange={color => {
                                        values.color = color;
                                        setCategory(() => {
                                            return { ...values, color };
                                        });
                                    }}
                                />



                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("categories.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {categoryId
                                        ? `${i18n.t("categories.buttons.edit")}`
                                        : `${i18n.t("categories.buttons.add")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
}
export default CategoriesModal;