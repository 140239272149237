import { Button, Dialog } from "@mui/material";
import React, { useRef, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';

import { SketchPicker } from "react-color";

const useStyles = makeStyles(theme => ({
  colorPicker: {
    width: 400,
    backgroundColor: `${theme.palette.background.paper} !important`,
    "& input": {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 2,
      padding: "4px !important",
      fontSize: "14px !important",
      boxShadow: `#000000 0px 0px 0px, ${theme.palette.divider} 0px 0px 0px 1px !important`,
      color: `${theme.palette.text.primary} !important`,
      outline: "none",
      "&:hover": {
        boxShadow: `#000000 0px 0px 0px, #ffffff 0px 0px 0px 1px !important`
      },
      "&:focus": {
        boxShadow: `#000000 0px 0px 0px, ${theme.palette.primary.main} 0px 0px 0px 2px !important`
      }
    },
    "& label": {
      color: `${theme.palette.text.primary} !important`,
    },
    "& .flexbox-fix": {
      borderColor: `${theme.palette.divider} !important`
    }
  }
}))

const ColorPicker = ({ onChange, currentColor, handleClose, open }) => {
  const classes = useStyles();
  const [selectedColor, setSelectedColor] = useState(currentColor);

  const colors = [
    "#B80000",
    "#DB3E00",
    "#FCCB00",
    "#008B02",
    "#006B76",
    "#1273DE",
    "#004DCF",
    "#5300EB",
    "#EB9694",
    "#FAD0C3",
    "#FEF3BD",
    "#C1E1C5",
    "#BEDADC",
    "#C4DEF6",
    "#BED3F3",
    "#D4C4FB",
    "#4D4D4D",
    "#999999",
    "#FFFFFF",
    "#F44E3B",
    "#FE9200",
    "#FCDC00",
    "#DBDF00",
    "#A4DD00",
    "#68CCCA",
    "#73D8FF",
    "#AEA1FF",
    "#FDA1FF",
    "#333333",
    "#808080",
    "#cccccc",
    "#D33115",
    "#E27300",
    "#FCC400",
    "#B0BC00",
    "#68BC00",
    "#16A5A5",
    "#009CE0",
    "#7B64FF",
    "#FA28FF",
    "#666666",
    "#B3B3B3",
    "#9F0500",
    "#C45100",
    "#FB9E00",
    "#808900",
    "#194D33",
    "#0C797D",
    "#0062B1",
    "#653294",
    "#AB149E",
  ];

  const handleChange = (color) => {
    setSelectedColor(color.hex);
    //handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="xs"
      paperFullWidth
    >


      <SketchPicker
        //width={"100%"}
        id="color-picker"
        triangle="hide"
        className={classes.colorPicker}
        color={selectedColor}
        colors={colors}
        onChange={handleChange}
        onChangeComplete={(color) => onChange(color.hex)}
      />
      <Button
        onClick={() => {
          if(!selectedColor){
            handleChange({ hex:"#22194D"})
            onChange("#22194D");
          }
          handleClose();
        }}
      >
        Ok
      </Button>

    </Dialog>
  );
};

export default ColorPicker;
