import React from 'react';
import { Chip, Tooltip } from '@mui/material';

const TagsWrapper = ({ tags, maxWidth, height = 15 }) => {
    if (!tags || tags.length <= 0) return null;
    const visibleTags = [];
    const hiddenTags = [];

    let totalWidth = 0;

    const measureTagWidth = (tag) => {
        const tempChip = document.createElement('div');
        tempChip.style.display = 'inline-block';
        tempChip.style.visibility = 'hidden';
        tempChip.style.fontSize = height; // Ajuste o tamanho da fonte conforme necessário
        tempChip.style.margin = '0px 2px';
        tempChip.innerText = tag.name;
        document.body.appendChild(tempChip);
        const tagWidth = tempChip.offsetWidth;
        document.body.removeChild(tempChip);
        return tagWidth + 20;
    };

    for (const tag of tags) {
        const tagWidth = measureTagWidth(tag);

        if (totalWidth + tagWidth <= maxWidth) {
            visibleTags.push(tag);
            totalWidth += tagWidth;
        } else {
            hiddenTags.push(tag);
        }
    }

    const remainingTagsCount = hiddenTags.length;



    return (
        <div style={{ display: "flex", alignItems: "center", }}>
            <div style={{ maxWidth, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {visibleTags.map((tag, index) => (
                    <Chip
                        key={index}
                        style={{ backgroundColor: tag.color, height, margin: '0px 2px', fontSize: '11px', maxWidth }}
                        size='small'
                        label={tag.name}
                    />
                ))}
            </div>
            {remainingTagsCount > 0 &&
                <Tooltip title={
                    tags.map((tag, index) => (
                        <Chip
                            key={index}
                            style={{ backgroundColor: tag.color, height, margin: '0px 2px', fontSize: '11px' }}
                            size='small'
                            label={tag.name}
                        />
                    ))
                }>
                    <Chip
                        style={{ height, margin: '0px 2px', fontSize: '11px', maxWidth }}
                        size='small'
                        label={`+${remainingTagsCount}`}
                    />
                </Tooltip>
            }
        </div>
    );
};

export default TagsWrapper;
