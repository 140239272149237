import { Table, TableBody, TableRow, TableCell, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Link } from "@mui/material"
import MainHeader from "../MainHeader";
import { i18n } from "../../translate/i18n";
import makeStyles from "@mui/styles/makeStyles";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import api from "../../services/api";


const useStyles = makeStyles((theme) => ({
  listItemButton: {
    borderRadius: 4,
  },
  noFileListText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noFileListTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  noFileListDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCustomRow: {
    '& td, & th': {
      borderColor: theme.palette.divider,
    },
  },
}))


const CommandListModal = ({ open, onClose, ticketId}) => {
  const classes = useStyles();

  const handleSendMessage = async (command) => {
    if(command === "") return;
    const message = {
      read: 0,
      fromMe: true,
      mediaUrl: "",
      body: command,
      quotedMsg: ''
    };
    try {
      await api.post(`/messages/${ticketId}`, message);
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
  }


  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <MainHeader fullWidth>
        <DialogTitle
          color="textPrimary"
          id="filelist-dialog-title"
        >
          {i18n.t("commandListModal.title")}
        </DialogTitle>
      </MainHeader>
      <DialogContent dividers padding={0} style={{ minHeight: 230 }}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow className={classes.tableCustomRow}>
                <TableCell style={{ width: '160px' }}>
                  {i18n.t("commandListModal.commands.comments")}
                </TableCell>
                <TableCell>{i18n.t("commandListModal.commands.descriptions.comments")}</TableCell>
              </TableRow>
              <TableRow className={classes.tableCustomRow}>
                <TableCell style={{ width: '160px' }}>
                  <Link component="button"
                    variant="body2" 
                    onClick={()=>{
                      handleSendMessage('!iniciar');
                      handleClose();
                  }}>
                    {i18n.t("commandListModal.commands.startChatbot")}
                  </Link>
                </TableCell>
                <TableCell>{i18n.t("commandListModal.commands.descriptions.startChatbot")}</TableCell>
              </TableRow>
              <TableRow className={classes.tableCustomRow}>
                <TableCell style={{ width: '160px' }}>
                  <Link component="button"
                    variant="body2" 
                    onClick={()=>{
                      handleSendMessage('!parar');
                      handleClose();
                  }}>
                    {i18n.t("commandListModal.commands.stopChatbot")}
                  </Link>
                </TableCell>
                <TableCell>{i18n.t("commandListModal.commands.descriptions.stopChatbot")}</TableCell>
              </TableRow>
              <TableRow className={classes.tableCustomRow}>
                <TableCell style={{ width: '160px' }}>
                  <Link component="button"
                    variant="body2" 
                    onClick={()=>{
                      handleSendMessage('!comandos');
                      handleClose();
                  }}>
                    {i18n.t("commandListModal.commands.commandsChatbot")}
                  </Link>
                </TableCell>
                <TableCell>{i18n.t("commandListModal.commands.descriptions.commandsChatbot")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
      </DialogContent>
      <DialogActions>
        <ButtonWithSpinner
          color="secondary"
          variant="outlined"
          onClick={handleClose}
        >
          {i18n.t("commandListModal.buttons.cancel")}
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  );
}

export default CommandListModal;