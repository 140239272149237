import { Chip, List, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import useTags from "../../hooks/useTags";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

const TagContactManager = ({ contactId, contactData, onChange }) => {
  const classes = useStyles();
  const [contactTags, setContactTags] = useState(contactData?.tags || []);
  const [searchParam, setSearchParam] = useState();
  const { tags, loading, hasMore } = useTags({ searchParam, showAll: true });

  useEffect(() => {
    setContactTags(contactData.tags);
  }, [contactData]);

  const handleChangeTags = (tags) => {
    setContactTags(tags);
    onChange(tags);
  }

  const filterTags = (tags, inputValue) => {
    const filteredOptions = tags.filter(
      (tag) =>
        tag.name.toLowerCase().includes(inputValue.toLowerCase().trim()) ||
        tag.id.toString().includes(inputValue)
    );

    if (filteredOptions.length === 0 && inputValue !== "") {
      const newOption = { id: -1, name: inputValue };
      filteredOptions.push(newOption);
    }

    return filteredOptions;
  };


  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const handleCreateTag = async (name) => {
    const newTag = { name, color: getRandomColor(), contacts: [contactData] };
    try {
      if (newTag) {
        const { data } = await api.post("/tags/", newTag);
        handleChangeTags([...contactTags, data])
      }
      toast.success("Tag salva com sucesso!");
    } catch (err) {
      toastError(err);
    }
  }
  const renderTags = (values, props) => {
    return (
      values.map((option, index) => (
        <Chip
          key={option.id}
          className={classes.chip}
          label={option.name}
          style={{ backgroundColor: option.color }}
          {...props({ index })}
        />
      ))
    )
  }

  const renderOption = (props, option) => {
    if (option.id !== -1) {
      return (
        <li {...props}>
          {option.name}
        </li>
      )
    } else {
      return (<li {...props}>{`${i18n.t("newTicketModal.add")} ${option.name}`}</li>);
    }
  }

  const handleAddTags = async (event, value) => {
    if (!value) return;
    value.map((tag) => {
      if (tag.id === -1) {
        handleCreateTag(tag?.name);
      }
    })
    handleChangeTags(value);
  }

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        loading={loading}
        clearOnBlur
        autoHighlight
        freeSolo
        clearOnEscape
        options={tags}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => renderOption(props, option)}
        filterOptions={(options, params) => filterTags(options, params.inputValue)}
        onChange={(event, value) => handleAddTags(event, value)}
        value={contactTags || []}
        renderTags={(value, getTagProps) =>
          renderTags(value, getTagProps)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            margin="dense"
            label={i18n.t("ticketDrawer.tags", { type: contactData.isGroup ? i18n.t("ticketDrawer.group") : i18n.t("ticketDrawer.contact") })}
            onChange={(e) => setSearchParam(e.target.value)}
          />
        )}
      />
    </div>
  );
};

export default TagContactManager;
