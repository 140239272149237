import React, { useContext, useEffect, useState } from "react";

import makeStyles from '@mui/styles/makeStyles';
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import InputLabel from "@mui/material/InputLabel";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

import ContactModal from "../ContactModal";
import MarkdownWrapper from "../MarkdownWrapper";
import ContactMediaModal from "../ContactMediaModal";
import TagContactManager from "../TagContactManager";
import TicketInfoDrawerSkeleton from "../TicketInfoDrawerSkeleton";
import CategoryTicketManager from "../CategoryTicketManager";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        display: "flex",
        // borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        // borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        // borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        // borderTopRightRadius: 4,
        // borderBottomRightRadius: 4,
    },
    header: {
        display: "flex",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: theme.palette.background.header,
        alignItems: "center",
        padding: theme.spacing(0, 1),
        minHeight: "73px",
        justifyContent: "flex-start",
    },
    content: {
        display: "flex",
        backgroundColor: theme.palette.background.list,
        flexDirection: "column",
        padding: "0px 4px 8px 8px",
        height: "100%",
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },

    contactAvatar: {
        margin: 15,
        width: 100,
        height: 100,
    },

    contactHeader: {
        display: "flex",
        padding: 8,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
            margin: 4,
        },
    },

    contactDetails: {
        marginTop: 8,
        padding: 8,
        display: "flex",
        flexDirection: "column",
    },
    contactExtraInfo: {
        marginTop: 4,
        padding: 6,
    },
    iconButton: {
        color: theme.palette.text.secondary
    }
}));

const TicketInfoDrawer = ({ handleDrawerClose, contact, loading, ticket }) => {
    const classes = useStyles();
    const [modalOpen, setModalOpen] = useState(false);
    const [mediaModalOpen, setMediaModalOpen] = useState(false);


    const handleAddTags = async (tags) => {
        contact.tags = tags;

        try {
            if (contact.id) {
                await api.put(`/contacts/${contact.id}`, contact);

            } else {
                await api.post("/contacts", contact);
            }
        } catch (err) {
            toastError(err);
        }
    }

    const handleAddCategories = async (categories) => {
        ticket.categories = categories;

        try {
            if (ticket.id) {
                await api.put(`/tickets/${ticket.id}`, ticket);

            } else {
                await api.post("/tickets", ticket);
            }
        } catch (err) {
            toastError(err);
        }
    }


    return (
        <>
            <ContactMediaModal
                open={mediaModalOpen}
                onClose={() => setMediaModalOpen(false)}
                ticketId={ticket.id}
            />
            <ContactModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                contactId={contact.id}
            />
            <div className={classes.header}>
                <IconButton className={classes.iconButton} onClick={handleDrawerClose} size="large">
                    <CloseIcon />
                </IconButton>
                <Typography style={{ justifySelf: "center" }}>
                    {i18n.t("ticketDrawer.header")}
                </Typography>
            </div>
            {loading ? (
                <TicketInfoDrawerSkeleton classes={classes} />
            ) : (
                <div className={classes.content}>

                    <Paper variant="outlined" className={classes.contactDetails}>
                        <Typography variant="h5" color="textPrimary">
                            {i18n.t(ticket.isGroup ? "ticketDrawer.group" : "ticketDrawer.contact")}
                        </Typography>
                        <Avatar
                            alt={contact.name}
                            src={contact.profilePicUrl}
                            className={classes.contactAvatar}
                        />

                        <Typography>{i18n.t("ticketDrawer.name")}: {contact.name}</Typography>

                        <Typography style={{ display: "flex", alignItems: "center", gap: 3 }}>
                            {i18n.t("ticketDrawer.number")}: {contact.isGroup ? contact.number : <Link href={`tel:${contact.number}`}>{contact.number}</Link>}
                        </Typography>
                        {contact.email && <Typography>{i18n.t("ticketDrawer.email")}: {contact.email}</Typography>}
                        {contact.company && <Typography>{i18n.t("ticketDrawer.company")}: {contact.company}</Typography>}
                        {contact.movideskPersonId && <Typography color="textSecondary">{`ID Movidesk: ${contact.movideskPersonId}`}</Typography>}
                        <TagContactManager
                            contactId={contact.id}
                            contactData={contact}
                            onChange={(tags) => handleAddTags(tags)}
                        />
                        <div style={{ display: "flex", gap: 4 }}>

                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setModalOpen(true)}
                            >
                                {i18n.t("ticketDrawer.buttons.edit", { type: contact.isGroup ? i18n.t("ticketDrawer.group") : i18n.t("ticketDrawer.contact") })}
                            </Button>

                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setMediaModalOpen(true)}
                            >
                                {i18n.t("ticketDrawer.buttons.medias")}
                            </Button>
                        </div>
                        {contact.extraInfo?.length > 0 &&
                            <Paper variant="outlined" className={classes.contactDetails}>
                                <Typography variant="subtitle1">
                                    {i18n.t("ticketDrawer.extraInfo")}
                                </Typography>
                                {contact?.extraInfo?.map(info => (
                                    <Paper
                                        key={info.id}
                                        variant="outlined"
                                        className={classes.contactExtraInfo}
                                    >
                                        <InputLabel>{info.name}</InputLabel>
                                        <Typography component="div" noWrap style={{ paddingTop: 2 }}>
                                            <MarkdownWrapper>{info.value}</MarkdownWrapper>
                                        </Typography>
                                    </Paper>
                                ))}
                            </Paper>
                        }
                        {contact?.obs &&
                            <Paper variant="outlined" className={classes.contactDetails}>
                                <Typography variant="subtitle1">
                                    {i18n.t("ticketDrawer.obs")}
                                </Typography>
                                <Paper
                                    variant="outlined"
                                    className={classes.contactExtraInfo}
                                >
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {contact.obs}
                                    </Typography>
                                </Paper>

                            </Paper>
                        }
                    </Paper>
                    <Paper variant="outlined" className={classes.contactDetails}>
                        <Typography variant="h5" color="textPrimary">
                            {i18n.t("ticketDrawer.ticket")}
                        </Typography>
                        <Typography style={{ display: "flex", alignItems: "center", gap: 3 }}>{i18n.t("ticketDrawer.number")}:<Link href={`/tickets/${ticket.id}`}>#{ticket.id}</Link></Typography>
                        {ticket.subject &&
                            <Typography>
                                {i18n.t("ticketDrawer.subject")}: {ticket.subject}
                            </Typography>
                        }
                        {ticket.user &&
                            <Typography>{`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`}</Typography>
                        }
                        {ticket.queue &&
                            <Typography>{`${i18n.t("ticketDrawer.queue")}: ${ticket.queue.name}`}</Typography>
                        }
                        {ticket.whatsapp &&
                            <Typography>{`${i18n.t("ticketDrawer.connection")}: ${ticket.whatsapp.name}`}</Typography>
                        }
                        {ticket.movideskTicketId &&
                            <Typography>{`Movidesk ticket: ${ticket.movideskTicketId}`}</Typography>
                        }
                        <CategoryTicketManager
                            ticketId={ticket.id}
                            ticketData={ticket}
                            onChange={(categories) => handleAddCategories(categories)}
                        />
                    </Paper>
                </div>
            )}
        </>
    )
}
export default TicketInfoDrawer;