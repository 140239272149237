import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	tableCustomRow: {
		'& td, & th':{
            borderColor: theme.palette.divider,
		},
	},
}));

const TableRowSkeleton = ({ avatar, columns,checkboxes }) => {
	const classes = useStyles();
	return <>
        <TableRow className={classes.tableCustomRow}>
            {Array.from({ length: checkboxes }, (_, index) => (
                    <TableCell align="center" padding="checkbox" key={index}>
                        <div className={classes.customTableCell}>
                            <Skeleton
                                align="center"
                                animation="wave"
                                width={20}
                                height={30}
                            />
                        </div>
                    </TableCell>
            ))}
            {Array.from({ length: avatar }, (_, index) => (
                <TableCell align="center" key={index}>
                    <div className={classes.customTableCell}>
                        <Skeleton
                            align="center"
                            animation="wave"
                            variant="circular"
                            width={40}
                            height={40}
                        />
                    </div>
                </TableCell>
                    
            ))}
            {Array.from({ length: columns }, (_, index) => (
                <TableCell align="center" key={index}>
                    <div className={classes.customTableCell}>
                        <Skeleton
                            align="center"
                            animation="wave"
                            height={30}
                            width={80}
                        />
                    </div>
                </TableCell>
            ))}
        </TableRow>
    </>;
};

export default TableRowSkeleton;
