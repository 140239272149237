import React, { useState, useReducer, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, colors, FormControlLabel, Avatar, Checkbox, Divider } from "@mui/material";
import { i18n } from "../../translate/i18n";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "@mui/icons-material/Search";
import ButtonWithSpinner from "../ButtonWithSpinner";
import TableRowSkeleton from "../TableRowSkeleton";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import MainHeader from "../MainHeader";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: theme.palette.primary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
    tableCustomRow: {
        '& td, & th': {
            borderColor: theme.palette.divider,
        },
    },
}));
const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach((contact) => {
            const contactIndex = state.findIndex((c) => c.id === contact.id);
            if (contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return [...state, ...newContacts];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const FowardingModal = ({ modalOpen, onClose, message }) => {
    const classes = useStyles();
    const [contacts, dispatch] = useReducer(reducer, []);
    const [searchParam, setSearchParam] = useState("");
    const [loading, setLoading] = useState(false);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const maxSelect = 5;

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam, modalOpen]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("/contacts/", {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, modalOpen]);

    const handleChange = (e) => {
        let value = +e.target.value;
        let ContactsList = contacts.filter((contact) => contact.id === value)
        let checked = e.target.checked;
        if (checked === true) {
            if (selectedContacts.length + 1 <= maxSelect) {
                setSelectedContacts([...selectedContacts, ContactsList[0]])
            } else {
                toast.error(i18n.t("fowardingModal.notices.maxSelect", { max: maxSelect }))
            }
        } else if (checked === false) {
            setSelectedContacts((current) => current.filter((contact) => contact.id !== value));
        }
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };
    const handleClose = () => {
        setSelectedContacts([]);
        setSearchParam();
        onClose();
    }

    const handleSendFwdMsg = async () => {
        setIsSubmiting(true)
        let fwdMsgData = {
            messageId: message.id, contacts: selectedContacts.map(contact => contact.number)
        }
        try {
            await api.post(`/messages/${message.ticketId}/fwd`, fwdMsgData);
        } catch (err) {
            toastError(err);
        }
        setIsSubmiting(false)
        handleClose();
    }
    return (
        <Dialog open={modalOpen} onClose={handleClose} maxWidth="md" fullWidth scroll="paper">
            <MainHeader>
                <DialogTitle
                    color="textPrimary"
                >
                    {i18n.t("fowardingModal.title")}
                </DialogTitle>
                <TextField
                    placeholder={i18n.t("fowardingModal.searchPlaceholder")}
                    type="search"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    value={searchParam}
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon style={{ color: "gray" }} />
                            </InputAdornment>
                        ),
                    }}
                />

            </MainHeader>
            {selectedContacts.length > 0 &&
                <>
                    <Divider />
                    <div style={{ display: "flex", padding: "8px" }}>{selectedContacts.map(contact =>
                        <div style={{ display: "flex",width:"20%", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                            <Avatar src={contact.profilePicUrl} />
                            {contact.name}
                        </div>
                    )}</div>
                </>
            }

            <DialogContent dividers padding={0} onScroll={handleScroll}>
                <div style={{ height: "53vh" }}>
                    <FormGroup row>
                        <Table size="small">
                            <TableHead>
                                <TableRow className={classes.tableCustomRow} >
                                    <TableCell padding="checkbox" />
                                    <TableCell padding="checkbox" />
                                    <TableCell align="center">{i18n.t("campaigns.table.name")}</TableCell>
                                    <TableCell align="center">
                                        {i18n.t("campaigns.table.whatsapp")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contacts?.length > 0 &&
                                    contacts.map(contact => (
                                        <TableRow className={classes.tableCustomRow} key={contact.id}>
                                            <TableCell align="center">
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        size="small"
                                                        color="primary"
                                                        value={contact.id}
                                                        checked={selectedContacts.some(current => current.id === contact.id)}
                                                        onChange={handleChange}
                                                        name={contact.number}
                                                    />} />
                                            </TableCell>

                                            <TableCell style={{ paddingRight: 0 }}>
                                                {<Avatar src={contact.profilePicUrl} />}
                                            </TableCell>
                                            <TableCell align="center">{contact.name}</TableCell>
                                            <TableCell align="center">{contact.number} </TableCell>
                                        </TableRow>
                                    ))}{loading && <TableRowSkeleton checkboxes={1} avatar={1} columns={2} />}
                            </TableBody>
                        </Table>
                    </FormGroup>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={isSubmiting}
                    variant="outlined"
                >
                    {i18n.t("campaigns.buttons.cancel")}
                </Button>
                <ButtonWithSpinner
                    variant="contained"
                    onClick={handleSendFwdMsg}
                    color="primary"
                    disabled={isSubmiting}
                    loading={isSubmiting}
                >
                    {'Enviar'}
                </ButtonWithSpinner>
            </DialogActions>
        </Dialog>
    )
}
export default FowardingModal;