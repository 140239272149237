import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from "@mui/material/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Avatar, FormControl, InputLabel, ListItem, MenuItem, Select, Typography } from "@mui/material";
import AvatarTooltip from "../AvatarTooltip";
import { Info } from "@mui/icons-material";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";

const NewTicketModal = ({ modalOpen, onClose }) => {
	const history = useHistory();
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [connectionId, setConnectionId] = useState(null);
	const [queueId, setQueueId] = useState(null);
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const { user } = useContext(AuthContext);
	const { whatsApps } = useContext(WhatsAppsContext);

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("/contacts", {
						params: { searchParam },
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedContact(null);
	};

	const handleSaveTicket = async contactId => {
		if (!contactId) return;
		setLoading(true);
		try {
			const { data: ticket } = await api.post("/tickets", {
				contactId: contactId,
				userId: user.id,
				queueId,
				status: "open",
				connectionId
			});
			history.push(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
		setLoading(false);
		handleClose();
	};

	const handleSelectOption = (e, newValue) => {
		if (newValue?.number) {
			setSelectedContact(newValue);
		} else if (newValue?.name) {
			const ifHasNumber = /^\d+$/.test(newValue.name); // Verifica se tem apenas números
			setNewContact(ifHasNumber ? { number: newValue.name } : { name: newValue.name });
			handleOpenContactModal();
		}
	};
	
	const handleOpenContactModal = () =>{
		setContactModalOpen(true);

	}
	const handleCloseContactModal = () => {
		setContactModalOpen(false);
	};

	const handleAddNewContactTicket = contact => {
		handleSaveTicket(contact.id);
	};

	const createAddContactOption = (filterOptions, params) => {

		if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
			filterOptions.push({
				name: `${params.inputValue}`,
			});
		}

		return filterOptions;
	};


	const renderOption = (props, option) => {
		if (option.number) {
			return (
				<li {...props}>
					{option.company || option.extraInfo?.length > 0 ?
						<AvatarTooltip
							arrow
							icon={<Info />}
							placement="right"
							title={
								<>
									{option.company &&
										<Typography>
											{`Empresa: ${option.company}`}
										</Typography>
									}
									{option.extraInfo?.length > 0 && option.extraInfo.map((info) =>
										<Typography>
											{`${info.name}: ${info.value}`}
										</Typography>
									)}
								</>
							}
						>
							<Avatar src={option.profilePicUrl} />
						</AvatarTooltip>
						: <Avatar src={option.profilePicUrl} />
					}
					<Typography
						noWrap
						component="span"
						variant="body2"
						color="textPrimary"
						style={{ marginLeft: "3px" }}
					>
						{option.company ?
							<>
								{option.name}
								<Typography
									component="span"
									color="textSecondary"
								>
									{` (${option.company}) `}
								</Typography>

							</>
							: option.name
						} {" - "} {option.number}
					</Typography>
				</li>
			);
		} else {
			return (<li {...props}>{`${i18n.t("newTicketModal.add")} ${option.name}`}</li>);
		}
	};

	const renderOptionLabel = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${option.name}`;
		}
	};

	return (
		<>	
			{contactModalOpen &&
				<ContactModal
				open={contactModalOpen}
				initialValues={newContact}
				onClose={handleCloseContactModal}
				onSave={handleAddNewContactTicket}
				/>
			}
			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle
					color="textPrimary"
					id="form-dialog-title"
				>
					{i18n.t("newTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					<Autocomplete
						options={options}
						loading={loading}
						style={{ width: 500 }}
						clearOnBlur
						autoHighlight
						freeSolo
						clearOnEscape
						getOptionLabel={renderOptionLabel}
						renderOption={(props, option) => renderOption(props, option)}
						filterOptions={createAddContactOption}
						onChange={(e, newValue) => handleSelectOption(e, newValue)}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("newTicketModal.fieldLabel")}
								variant="outlined"
								autoFocus
								margin="dense"
								onChange={e => setSearchParam(e.target.value)}
								onKeyDown={e => {
									if (loading || !selectedContact) return;
									else if (e.key === "Enter") {
										handleSaveTicket(selectedContact.id);
									}
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
					<FormControl fullWidth margin="dense" variant="outlined" >
						<InputLabel>{i18n.t("newTicketModal.queueLabel")}</InputLabel>
						<Select

							name="whatsappId"
							value={queueId}
							onChange={(e) => setQueueId(e.target.value)}
							label={i18n.t("newTicketModal.queueLabel")}
						// error={
						// 	touched.whatsappId && Boolean(errors.whatsappId)
						// }
						// helperText={
						// 	touched.whatsappId && errors.whatsappId
						// }
						>
							<MenuItem value={null}>&nbsp;</MenuItem>
							{user?.queues?.length > 0 && user.queues.map((queue) => (
								<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
							))}
						</Select>
					</FormControl>
					
					<FormControl fullWidth margin="dense" variant="outlined" >
						<InputLabel>{i18n.t("newTicketModal.connectionLabel")}</InputLabel>
						<Select
							
							name="whatsappId"
							value={connectionId}
							onChange={(e) => setConnectionId(e.target.value)}
							label={i18n.t("newTicketModal.connectionLabel")}
							// error={
							// 	touched.whatsappId && Boolean(errors.whatsappId)
							// }
							// helperText={
							// 	touched.whatsappId && errors.whatsappId
							// }
						>
							<MenuItem value={null}>&nbsp;</MenuItem>
							{whatsApps.length > 0 && whatsApps.map((whatsapp) => (
								<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
							))}
						</Select>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						disabled={!selectedContact}
						onClick={() => handleSaveTicket(selectedContact.id)}
						color="primary"
						loading={loading}
					>
						{i18n.t("newTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewTicketModal;
