import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { i18n } from "../../translate/i18n";
import { Field, FieldArray, Form, Formik, getIn, setIn } from "formik"; // Importe getIn e setIn aqui
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { DeleteOutline } from "@mui/icons-material";
import UploadField from "../UploadField";
import UploadFieldNew from "../UploadField/newUploadField";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    inlineWrapper: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        flexWrap: "wrap",
        gap: 4
    },
    multiLine: {
        display: "flex",
        gap: 4,
        flex: 1,
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    trashIcon: {
        color: theme.palette.secondary.main
    },
}));

const FileListSchema = Yup.object().shape({
    name: Yup.string().min(3, "Muito curto!").required("Obrigatório"),
    files: Yup.array().test('has-first-item', 'Obrigatório', function (value) {
        // Verifique se o array possui pelo menos um item e se o primeiro item não está vazio
        return value?.length > 0 && !!value[0].file?.length;
    }),
});

const FileListModal = ({ open, fileListId, onClose }) => {
    const maxFileSize = 1000000;
    const classes = useStyles();
    const initialState = {
        name: "",
        files: [{
            filename: "",
            file: [],
            mediaUrl: ""
        }]
    };
    const [fileList, setFileList] = useState(initialState);

    const downloadFile = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const urlParts = url.split('/');

            const fileName = urlParts[urlParts.length - 1];
            // Criar o objeto File a partir do blob do arquivo
            const file = new File([blob], fileName);

            // Definir o arquivo no elemento <input>
            return [file];
        } catch (error) {
            console.error('Erro ao fazer o download do arquivo:', error);
        }
    }

    useEffect(() => {
        (async () => {
            if (!fileListId) return;
            try {
                const { data } = await api.get(`/filelists/${fileListId}`);

                // Mapear os arquivos e baixá-los usando a função downloadFile
                const downloadedFiles = await Promise.all(data.files.map(async (fileInfo, index) => {
                    const downloadedFile = await downloadFile(fileInfo.mediaUrl);
                    return {
                        ...fileInfo,
                        file: downloadedFile,
                    };
                }));

                // Atualizar o estado com os arquivos baixados
                setFileList({ ...data, files: downloadedFiles });
            } catch (err) {
                toastError(err);
            }
        })();
        return () => {
            setFileList(initialState);
        }


    }, [fileListId, open]);


    const handleClose = () => {
        setFileList(initialState)
        onClose();
    };
    const handleSaveFileList = async (values) => {
        try {

            const formData = new FormData();
            values.files.forEach((fileInfo, index) => {
                if (fileInfo.file.length > 0) {
                    formData.append("fileArray", fileInfo.file[0]); // Usar "files" aqui
                }
            });
            formData.append('name', values.name);

            if (fileListId) {
                await api.put(`/filelists/${fileListId}`, formData);
            } else {
                await api.post("/filelists", formData);
            }
            toast.success(i18n.t("fileListModal.success"));
            handleClose();
        } catch (err) {
            toastError(err);
        }
    };


    return (
        <Dialog open={open} onClose={handleClose} maxWidth={"xs"} fullWidth>
            <DialogTitle
                id="form-dialog-title"
                color="textPrimary"
            >
                {fileListId
                    ? `${i18n.t("fileListModal.title.edit", { name: fileList.name })}`
                    : `${i18n.t("fileListModal.title.add")}`
                }
            </DialogTitle>
            <Formik
                initialValues={fileList}
                enableReinitialize={true}
                validationSchema={FileListSchema}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleSaveFileList(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form>
                        <DialogContent dividers>
                            <div style={{ maxHeight: "55vh" }}>
                                <Field
                                    as={TextField}
                                    fullWidth
                                    label={i18n.t("fileListModal.form.name")}
                                    name="name"
                                    variant="outlined"
                                    margin="dense"
                                    error={
                                        touched.name && Boolean(errors.name)
                                    }
                                    helperText={
                                        touched.name && errors.name
                                    }
                                    className={classes.textField}
                                />
                                <FieldArray name="files">
                                    {({ push, remove }) => (
                                        <>
                                            {values.files?.length > 0 && values.files.map((info, index) => (
                                                <div className={classes.extraAttr} key={`${index}-info`}>
                                                    <Field
                                                        fullWidth
                                                        as={UploadFieldNew}
                                                        label={
                                                            !values.files[index].file?.length > 0
                                                                ? "Nenhum arquivo anexado"
                                                                : "Arquivo anexado"
                                                        }
                                                        name={`files[${index}].filename`}
                                                        variant="outlined"
                                                        margin="dense"
                                                        onChange={(media) => {
                                                            setFieldValue(`files[${index}].filename`, media?.name);
                                                            values.files[index].file = media;
                                                        }}
                                                        onClear={() => {
                                                            values.files[index].file = [];
                                                            values.files[index].mediaUrl = "";
                                                            setFieldValue(`files[${index}].filename`, "");
                                                        }}
                                                        previewUrl={values.files[index]?.mediaUrl}
                                                        className={classes.textField}
                                                        error={
                                                            touched.files && index === 0 && Boolean(errors.files)
                                                        }
                                                        helperText={
                                                            touched.files && index === 0 && errors.files
                                                        }
                                                    />

                                                    {index > 0 && (
                                                        <IconButton
                                                            className={classes.trashIcon}
                                                            size="small"
                                                            onClick={() => remove(index)}
                                                        >
                                                            <DeleteOutline />
                                                        </IconButton>
                                                    )}
                                                </div>
                                            ))}
                                            <div className={classes.extraAttr}>
                                                <Button
                                                    style={{ flex: 1, marginTop: 8 }}
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => push({ file: "" })}
                                                >
                                                    {`+ ${i18n.t("fileListModal.buttons.addFile")}`}
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                color="secondary"
                                disabled={isSubmitting}
                                variant="outlined"
                            >
                                {i18n.t("fileListModal.buttons.cancel")}
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSubmitting}
                                variant="contained"
                                className={classes.btnWrapper}
                            >
                                {fileListId
                                    ? `${i18n.t("fileListModal.buttons.okEdit")}`
                                    : `${i18n.t("fileListModal.buttons.okAdd")}`}
                                {isSubmitting && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default FileListModal;
