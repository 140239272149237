import { Divider, TextField, Typography, Select, FormControl, InputLabel, MenuItem, Paper } from "@mui/material";
import { Field } from "formik";
import { i18n } from "../../translate/i18n";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { useContext, useEffect, useState } from "react";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import UploadField from "../UploadField/newUploadField";
import AdvancedTextField from "../AdvancedTextField";
import { differenceInMilliseconds, format, parse } from "date-fns";
import { messages } from "../../translate/languages";

const CampaignConfig = ({ classes, touched, errors, loading, values, setFieldValue }) => {
    const { whatsApps } = useContext(WhatsAppsContext);
    const [estimatedTime, setEstimatedTime] = useState(null);
    const textTemplates = {
        greeting: "Bom dia/Boa tarde/Boa noite",
        contactName: "Nome do contato",
        contactNumber: "Número do contato",
        contactEmail: "Email do contato",
        ticketNumber: "Número do ticket atual"
    }
    useEffect(() => {
        setEstimatedTime(getEstimatedTime());
    }, [values])

    const getEstimatedTime = () => {
        const { sendAt, maxInterval, minInterval, startRestrict, endRestrict, contacts } = values;
        const totalContacts = contacts.length;

        function timeStringToMilliseconds(timeString) {
            const [hours, minutes, seconds] = timeString.split(':').map(Number);
            const totalMilliseconds = hours * 3600000 + minutes * 60000 + seconds * 1000;
            return totalMilliseconds;
        }
        const isWithinRestrictionPeriod = (now) => {
            now = new Date(now)
            const startRestrictTime = parse(startRestrict, 'HH:mm:ss', now);
            const endRestrictTime = parse(endRestrict, 'HH:mm:ss', now);

            if (startRestrictTime < endRestrictTime) {
                // O período de restrição está dentro do mesmo dia
                return now >= startRestrictTime && now <= endRestrictTime;
            } else if (startRestrictTime > endRestrictTime){
                // O período de restrição atravessa a meia-noite
                return now >= startRestrictTime || now <= endRestrictTime;
            }else{
                return false;
            }
        }
        const calculateRemainingInterval = (now) => {
            if (isWithinRestrictionPeriod(now)) {
                now = new Date(now)
                let endRestrictTime = parse(endRestrict, 'HH:mm:ss', now);
                if (endRestrictTime > now) {
                    const remainingMilliseconds = differenceInMilliseconds(endRestrictTime, now);
                    return remainingMilliseconds;
                } else {
                    endRestrictTime.setDate(endRestrictTime.getDate() + 1);
                    const remainingMilliseconds = differenceInMilliseconds(endRestrictTime, now);
                    return remainingMilliseconds;
                }
            }
            return 0; // Fora do período de restrição ou após o endRestrict
        }
        const maxMilliseconds = timeStringToMilliseconds(maxInterval);
        const minMilliseconds = timeStringToMilliseconds(minInterval);

        let elapsedTimeOnMax = new Date(sendAt).getTime();
        let elapsedTimeOnMin = new Date(sendAt).getTime();
        let sentContacts = 0;
        while (sentContacts < totalContacts) {
            elapsedTimeOnMax += maxMilliseconds;
            elapsedTimeOnMin += minMilliseconds;
            if (isWithinRestrictionPeriod(elapsedTimeOnMax)) {
                elapsedTimeOnMax += calculateRemainingInterval(new Date(elapsedTimeOnMax))
            }
            if (isWithinRestrictionPeriod(elapsedTimeOnMin)) {
                elapsedTimeOnMin += calculateRemainingInterval(new Date(elapsedTimeOnMin))
            }
            sentContacts += 1;
        }
        // Format the duration
        const formatDuration = (milliseconds) => {
            const seconds = Math.floor(milliseconds / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);
            const months = Math.floor(days / 30);
            const formattedParts = [];
            if (months > 0) {
                formattedParts.push(`${months} mês${months > 1 ? 'es' : ''}`);
            }
            if (days % 30 > 0) {
                formattedParts.push(`${days % 30} dia${days % 30 > 1 ? 's' : ''}`);
            }
            if (hours % 24 > 0) {
                formattedParts.push(`${hours % 24} hora${hours % 24 > 1 ? 's' : ''}`);
            }
            if (minutes % 60 > 0) {
                formattedParts.push(`${minutes % 60} minuto${minutes % 60 > 1 ? 's' : ''}`);
            }
            if (seconds % 60 > 0) {
                formattedParts.push(`${seconds % 60} segundo${seconds % 60 > 1 ? 's' : ''}`);
            }
            if (formattedParts.length === 0) {
                return 'Menos de um segundo';
            }
            if (formattedParts.length > 1) {
                const lastPart = formattedParts.pop();
                const secondLastPart = formattedParts.pop();
                formattedParts.push(secondLastPart + ' e ' + lastPart);
            }
            return formattedParts.join(', ');
        };

        const maxDuration = formatDuration(new Date(new Date(elapsedTimeOnMax) - new Date(sendAt)).getTime());
        const minDuration = formatDuration(new Date(new Date(elapsedTimeOnMin) - new Date(sendAt)).getTime());
        const meanDuration = formatDuration(new Date(new Date((elapsedTimeOnMax + elapsedTimeOnMin) / 2) - new Date(sendAt)).getTime());
        return {
            max: maxDuration,
            min: minDuration,
            mean: meanDuration
        };

    };


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={classes.inlineWrapper}>
                <Field
                    as={TextField}
                    label={i18n.t("campaignModal.form.name")}
                    autoFocus
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name ? errors.name : ""}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                />
                <Field
                    as={DateTimePicker}
                    label={i18n.t("campaignModal.form.sendAt")}
                    type="datetime-local"
                    format="dd/MM/yyyy HH:mm"
                    value={new Date(values.sendAt)}
                    className={classes.textField}
                    ampm={false}
                    disablePast

                    onChange={(date) => setFieldValue("sendAt", format(new Date(date), "yyyy-MM-dd'T'HH:mm"))}
                    slotProps={{
                        textField: {
                            margin: "dense",
                            error: touched.sendAt && Boolean(errors.sendAt),
                            helperText: touched.sendAt && errors.sendAt ? errors.sendAt : ""
                        }
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <FormControl variant="outlined" className={classes.textField} margin="dense">
                    <InputLabel>{i18n.t("campaignModal.form.connection")}</InputLabel>
                    <Field
                        as={Select}
                        name="whatsappId"
                        //value={whatsappId}
                        //onChange={(e) => setWhatsappId(e.target.value)}
                        label={i18n.t("campaignModal.form.connection")}
                        error={
                            touched.whatsappId && Boolean(errors.whatsappId)
                        }
                        helperText={
                            touched.whatsappId && errors.whatsappId
                        }
                    >
                        {whatsApps.map((whatsapp) => (
                            <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
                        ))}
                    </Field>
                </FormControl>
            </div>
            <div className={classes.inlineWrapper}>
                <Paper variant="outlined" style={{ margin: "4px 0", flex: 1, borderRadius: 4, display: "flex", flexDirection: "column", padding: 4 }}>
                    <Typography
                        style={{ margin: "auto" }}
                    >
                        {i18n.t("campaignModal.form.interval")}
                    </Typography>
                    <div className={classes.multiLine}>

                        <Field
                            as={TimePicker}
                            ampm={false}
                            label={i18n.t("campaignModal.form.min")}
                            value={parse(values.minInterval, 'HH:mm:ss', new Date())}
                            name="minInterval"
                            className={classes.multiLine}
                            color="primary"
                            views={['hours', 'minutes', 'seconds']}
                            onChange={(time) => setFieldValue("minInterval", format(new Date(time), "HH:mm:ss"))}
                            maxTime={parse(values.maxInterval, 'HH:mm:ss', new Date())}
                            slotProps={{
                                textField: {
                                    style: { minWidth: 100 },
                                    margin: "dense",
                                
                                    // error: touched.minInterval && Boolean(errors.minInterval),
                                    // helperText: touched.minInterval && errors.minInterval ? errors.minInterval : ""
                                }
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <Divider orientation="vertical" />

                        <Field
                            as={TimePicker}
                            ampm={false}
                            label={i18n.t("campaignModal.form.max")}
                            name="maxInterval"
                            value={parse(values.maxInterval, 'HH:mm:ss', new Date())}
                            minTime={parse(values.minInterval, 'HH:mm:ss', new Date())}
                            className={classes.multiLine}
                            views={['hours', 'minutes', 'seconds']}
                            onChange={(time) => setFieldValue("maxInterval", format(new Date(time), "HH:mm:ss"))}
                            slotProps={{
                                textField: {
                                    style: { minWidth: 100 },
                                    margin: "dense",
                                    helperText: touched.maxInterval || errors.maxInterval ? errors.maxInterval : ""
                                }
                            }}
                            error={ touched.maxInterval || Boolean(errors.maxInterval)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                    </div>
                </Paper>
                <Paper variant="outlined" style={{ margin: "4px 0", flex: 1, borderRadius: 4, display: "flex", flexDirection: "column", padding: 4 }}>
                    <Typography
                        style={{ margin: "auto" }}
                    >
                        {i18n.t("campaignModal.form.restrictTime")}
                    </Typography>
                    <div className={classes.multiLine}>

                        <Field
                            as={TimePicker}
                            ampm={false}
                            label={i18n.t("campaignModal.form.start")}
                            value={parse(values.startRestrict, 'HH:mm:ss', new Date())}
                            name="startRestrict"
                            className={classes.multiLine}
                            color="primary"
                            views={['hours', 'minutes', 'seconds']}
                            onChange={(time) => setFieldValue("startRestrict", format(new Date(time), "HH:mm:ss"))}
                            slotProps={{
                                textField: {
                                    style: { minWidth: 100 },
                                    margin: "dense",
                                    error: touched.startRestrict && Boolean(errors.startRestrict),
                                    helperText: touched.startRestrict && errors.startRestrict ? errors.startRestrict : ""
                                }
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <Divider orientation="vertical" />

                        <Field
                            as={TimePicker}
                            ampm={false}
                            label={i18n.t("campaignModal.form.end")}
                            name="endRestrict"
                            value={parse(values.endRestrict, 'HH:mm:ss', new Date())}
                            minTime={new Date(values.startRestrict)}
                            className={classes.multiLine}
                            views={['hours', 'minutes', 'seconds']}
                            onChange={(time) => setFieldValue("endRestrict", format(new Date(time), "HH:mm:ss"))}
                            slotProps={{
                                textField: {
                                    style: { minWidth: 100 },
                                    margin: "dense",
                                    error: touched.endRestrict && Boolean(errors.endRestrict),
                                    helperText: touched.endRestrict && errors.endRestrict ? errors.endRestrict : ""
                                }
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </Paper>

            </div>
            <div className={classes.inlineWrapper}>

                <Paper variant="outlined" style={{ margin: "4px 0", flex: 1, borderRadius: 4, display: "flex", flexDirection: "column", padding: 4 }}>
                    <Typography
                        style={{ margin: "auto" }}
                    >
                        {i18n.t("campaignModal.form.estimatedTime",{count:values.contacts.length})}
                    </Typography>
                    <div className={classes.multiLine}>
                        <TextField
                            label={i18n.t("campaignModal.form.min")}
                            margin="dense"
                            className={classes.multiLine}
                            disabled={true}
                            value={estimatedTime?.min}
                        />
                        <TextField
                            label={i18n.t("campaignModal.form.mean")}
                            margin="dense"
                            className={classes.multiLine}
                            disabled={true}
                            value={estimatedTime?.mean}
                        />
                        <TextField
                            label={i18n.t("campaignModal.form.max")}
                            margin="dense"
                            className={classes.multiLine}
                            disabled={true}
                            value={estimatedTime?.max}
                        />
                    </div>
                </Paper>
            </div>
            <div className={classes.inlineWrapper}>
                {[1, 2, 3, 4, 5, 6].map((i) => {
                    const fieldName = `message${i === 1 ? "" : i}`;
                    return (
                        <Field
                            key={fieldName}
                            as={AdvancedTextField}
                            selectTemplates={true}
                            label={i18n.t("campaignModal.form.message") + " " + i}
                            type="message"
                            multiline
                            rows={4}
                            margin="dense"
                            id={fieldName}
                            name={fieldName}
                            onChange={(value) => values[fieldName] = value}
                            className={classes.textField}
                            variant="outlined"
                            error={touched[fieldName] && Boolean(errors[fieldName])}
                            templates={textTemplates}
                            value={values[fieldName]}
                        />
                    );
                })}
            </div>
            <div className={classes.inlineWrapper}>
                {[1, 2, 3, 4, 5, 6].map((i) => {
                    const fieldName = `messageMedia${i === 1 ? "" : i}`;
                    return (
                        <Field
                            key={fieldName}
                            id={`message-upload${i === 1 ? "" : i}`}
                            name={`media${i === 1 ? "" : i}`}
                            className={classes.mediaField}
                            label={`Anexar Arquivo ${i}`}
                            as={UploadField}
                            margin="dense"
                            // value={values[`messageMedia${i == 1 ? "" : i}`]}
                            onChange={(media) => (values[fieldName] = media)}
                            onClear={() => (values[fieldName] = [])}
                            previewUrl={values[`mediaUrl${i === 1 ? "" : i}`]}
                        />
                    );
                })}
            </div>
        </LocalizationProvider>
    );
};

export default CampaignConfig;
