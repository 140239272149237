import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export function CircularProgressWithLabel(props) {
    const { size, value } = props;

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="indeterminate"
                sx={{
                    color: (theme) => theme.palette.primary.dark,
                    animationDuration: '1400ms',
                    position: 'absolute',
                    left: 2,
                    top: 2,
                    bottom: 2,
                    right: 2,
                    margin: "auto"

                }}
                size={size - 6.1}
                thickness={2}
            //{...props}

            />
            <CircularProgress
                variant="determinate"
                disableShrink
                sx={{
                    color: (theme) => theme.palette.divider,
                    animationDuration: '550ms',
                    position: 'absolute',
                    left: 0,
                }}
                size={size}
                thickness={4}
                //{...props}
                value={100}
            />
            <CircularProgress variant="determinate" size={size} value={value} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: "default"
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

