import React, { createContext } from "react";
import useTags from "../../hooks/useTags";


const TagsContext = createContext();

const TagsProvider = ({ children }) => {
    const { loading, tags } = useTags({showAll:true});

    return (
        <TagsContext.Provider value={{ tags, loading }}>
            {children}
        </TagsContext.Provider>
    );
};

export { TagsContext, TagsProvider };
