import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { useEffect, useState } from "react";
import openSocket from "../../services/socket-io";

export const changeSettings = async (e) => {
    const selectedValue = e.target.value;
    const settingKey = e.target.name;

    try {
        await api.put(`/settings/${settingKey}`, {
            value: selectedValue,
        });
        toast.success(i18n.t("settings.success"));
    } catch (err) {
        toastError(err);
    }
};

const useSettings = () => {
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState([]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {

            const fetchSettings = async () => {
                try {
                    const { data } = await api.get("/settings");
                    setSettings(data);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }

            }
            fetchSettings();
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, []);

    useEffect(() => {
        const socket = openSocket();

        socket.on("settings", data => {
            if (data.action === "update") {
                setSettings(prevState => {
                    const aux = [...prevState];
                    const settingIndex = aux.findIndex(s => s.key === data.setting.key);
                    aux[settingIndex].value = data.setting.value;
                    return aux;
                });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return { settings, loading };
}
export default useSettings;