import { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const reducer = (state, action) => {
    if (action.type === "LOAD_CATEGORIES") {
        const categories = action.payload;
        const newCategories = [];

        categories.forEach((category) => {
            const categoryIndex = state.findIndex((c) => c.id === category.id);
            if (categoryIndex !== -1) {
                state[categoryIndex] = category;
            } else {
                newCategories.push(category);
            }
        });

        return [...state, ...newCategories];
    }

    if (action.type === "UPDATE_CATEGORY") {
        const category = action.payload;
        const categoryIndex = state.findIndex((c) => c.id === category.id);

        if (categoryIndex !== -1) {
            state[categoryIndex] = category;
            return [...state];
        } else {
            return [category, ...state];
        }
    }

    if (action.type === "DELETE_CATEGORY") {
        const categoryId = action.payload;
        const categoryIndex = state.findIndex((c) => c.id === categoryId);
        if (categoryIndex !== -1) {
            state.splice(categoryIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useCategories = ({ showAll = false }) => {
    const [categories, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {

        const fetchCategories = async () => {
            try {
                const { data } = await api.get("/categories", { params: { showAll } });
                dispatch({ type: "LOAD_CATEGORIES", payload: data.categories });
                setHasMore(data.hasMore);
            } catch (err) {
                toastError(err);
            }
        };
        fetchCategories();
        setLoading(false);
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, []);


    useEffect(() => {
        const socket = openSocket();

        socket.on("category", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_CATEGORY", payload: data.category });

            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_CATEGORY", payload: data.categoryId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return { categories, hasMore, loading };
};

export default useCategories;