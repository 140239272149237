import React, { useState, useEffect, useReducer, useRef, useContext } from "react";
import ReactDOM from 'react-dom';
import { isSameDay, parseISO, format, isYesterday, isToday } from "date-fns";
import openSocket from "../../services/socket-io";
import clsx from "clsx";

import { blue, cyan, green, lightBlue } from "@mui/material/colors";
import { Avatar, Badge, Button, Chip, CircularProgress, Divider, Fab, IconButton, Popper, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
  GetApp,
  Reply,
  CallMissed,
  ArrowDownward,
  KeyboardArrowDown,
  People,
  Person,
  Photo,
  Mic,
  Videocam,
  Article,
  InsertDriveFile,
  LocationOn,
  SpeakerNotesOff,
  Announcement,
  WebStories
} from "@mui/icons-material";

import MarkdownWrapper from "../MarkdownWrapper";
import VcardPreview from "../VcardPreview";
import LocationPreview from "../LocationPreview";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import Audio from "../Audio";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { messages } from "../../translate/languages";
import { SearchContext } from "../../context/Search/SearchContext";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import TextResumer from "../TextResumer";
import ModalImage from "react-modal-image";


const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    backgroundColor: theme.palette.background.messagesList.dark,

  },
  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
  },
  newMessageScrollButton: {
    zIndex: 1,
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    }
  },
  messagesList: {
    backgroundImage: theme.palette.background.messagesList.image,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    [theme.breakpoints.down('md')]: {
      paddingBottom: "90px",
    },
  },

  circleLoading: {
    color: theme.palette.primary.main,
    opacity: "70%",
  },
  circleLoadingWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "5px",
    top: 3,
    left: "50%",
    position: "absolute",
    borderRadius: "50px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    backgroundColor: theme.palette.background.paper

  },
  messageInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    color: theme.palette.text.secondary
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    //minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 4,
    paddingBottom: 0,
    boxShadow: theme.palette.shadow,
    "&::before": {
      content: "''",
      position: "absolute",
      top: "10px",
      left: "-6px",
      transform: "translateY(-50%)",
      width: 0,
      height: 0,
      borderBottom: "20px solid transparent",
      borderRight: `10px solid ${theme.palette.background.paper}`,
      borderRadius: `4px`
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: "90%"
    },
  },

  quotedContainerLeft: {
    margin: "0px 0px 4px 0px",
    overflow: "hidden",
    backgroundColor: theme.palette.primary.main + 14,
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
    cursor: "pointer"
  },

  quotedMsg: {
    display: "flex",
    justifyContent: "space-between",
    padding: 6,
    maxWidth: "100%",
    width: "100%",
    height: "auto",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 2,
    //minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.primary,
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 4,
    paddingBottom: 0,
    boxShadow: theme.palette.shadow,
    "&::before": {
      content: "''",
      position: "absolute",
      bottom: "-10px",
      right: "-6px",
      transform: "translateY(-50%)",
      width: 0,
      height: 0,
      borderTop: "20px solid transparent",
      borderLeft: `8px solid ${theme.palette.primary.light}`,
      borderBottomRightRadius: `4px`
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: "90%"
    },
  },
  messageRightInternal: {
    marginLeft: 20,
    marginTop: 2,
    //minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    backgroundColor: theme.palette.info.light,
    color: theme.palette.text.primary,
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 4,
    paddingBottom: 0,
    boxShadow: theme.palette.shadow,
    "&::before": {
      content: "''",
      position: "absolute",
      bottom: "-10px",
      right: "-6px",
      transform: "translateY(-50%)",
      width: 0,
      height: 0,
      borderTop: "20px solid transparent",
      borderLeft: `8px solid ${theme.palette.info.light}`,
      borderBottomRightRadius: `4px`
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: "90%"
    },
  },

  quotedContainerRight: {
    margin: "0px 0px 4px 0px",
    overflowY: "hidden",
    backgroundColor: theme.palette.primary.dark + 14,
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
    cursor: "pointer"
  },

  quotedMsgRight: {
    padding: 3,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: theme.palette.text.secondary,
    zIndex: 1,
    backgroundColor: "transparent",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "transparent" },
  },

  messageContactNameLeft: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  messageContactNameRight: {
    display: "flex",
    color: "#35cd96",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "5px 5px 5px 5px",
  },

  textContentItemDeleted: {
    display: "flex",
    alignItems: "center",
    fontStyle: "italic",
    fontWeight: 300,
    color: theme.palette.text.secondary,
    overflowWrap: "break-word",
    padding: "5px 5px 5px 5px",
  },
  quotedMediaDescripton: {
    padding: 5,
    width: "100%",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    minWidth: "fit-content",
    maxWidth: 330,
    "& p": {
      display: "flex",
      alignItems: "center",
    }
  },
  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderRadius: 8,
    zIndex: 1000,
  },
  quotedMessageMedia: {
    objectFit: "cover",
    width: "80px",
    height: "80px",
    margin: "-10px",
    marginLeft: 5
  },

  timestamp: {
    display: "flex",
    flexWrap: "nowrap",
    wordBreak: "keep-all",
    fontSize: 11,
    margin: "auto",
    marginTop: -15,
    marginBottom: 0,
    marginRight: 0,
    color: theme.palette.text.secondary,
  },

  messageContent: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "end",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: theme.palette.background.paper,
    margin: "10px",
    borderRadius: "10px",
    boxShadow: theme.palette.shadow,
  },

  dailyTimestampText: {
    color: theme.palette.text.secondary,
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },
  callLog: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    maxWidth: "400px",
    backgroundColor: theme.palette.background.paper,
    margin: "10px",
    borderRadius: "10px",
    boxShadow: theme.palette.shadow,
  },

  callLogIcon: {
    fontSize: 18,
    color: theme.palette.secondary.main,
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 24,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: cyan[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    backgroundColor: theme.palette.background.paper + "5D",
    padding: 10,
    textTransform: "unset",
    color: theme.palette.text.primary,
  },
  quotedDownloadMedia: {
    height: 50,
    width: 50,
    color: theme.palette.text.primary,
  },
  downloadDescription: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%"
  },
  downloadFiletype: {
    color: theme.palette.text.secondary,
    fontWeight: 400
  },
  ticketNumber: {
    display: 'flex',
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper + '5d',
    padding: 8,
    margin: 10,
    justifyContent: 'center',
    alignItems: "center",
    marginLeft: -20,
    marginRight: -20
  },
  ticketNumberChip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontSize: 14
  },
  reactionContainerRight: {
    display: "none",
    position: "absolute",
    marginTop: -5,
    borderRadius: 70,
    border: `1px solid ${theme.palette.divider}`,
    padding: "0 5px",
    right: 0,
    backgroundColor: theme.palette.background.paper,
  },
  reactionContainerLeft: {
    display: "none",
    position: "absolute",
    marginTop: -5,
    borderRadius: 70,
    border: `1px solid ${theme.palette.divider}`,
    padding: "0 5px",
    left: 0,
    backgroundColor: theme.palette.background.paper,
  }
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }
  function ToastDisplay(props) {
    return <><Typography>Mensagem apagada:</Typography><Typography>{props.body}</Typography></>;
  }


  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageToUpdate.isDeleted === true && messageToUpdate.fromMe) {
      //toast.info(`Mensagem apagada: ${messageToUpdate.body}  `,{autoClose: false});
      toast.info(<ToastDisplay
        body={messageToUpdate.body}
      >
      </ToastDisplay>);
    }

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "DELETE_MESSAGE") {
    const messageId = action.payload;
    const messageIndex = state.findIndex((q) => q.id === messageId);
    if (messageIndex !== -1) {
      state.splice(messageIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const MessagesList = ({ ticketId, isGroup, showHistory, connType }) => {
  const classes = useStyles();

  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessages] = useState(0);
  const [scrollButtonVisibility, setScrollButtonVisibility] = useState(false);
  const lastMessageRef = useRef();
  const { searchMessage, setSearchMessage } = useContext(SearchContext);
  const { settings } = useContext(SettingsContext);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);
  const [contactColors, setContactColors] = useState([]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);

    currentTicketId.current = ticketId;
  }, [ticketId, showHistory]);

  useEffect(() => {
    if (searchMessage) {
      scrollToMessage(searchMessage);
    }
  }, [searchMessage])

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        try {
          const { data } = await api.get("/messages/" + ticketId, {
            params: { pageNumber, showAllMessages: showHistory },
          });
          if (currentTicketId.current === ticketId) {
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setUniqueContactColors(data.messages);

            setHasMore(data.hasMore);
            setLoading(false);
          }

          if (pageNumber === 1 && data.messages.length > 1) {
            scrollToBottom();
            setTimeout(() => {
              scrollToBottom();
            }, 1000);
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, ticketId, showHistory]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("appMessage", (data) => {
      if (data.action === "create") {
        dispatch({ type: "ADD_MESSAGE", payload: data.message });
        const elMessagesList = document.getElementById("messagesList");
        if (elMessagesList.scrollTop >= elMessagesList.scrollHeight - 300 - elMessagesList.clientHeight) {
          scrollToBottom()
        } else {
          setNewMessages((prev) => prev + 1)
        }
      }

      if (data.action === "update") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_MESSAGE", payload: data.message.id });
        const reactionEl = document.querySelector(`[data-reaction-id="${data.message.id}"]`)
        const reactionContainer = document.getElementById(`reaction_${data.message.quotedMsgId}`);
        const messageEl = document.getElementById(data.message.quotedMsgId);


        if (messageEl) {
          messageEl.style.marginBottom = "0";
        }
        if (reactionEl) reactionEl.remove();
        if (reactionContainer?.childElementCount < 1) reactionContainer.style.display = "none";
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId]);

  const getSettingValue = (key) => {
    if (settings && settings.length > 0) {
      const { value } = settings.find(s => s.key === key);
      return value;
    }
  };

  const getRandomColor = () => {
    // Gerar valores RGB em uma faixa de tons pastéis (R e G dentro de 100-200, B dentro de 150-250)
    const r = Math.floor(Math.random() * 100) + 100;
    const g = Math.floor(Math.random() * 100) + 100;
    const b = Math.floor(Math.random() * 100) + 150;

    // Converter os valores RGB em uma string hexadecimal no formato #RRGGBB
    const color = `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
    return color;
  }

  const setUniqueContactColors = (messages) => {
    messages.forEach((message) => {
      const contactId = message.contactId;

      // Verificar se o contactId não é nulo e se ainda não está na lista
      if (contactId !== null && !contactColors.some((contact) => contact.contactId === contactId)) {
        let color;
        do {
          color = getRandomColor(); // Gera uma cor aleatória
        } while (contactColors.some((contact) => contact.color === color));

        setContactColors((prevContactList) => [...prevContactList, { contactId, color }]);
        return color;
      }
    });
  };


  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScroll = (e) => {
    const elMessagesList = document.getElementById("messagesList");
    const msgScrollButton = document.getElementById("msgScrollBtn");
    if (elMessagesList.scrollTop >= elMessagesList.scrollHeight - 100 - elMessagesList.clientHeight) {
      setScrollButtonVisibility(false);
      setNewMessages(0);
    } else {
      setScrollButtonVisibility(true);
    }
    if (!hasMore) return;
    const { scrollTop } = e.currentTarget;
    if (scrollTop === 0) {
      elMessagesList.scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
      setLoading(true);
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const fileExtension = (filename) => {
    const parts = filename.split('.');
    return parts[parts.length - 1].toUpperCase();
  }

  const scrollToMessage = (message) => {

    if (!showHistory && +message.ticketId !== +ticketId) {
      toast.error("MESSAGE_NOT_FOUND_ON_TICKET");
      setLoading(false);
      return;
    };
    const elMessage = document.getElementById(message.id);
    if (elMessage) {
      elMessage.style.backgroundColor = blue[800]; // Adiciona fundo azul
      setTimeout(() => {
        elMessage.style.backgroundColor = ""; // Remove fundo azul após 2 segundos
      }, 1500);
      elMessage.style.transition = "none"; // Define a transição de cor
      elMessage.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setSearchMessage();
      setLoading(false);
    } else if (!loading) {
      loadMore();
      setLoading(true)
      setTimeout(() => {
        scrollToMessage(message);
      }, 1000); // Aguarda 1 segundo antes de tentar novamente
    }
  };


  const NewMessageScrollButton = () => {
    const viewNewMessages = () => {
      scrollToBottom();
    }
    return (
      <>
        <Fab id="msgScrollBtn" size="small" onClick={viewNewMessages} className={classes.newMessageScrollButton}>
          <Badge
            badgeContent={newMessage}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{
              badge: classes.badgeStyle,
            }}
          >
            <KeyboardArrowDown />
          </Badge>
        </Fab>
      </>
    )
  }

  const checkMessageMedia = (message) => {


    if (message.mediaType === "location" && message.body.split('|').length >= 2) {
      let locationParts = message.body.split('|')
      let imageLocation = locationParts[0]
      let linkLocation = locationParts[1]

      let descriptionLocation = null

      if (locationParts.length > 2)
        descriptionLocation = message.body.split('|')[2]

      return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
    }
    else if (message.mediaType === "vcard") {
      return renderVCard({ message });
    } else if (message.mediaType === "multi_vcard") {
      return renderVCard({ message });
    }

    else if (message.mediaType === "image") {
      return (
        <ModalImageCors
          imageUrl={message.mediaUrl}
          filename={`${message.fromMe ? i18n.t("contactMediaModal.you") : message.contact.name}: ${message.body || message.filename}`}
        />
      );
    } else if (message.mediaType === "audio") {
      return <Audio url={message.mediaUrl} />
    } else if (message.mediaType === "video") {
      return (
        <video
          className={classes.messageMedia}
          src={message.mediaUrl}
          controls
        />
      );
    } else {
      return (
        <>
          <Button
            endIcon={<GetApp style={{ borderRadius: "50px", padding: "2px", border: "1px solid #ffffff" }} />}
            className={classes.downloadMedia}
            variant="outlined"
            target="_blank"
            href={message.mediaUrl}
          >
            <div className={classes.downloadDescription}>
              <Typography noWrap >
                <InsertDriveFile />
                {message.filename}

              </Typography>
              <span className={classes.downloadFiletype}>
                {`${i18n.t("Tipo de arquivo:")} ${fileExtension(message.filename)}`}
              </span>
            </div>

          </Button>

        </>
      );
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    const messageDay = parseISO(messagesList[index].createdAt);
    const previousMessageDay = parseISO(messagesList[index - 1]?.createdAt);
    return (
      <>
        {index === messagesList.length - 1 &&
          <div
            key={`ref-${message.createdAt}`}
            ref={lastMessageRef}
            style={{ float: "left", clear: "both" }}
          />
        }
        {!previousMessageDay || !isSameDay(messageDay, previousMessageDay) &&
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {isToday(messageDay) && "Hoje"}
              {isYesterday(messageDay) && "Ontem"}
              {!isToday(messageDay) && !isYesterday(messageDay) && format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        }
      </>
    );
  };

  const renderNumberTicket = (message, index) => {
    const messageTicket = message.ticketId;
    const ticketSubject = message.ticket?.subject;
    const previousMessageTicket = messagesList[index - 1]?.ticketId;

    if (messageTicket !== previousMessageTicket || !previousMessageTicket && messageTicket === currentTicketId) {
      return (
        <div key={`ticket-${message.id}`} className={classes.ticketNumber}>
          <Chip className={classes.ticketNumberChip} label={`#Ticket: ${messageTicket}`} />
          {ticketSubject &&
            <Typography>{`Assunto: ${ticketSubject}`}</Typography>
          }
        </div>

      );
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };
  const renderLogs = (message) => {
    if (message.mediaType === "call_log") {
      return (
        <span
          className={classes.callLog}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            <CallMissed className={classes.callLogIcon} />
            {`Chamada perdida às ${format(parseISO(message.createdAt), "HH:mm")}`}
          </div>
        </span>
      )
    } else if (message.mediaType === "message_log") {
      return (
        <span
          className={classes.callLog}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {i18n.t(`messagesList.logs.${message.body}`)}
          </div>
        </span>
      )
    }
  }

  const renderVCard = ({ message, quotedMsg }) => {
    if (message.mediaType === "vcard") {
      const array = message.body.split("\n");
      const obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }
          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }
      if (quotedMsg) {
        return (
          <>
            <Person style={{ height: "16px" }} />
            <MarkdownWrapper>{`Contato: ${contact}`}</MarkdownWrapper>
          </>)
      }
      return <VcardPreview contact={contact} numbers={obj[0]?.number} />
    }

    else if (message.mediaType === "multi_vcard") {
      const cleanedString = message.body.replace(/\\n/g, '\n'); // Restaurar as quebras de linha
      const array = cleanedString.toString().split("END:VCARD");
      const obj = [];
      for (let i = 0; i < array.length; i++) {
        const vCard = array[i];
        const vCardLines = vCard.split("\n");
        let name = "";
        let number = "";
        for (let j = 0; j < vCardLines.length; j++) {
          const line = vCardLines[j];
          if (line.startsWith("FN:")) {
            name = line.slice(3);
          } else if (line.includes("TEL")) {
            const phoneNumber = line.split(":")[1];
            number = phoneNumber;
          }
        }
        if (name !== "" && number !== "") {
          obj.push({
            name,
            number
          });
        }
      }
      if (quotedMsg) {
        return (
          <>
            <Person style={{ height: "16px" }} />
            <MarkdownWrapper>{`${obj?.length} contatos`}</MarkdownWrapper>
          </>)
      }
      return (
        <>
          {obj.map((ob) => (
            <VcardPreview contact={ob?.name} numbers={ob?.number} />
          ))}
        </>
      );
    }

  }

  const renderContactName = (message) => {
    const contactId = message?.contactId;
    const contactColor = contactColors.find((contactColor) => contactColor.contactId === contactId);
    let color;
    if (!contactColor && isGroup && !message.fromMe) {
      color = getRandomColor();
      setContactColors((prevContactList) => [...prevContactList, { contactId, color }]);
    }
    color = contactColor?.color;

    return (
      <span
        className={clsx(classes.messageContactNameLeft, {
          [classes.messageContactNameRight]: message?.fromMe,
        })}
        style={{ color }}
      >
        {message?.contact?.name ?? "Você"}
      </span>
    );
  };

  const renderLinkThumbnail = (message) => {
    let linkData = typeof message.linkData === "string" ? JSON.parse(message?.linkData) : message?.linkData;
    if (!linkData) return;
    return (

      <a className={clsx(classes.quotedContainerLeft, {
        [classes.quotedContainerRight]: message?.fromMe
      })} style={{ textDecoration: "none", maxWidth: "100%", display: "flex" }} href={linkData?.matchedText}>
        {linkData?.jpegThumbnail &&
          <img
            variant="square"
            style={{ maxWidth: "100%", maxHeight: 300, minWidth: 80, minHeight: 80, objectFit: "contain" }}
            src={process.env.REACT_APP_BACKEND_URL + "/public/" + linkData?.jpegThumbnail}
            alt={`${linkData?.description.substring(0, 19)}${linkData?.description.length > 20 && "..."}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.style.display = "none"
            }}
          />
        }
        <div className={classes.quotedMediaDescripton}>
          <Typography color="textPrimary">
            {linkData?.title}
          </Typography>
          <Typography color="textSecondary">
            <MarkdownWrapper>{linkData?.description}</MarkdownWrapper>
          </Typography>
          <Typography noWrap sx={{ color: 'gray' }}>
            {linkData?.matchedText}
          </Typography>
        </div>
      </a>
    );
  }

  const renderQuotedMessage = (message) => {
    const quotedMsg = message.quotedMsg;
    let isStatus = quotedMsg.mediaType.includes("status_");
    let isOld = quotedMsg.mediaType.includes("old_");
    const renderMediaTypes = (mediaType) => {
      mediaType = mediaType.replace("status_", "");
      mediaType = mediaType.replace("old_", "");
      if (mediaType === "chat") {
        return (
          <div className={classes.quotedMediaDescripton}>
            {renderContactName(!isStatus ? quotedMsg : {
              ...quotedMsg,
              contact: {
                name: "Status"
              },
            })}
            {<Typography color="textSecondary">
              {quotedMsg.body}
            </Typography>}
          </div>
        );
      } else if (mediaType === "audio") {
        return (
          <div className={classes.quotedMediaDescripton}>
            {renderContactName(!isStatus ? quotedMsg : {
              ...quotedMsg,
              contact: {
                name: "Status"
              },
            })}
            <Typography noWrap color="textSecondary">
              <Mic fontSize="small" />
              {"Mensagem de áudio"}
            </Typography>
          </div>
        );
      } else if (mediaType === "video") {
        return (
          <>
            <div className={classes.quotedMediaDescripton}>
              {renderContactName(!isStatus ? quotedMsg : {
                ...quotedMsg,
                contact: {
                  name: "Status"
                },
              })}
              <Typography noWrap color="textSecondary">
                {isStatus ? <WebStories fontSize="small" /> : <Videocam fontSize="small" />}
                {quotedMsg.body || "Vídeo"}
              </Typography>
            </div>
            <video
              className={classes.quotedMessageMedia}
              src={quotedMsg.mediaUrl}
              controls={isStatus}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.style.display = "none"
              }}
            />
          </>
        );
      } else if (mediaType === "image") {
        return (
          <>
            <div className={classes.quotedMediaDescripton}>
              {renderContactName(!isStatus ? quotedMsg : {
                ...quotedMsg,
                contact: {
                  name: "Status"
                },
              })}
              <Typography noWrap color="textSecondary">
                {isStatus ? <WebStories fontSize="small" /> : <Photo fontSize="small" />}
                {quotedMsg.body || "Foto"}
              </Typography>
            </div>
            {isStatus ?
              <ModalImage
                className={classes.quotedMessageMedia}
                showRotate={quotedMsg.mediaUrl}
                smallSrcSet={quotedMsg.mediaUrl}
                medium={quotedMsg.mediaUrl}
                large={quotedMsg.mediaUrl}
                alt={quotedMsg.filename || "image"}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.style.display = "none"
                }}
              /> :
              <img
                className={classes.quotedMessageMedia}
                src={quotedMsg.mediaUrl}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.style.display = "none"
                }} />
            }
          </>
        );
      } else if (mediaType === "location" && quotedMsg.body.split('|').length >= 2) {
        let locationParts = quotedMsg.body.split('|');
        let imageLocation = locationParts[0];
        let linkLocation = locationParts[1];

        let descriptionLocation = null;

        if (locationParts.length > 2)
          descriptionLocation = quotedMsg.body.split('|')[2];

        return (<>
          <div className={classes.quotedMediaDescripton}>
            {renderContactName(!isStatus ? quotedMsg : {
              ...quotedMsg,
              contact: {
                name: "Status"
              },
            })}
            <Typography noWrap color="textSecondary">
              <LocationOn fontSize="small" />
              {"Localização"}
            </Typography>
          </div>
          <img
            className={classes.quotedMessageMedia}
            src={imageLocation}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.style.display = "none"
            }}
          />
        </>);

      } else {
        const extension = quotedMsg.filename?.split('.')[1]?.toUpperCase();
        return (
          <>
            <div className={classes.quotedMediaDescripton}>
              {renderContactName(!isStatus ? quotedMsg : {
                ...quotedMsg,
                contact: {
                  name: "Status"
                },
              })}
              <Typography color="textSecondary">
                <Article fontSize="small" />
                <MarkdownWrapper>{quotedMsg.body || quotedMsg.filename}</MarkdownWrapper>
              </Typography>
            </div>
            <Avatar variant="rounded" className={classes.quotedMessageMedia}>{extension}</Avatar>
          </>
        );
      }
    }
    const contactId = message.quotedMsg?.contactId;
    const contactColor = contactColors.find((contactColor) => contactColor.contactId === contactId);
    let color;
    if (!contactColor && isGroup && !message.fromMe) {
      color = getRandomColor();
      setContactColors((prevContactList) => [...prevContactList, { contactId, color }]);
    }
    color = contactColor?.color;
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe
        })}
        onClick={(e) => !isStatus && !isOld ? scrollToMessage(message.quotedMsg) : !isStatus ? toast.info(i18n.t("messagesList.cannotScrollToMessage")) : null}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
          style={{
            backgroundColor: color
          }}
        ></span>
        <div className={classes.quotedMsg}>
          {renderLinkThumbnail(message?.quotedMsg)}
          {renderMediaTypes(message?.quotedMsg.mediaType)}
        </div>
      </div>
    )
  }
  const ReactionWithTooltip = ({ reaction }) => {
    return (
      <Tooltip title={reaction.fromMe ? (reaction.whatsapp?.name ?? "Você") : reaction.contact?.name} arrow>
        <span>{reaction.body}</span>
      </Tooltip>
    );
  };

  const renderReactions = (reaction) => {
    const reactionContainer = document.getElementById(`reaction_${reaction.quotedMsgId}`);
    const messageEl = document.getElementById(reaction.quotedMsgId);

    if (messageEl && reactionContainer) {
      reactionContainer.style.display = "flex"
      messageEl.style.marginBottom = '19px';
    }

    if (reactionContainer) {
      // Verificar se já existe uma reação igual do mesmo contato
      const existingReactions = reactionContainer.querySelectorAll(`[data-reaction-id]`);
      const existingReactionIds = Array.from(existingReactions).map((el) => el.getAttribute('data-reaction-id'));

      if (!existingReactionIds.includes(reaction.id)) {
        const newReaction = document.createElement('span');
        newReaction.setAttribute('data-reaction-id', reaction.id);
        newReaction.style.display = "flex";
        newReaction.style.flexDirection = "row";

        // Renderize o componente ReactionWithTooltip e coloque-o no div
        ReactDOM.render(<ReactionWithTooltip reaction={reaction} />, newReaction);

        // Adicionar o novo elemento ao reactionContainer
        reactionContainer.appendChild(newReaction);

        if (reactionContainer?.childElementCount >= 1) {
          reactionContainer.style.display = 'block';
        }
      } else {
        // Atualize a reação existente
        const existingReaction = reactionContainer.querySelector(`[data-reaction-id="${reaction.id}"]`);
        ReactDOM.render(<ReactionWithTooltip reaction={reaction} />, existingReaction);
      }
    }
  }

  // const renderReactions = (reaction) => {
  //   const messageEl = document.getElementById(reaction.quotedMsgId);
  //   if (messageEl) {
  //     //const existingReaction = messageEl.querySelector(`#${reaction.id}`);
  //     messageEl.style.marginBottom = "19px";
  //     return (
  //       <Popper
  //         open={!!messageEl}
  //         anchorEl={messageEl}
  //         disablePortal={true}
  //         placement={reaction.quotedMsg.fromMe ? "bottom-end" : "bottom-start"}
  //         modifiers={[
  //           {
  //             name: 'flip',
  //             enabled: false,
  //             options: {
  //               altBoundary: false,
  //               rootBoundary: 'document',
  //               padding: 8,
  //             },
  //           },
  //           {
  //             name: 'preventOverflow',
  //             enabled: false,
  //             options: {
  //               altAxis: false,
  //               altBoundary: false,
  //               tether: false,
  //               rootBoundary: 'document',
  //               padding: 8,
  //             },
  //           },
  //         ]}
  //       >

  //         <Chip className={classes.reaction} size="small" label={
  //           <Tooltip
  //             placement="top"
  //             title={reaction.fromMe ? reaction.whatsapp?.name || "Você" : reaction.contact.name}
  //           >
  //             <span id={reaction.id}>{reaction.body}</span></Tooltip>
  //         } />
  //       </Popper>
  //     )


  //   }
  // };

  const renderMessages = () => {
    const viewMessagesList = messagesList.map((message, index) => {
      if (!message.fromMe) {
        return (
          <React.Fragment key={message.id}>
            {renderDailyTimestamps(message, index)}
            {renderMessageDivider(message, index)}
            {renderNumberTicket(message, index)}
            {renderLogs(message)}
            {message.mediaType === "reaction" && renderReactions(message)}
            {message.mediaType !== "call_log" && message.mediaType !== "message_log" &&
              message.mediaType !== "reaction" && !message.mediaType?.includes("status_") && !message.mediaType?.includes("old_") &&
              <div className={classes.messageLeft} id={message.id}>
                {!message.isDeleted || (message.isDeleted && getSettingValue("showdeletedmsg") === "enabled") ?
                  <>
                    <p style={{ display: 'flex', alignItems: 'center', padding: 0, margin: 0, color: 'gray' }}>{message.isFowarded ? <><Reply style={{ fontSize: 18, transform: "scaleX(-1)" }} /><i>Encaminhada</i></> : null}</p>
                    {!message.contact?.connectionId &&
                      <IconButton
                        variant="contained"
                        size="small"
                        id="messageActionsButton"
                        disabled={message.isDeleted}
                        className={classes.messageActionsButton}
                        onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                      >
                        <ExpandMore />
                      </IconButton>
                    }
                    {isGroup && (
                      <span
                        key={index}
                        className={classes.messageContactNameLeft}
                        style={{
                          color: contactColors.find((contactColor) => contactColor.contactId === message.contactId)?.color
                        }}
                        contact={message?.contact}
                        numbers={message?.contact?.number}
                      >

                        {message.contact?.name}
                      </span>
                    )}
                    {message.quotedMsg && renderQuotedMessage(message)}
                    {message.linkData && renderLinkThumbnail(message)}
                    {(message.mediaUrl || message.mediaType === "location" || message.mediaType === "vcard"
                      || message.mediaType === "multi_vcard"
                    ) && checkMessageMedia(message)}

                    <div className={classes.messageContent} style={{ maxWidth: message.mediaUrl ? 330 : undefined }}>
                      <div
                        className={clsx(classes.textContentItem, {
                          [classes.textContentItemDeleted]: message.isDeleted || message.body.includes("MESSAGE_NOT_SUPPORTED"),
                        })}
                      >{message.isDeleted && (
                        <Block
                          color="textSecondary"
                          fontSize="large"
                          className={classes.deletedIcon}
                        />
                      )}
                        {message.body.includes("MESSAGE_NOT_SUPPORTED") && (
                          <SpeakerNotesOff
                            color="textSecondary"
                            fontSize="large"
                            className={classes.deletedIcon}
                          />
                        )}
                        <TextResumer>
                          {message.body}
                          </TextResumer>
                      </div>
                      <span className={classes.timestamp}>
                        {message.isEdited && "Editada "}
                        {format(parseISO(message.createdAt), "HH:mm")}
                      </span>
                    </div>
                    <div className={classes.reactionContainerLeft} id={`reaction_${message.id}`}>
                    </div>
                  </>
                  : <>
                    <div
                      className={clsx(classes.textContentItem, {
                        [classes.textContentItemDeleted]: message.isDeleted,
                      })}
                    >{message.isDeleted && (
                      <Block
                        color="textSecondary"
                        fontSize="large"
                        className={classes.deletedIcon}
                      />
                    )}
                      <MarkdownWrapper>{"Mensagem apagada"}</MarkdownWrapper>
                    </div>
                    <span className={classes.timestamp}>

                      {format(parseISO(message.createdAt), "HH:mm")}
                    </span>
                  </>
                }

              </div>}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={message.id}>
            {renderDailyTimestamps(message, index)}
            {renderMessageDivider(message, index)}
            {renderNumberTicket(message, index)}
            {message.mediaType === "reaction" && renderReactions(message)}
            {message.mediaType !== "reaction" && !message.mediaType?.includes("status_") &&
              !message.mediaType?.includes("old_") &&
              <div className={clsx(classes.messageRight, {
                [classes.messageRightInternal]: message.mediaType === "internal_dialog"
              })} id={message.id}>
                {!message.isDeleted || (message.isDeleted && getSettingValue("showdeletedmsg") === "enabled" && message.mediaType !== "internal_dialog") ?
                  <>
                    <p className={classes.messageInfo}>
                      {message.isFowarded ? <><Reply style={{ fontSize: 18, transform: "scaleX(-1)" }} /><i>Encaminhada</i></> : null}
                      {message.mediaType === "internal_dialog" ? <><Announcement style={{ fontSize: 18 }} /><i>Mensagem interna</i></> : null}
                    </p>
                    {!message.contact?.connectionId &&
                      <IconButton
                        variant="contained"
                        size="small"
                        id="messageActionsButton"
                        disabled={message.isDeleted}
                        className={classes.messageActionsButton}
                        onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                      >
                        <ExpandMore />
                      </IconButton>
                    }
                    {message.quotedMsg && renderQuotedMessage(message)}
                    {message.linkData && renderLinkThumbnail(message)}
                    {(message.mediaUrl || message.mediaType === "location" || message.mediaType === "vcard"
                      || message.mediaType === "multi_vcard"
                    ) && checkMessageMedia(message)}
                    <div className={classes.messageContent} style={{ maxWidth: message.mediaUrl ? 330 : undefined }}>
                      <div
                        className={clsx(classes.textContentItem, {
                          [classes.textContentItemDeleted]: message.isDeleted || message.body.includes("MESSAGE_NOT_SUPPORTED") || message.mediaType === "internal_dialog",
                        })}
                      >
                        {message.isDeleted && (
                          <Block
                            color="textSecondary"
                            fontSize="large"
                            className={classes.deletedIcon}
                          />
                        )}
                        {message.body.includes("MESSAGE_NOT_SUPPORTED") && (
                          <SpeakerNotesOff
                            color="textSecondary"
                            fontSize="large"
                            className={classes.deletedIcon}
                          />
                        )}

                        <TextResumer>
                          {message.body}
                        </TextResumer>
                      </div>
                      <span className={classes.timestamp}>
                        {message.isEdited && "Editada "}
                        {format(parseISO(message.createdAt), "HH:mm")}
                        {message.isBot && "🤖"}
                        {renderMessageAck(message)}
                      </span>

                    </div>
                    <div className={classes.reactionContainerRight} id={`reaction_${message.id}`}>
                    </div>
                  </>
                  : 
                  <>
                    <div className={classes.messageContent}>
                      <div
                        className={clsx(classes.textContentItem, {
                          [classes.textContentItemDeleted]: message.isDeleted,
                        })}
                      >{message.isDeleted && (
                        <Block
                          color="textSecondary"
                          fontSize="large"
                          className={classes.deletedIcon}
                        />
                      )}
                        <MarkdownWrapper>{message.mediaType === "internal_dialog" ? "Mensagem interna apagada" : "Mensagem apagada"}</MarkdownWrapper>
                      </div>
                      <span className={classes.timestamp}>
                        {format(parseISO(message.createdAt), "HH:mm")}
                      </span>
                    </div>
                  </>
                }

              </div>}
          </React.Fragment>
        );
      }
    });
    return viewMessagesList;
  };

  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
        connType={connType}
        isGroup={isGroup}
      />

      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
      >
        {messagesList.length > 0 ? renderMessages() : []}
        {scrollButtonVisibility && <NewMessageScrollButton />}
      </div>
      {loading && (
        <div className={classes.circleLoadingWrapper}>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}
    </div>
  );
};

export default MessagesList;