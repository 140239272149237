const generateManifestJson = () =>{
    const manifestElement = document.getElementById("dynamic-manifest");

    fetch("/manifest.json")
        .then(response => response.json())
        .then(data => {
            // Personalize o manifesto com base em variáveis de ambiente ou lógica específica
             data.short_name = process.env.REACT_APP_NAME || data.short_name;
            data.name = process.env.REACT_APP_NAME || data.name;
            
            // Converta o manifesto personalizado de volta para JSON
            const dynamicManifest = JSON.stringify(data);

            // Crie um Blob de dados JSON para o manifesto
            const blob = new Blob([dynamicManifest], { type: 'application/json' });

            // Crie uma URL de objeto para o Blob
            const manifestURL = URL.createObjectURL(blob);

            // Defina o atributo "href" do elemento do link do manifesto com a URL personalizada
            manifestElement.href = manifestURL;
        });
}
export default generateManifestJson;