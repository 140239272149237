import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import {
	Avatar,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	Popover,
	TextField,
	Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ContactModal from "../ContactModal";
import * as Yup from "yup";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import Autocomplete from '@mui/material/Autocomplete';
import { Field, Form, Formik } from "formik";
import { format, addHours } from "date-fns";
import { toast } from "react-toastify";
import AvatarTooltip from "../AvatarTooltip";
import { Info, Mood } from "@mui/icons-material";
import UploadField from "../UploadField/newUploadField";
import { Picker } from "emoji-mart";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AdvancedTextField from "../AdvancedTextField";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		flex: 1,
		//paddingBottom: "60px !important",
		"& textarea": {
			marginBottom: "30px !important",
		}
	},
	iconButton: {
		color: theme.palette.text.secondary
	},
	errorIcon: {
		color: theme.palette.secondary.main
	},
	emojiBox: {
		backgroundColor: "red"
	},
	uploadInput: {
		cursor: "default",
		minHeight: "56px",
		"& input": {
			display: "none",
		}
	},
	uploadInputHide: {
		minHeight: "56px",
		"& input": {
			cursor: "pointer",
			opacity: 0
		}
	},
	iconContainer: {
		display: "flex",
		alignItems: "center",
		maxWidth: "100%",
		marginTop: "-45px",
		padding: "5px 0px"
	},
	flexContainer: {
		display: "flex",
		alignItems: "center",
		gap: "8px",
		width: "100%"
	},
	fileNameBreaker: {
		maxWidth: "calc(100% - 10px)",
		textAlign: "center",
		wordWrap: "break-word",
		[theme.breakpoints.down('md')]: {
			maxWidth: "calc(100% - 10px)",
		},
	},
}))

const ScheduleSchema = Yup.object().shape({
	message: Yup.string().min(5, "Mensagem muito curta").required("Obrigatório"),
	contactId: Yup.number().required("Obrigatório"),
	sendAt: Yup.string()
		.required("Obrigatório")
		.test("is-after-now", "A data precisa ser maior que atual", function (
			value
		) {
			return new Date(value) > new Date();
		}),
});



const ScheduleModal = ({ modalOpen, onClose, scheduleId, contact }) => {
	const classes = useStyles();
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedContact, setSelectedContact] = useState(contact || null);
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const { user } = useContext(AuthContext);
	const messageRef = useRef({});
	const [medias, setMedias] = useState([]);
	const [openEmojis, setOpenEmojis] = useState(); // Estado dos popovers abertos

	const idEmoji = 'simple-popover';

	const textTemplates = {
		greeting: "Bom dia/Boa tarde/Boa noite",
		contactName: "Nome do contato",
		contactNumber: "Número do contato",
		contactEmail: "Email do contato",
		ticketNumber: "Número do ticket atual",
		userName: "Nome do atendente"
	}

	// Função para lidar com a abertura de um popover específico
	const handleOpenEmoji = () => {
		setOpenEmojis(true);
	};

	// Função para lidar com o fechamento de um popover específico
	const handleCloseEmoji = () => {
		setOpenEmojis(false);
	};

	const initialValues = {
		contactId: contact?.id ?? "",
		message: "",
		sendAt: format(addHours(new Date(), 1), "yyyy-MM-dd'T'HH:mm"),
		sentAt: ""
	};
	const [schedule, setSchedule] = useState(initialValues);

	useEffect(() => {
		if (contact) {
			setSelectedContact(contact);
		}
	}, [modalOpen, contact]);

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts", {
						params: { searchParam },
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);

	useEffect(() => {
		let isMounted = true; // Variável para rastrear se o componente está montado

		(async () => {
			if (!scheduleId) return;
			try {
				const { data } = await api.get(`/schedules/${scheduleId}`);
				if (isMounted) { // Verifica se o componente ainda está montado antes de atualizar o estado
					setSchedule((prevState) => ({
						...prevState,
						...data,
						sendAt: format(new Date(data.sendAt), "yyyy-MM-dd'T'HH:mm"),
					}));
					data?.mediaUrl
						? setMedias(await downloadFile(data?.mediaUrl))
						: setMedias([]);
					setSelectedContact(data.contact);
				}
			} catch (err) {
				toastError(err);
			}
		})();

		// Função de retorno de efeito para redefinir o estado quando o componente for desmontado
		return () => {
			isMounted = false;
		};
	}, [scheduleId, modalOpen]);


	async function downloadFile(url) {
		try {
			const response = await fetch(url);
			const blob = await response.blob();
			const urlParts = url.split('/');

			const fileName = urlParts[urlParts.length - 1];
			// Criar o objeto File a partir do blob do arquivo
			const file = new File([blob], fileName);

			// Definir o arquivo no elemento <input>
			return [file];
		} catch (error) {
			console.error('Erro ao fazer o download do arquivo:', error);
		}
	}

	const handleClose = () => {
		onClose();
		setMedias([]);
		setSearchParam("");
		setSelectedContact(null);
	};

	const handleSelectOption = (e, newValue) => {
		if (newValue?.number) {
			setSelectedContact(newValue);
		} else if (newValue?.name) {
			setNewContact({ name: newValue?.name });
			setContactModalOpen(true);
		}
	};

	const handleCloseContactModal = () => {
		setContactModalOpen(false);
	};

	const handleAddNewContactSchedule = (contact) => {
		setSelectedContact(contact);
		setSchedule({ ...schedule, contactId: contact?.id ?? '' });
	};
	const handleSaveSchedule = async (values) => {
		const formData = new FormData();//{ ...values, userId: user.id };
		formData.append("contactId", values.contactId);
		formData.append("message", values.message);
		formData.append("sendAt", format(new Date(values.sendAt), "yyyy-MM-dd'T'HH:mm"));
		formData.append("userId", user.id);
		if (values.messageMedia && values.messageMedia.length > 0) {
			values.messageMedia.forEach((media) => {
				formData.append("media", media);
			});
		}

		try {
			if (scheduleId) {
				await api.put(`/schedules/${scheduleId}`, formData);
			} else {
				await api.post("/schedules", formData);
			}
			toast.success(i18n.t("scheduleModal.success"));
			setSelectedContact(null);
			setSchedule(initialValues);

		} catch (err) {
			toastError(err);
		}
		handleClose();
	}

	const createAddContactOption = (filterOptions, params) => {

		if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
			filterOptions.push({
				name: `${params.inputValue}`,
			});
		}

		return filterOptions; // Retorna todas as opções sem adicionar uma nova opção
	};


	const renderOption = (props, option) => {
		if (option.number) {
			return (
				<li {...props}>
					{option.company || option.extraInfo?.length > 0 ?
						<AvatarTooltip
							arrow
							icon={<Info />}
							placement="right"
							title={
								<>
									{option.company &&
										<Typography>
											{`Empresa: ${option.company}`}
										</Typography>
									}
									{option.extraInfo?.length > 0 && option.extraInfo.map((info) =>
										<Typography>
											{`${info.name}: ${info.value}`}
										</Typography>
									)}
								</>
							}
						>
							<Avatar src={option.profilePicUrl} />
						</AvatarTooltip>
						: <Avatar src={option.profilePicUrl} />
					}
					<Typography
						noWrap
						component="span"
						variant="body2"
						color="textPrimary"
						style={{ marginLeft: "3px" }}
					>
						{option.company ?
							<>
								{option.name}
								<Typography
									component="span"
									color="textSecondary"
								>
									{` (${option.company}) `}
								</Typography>

							</>
							: option.name
						} {" - "} {option.number}
					</Typography>
				</li>
			);
		} else {
			return (<li {...props}>{`${i18n.t("newTicketModal.add")} ${option.name}`}</li>);
		}
	};
	const renderOptionLabel = option => {

		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${option.name}`;
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<div className={classes.root}>
				<ContactModal
					open={contactModalOpen}
					initialValues={newContact}
					onClose={handleCloseContactModal}
					onSave={handleAddNewContactSchedule}
				></ContactModal>
				<Dialog
					open={modalOpen}
					onClose={handleClose}
				>
					<DialogTitle
						color="textPrimary"
						id="form-dialog-title"
					>
						{scheduleId ? `Editar agendamento` : `Agendar mensagem`}
					</DialogTitle>
					<Formik
						initialValues={schedule}
						enableReinitialize={true}
						validationSchema={ScheduleSchema}
						onSubmit={(values, actions) => {
							setTimeout(() => {
								handleSaveSchedule(values);
								actions.setSubmitting(false);
							}, 400);
						}}
					>
						{({ touched, errors, isSubmitting, values }) => (
							<Form>
								<DialogContent dividers>
									<FormControl
										variant="outlined"
										fullWidth
									>

										<Autocomplete
											options={options}
											loading={loading}
											clearOnBlur
											autoHighlight
											freeSolo
											name="contactId"
											clearOnEscape
											value={selectedContact}
											getOptionLabel={renderOptionLabel}
											renderOption={renderOption}
											filterOptions={createAddContactOption}
											onChange={(e, contact) => {
												const contactId = contact ? contact.id : '';
												setSchedule({ ...schedule, contactId });
												handleSelectOption(e, contact ? contact : null);
												setSelectedContact(contact ? contact : null);
											}}
											renderInput={params => (
												<TextField
													{...params}
													label={i18n.t("newTicketModal.fieldLabel")}
													variant="outlined"
													autoFocus
													error={
														touched.contactId && Boolean(errors.contactId)
													}
													helperText={
														touched.contactId && errors.contactId
													}
													onChange={e => setSearchParam(e.target.value)}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{loading ? (
																	<CircularProgress color="inherit" size={20} />
																) : null}
																{params.InputProps.endAdornment}
															</React.Fragment>
														),
													}}
												/>
											)}
										/>

									</FormControl>
									<Field
										as={AdvancedTextField}
										selectTemplates={true}
										label={i18n.t("campaignModal.form.message")}
										type="message"
										multiline
										rows={4}
										margin="dense"
										id="message"
										name="message"
										fullWidth
										onChange={(value) => values.message = value}
										className={classes.textField}
										variant="outlined"
										error={touched.message && Boolean(errors.message)}
										templates={textTemplates}
										value={values.message}
									/>
									<Field
										id={`schedule-upload`}
										name={`messageMedia`}
										className={classes.mediaField}
										label={`Anexar Arquivo`}
										as={UploadField}
										margin="dense"
										value={""}
										// value={values[`messageMedia${i == 1 ? "" : i}`]}
										onChange={(media) => (values.messageMedia = media)}
										onClear={() => (values.messageMedia = [])}
										previewUrl={values.mediaUrl}
										fullWidth
									/>
									<Field
										as={DateTimePicker}
										label={i18n.t("scheduleModal.form.sendAt")}
										type="datetime-local"
										format="dd/MM/yyyy HH:mm"
										value={new Date(values.sendAt)}
										ampm={false}
										disablePast
										onChange={(date) => values.sendAt = format(new Date(date), "yyyy-MM-dd'T'HH:mm")}
										slotProps={{
											textField: {
												fullWidth: true,
												margin: "dense",
												error: touched.sendAt && Boolean(errors.sendAt),
												helperText: touched.sendAt && errors.sendAt ? errors.sendAt : ""
											}
										}}
										InputLabelProps={{
											shrink: true,
										}}
									/>

								</DialogContent>
								<DialogActions>
									<Button
										onClick={handleClose}
										color="secondary"
										disabled={isSubmitting}
										variant="outlined"
									>
										{i18n.t("scheduleModal.buttons.cancel")}
									</Button>
									<Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										variant="contained"
										className={classes.btnWrapper}
									>
										{scheduleId
											? `${i18n.t("scheduleModal.buttons.okEdit")}`
											: `${i18n.t("scheduleModal.buttons.okAdd")}`}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>
								</DialogActions>
							</Form>
						)}
					</Formik>


				</Dialog>
			</div>
		</LocalizationProvider>

	)
}
export default ScheduleModal;