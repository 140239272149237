import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import Tickets from "../pages/Tickets/";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Schedules from "../pages/Schedules/";
import Queues from "../pages/Queues/";
import Changelog from "../pages/Changelog";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";
import Campaigns from "../pages/Campaigns";
import Tags from "../pages/Tags";
import ControlPanel from "../pages/ControlPanel";
import Categories from "../pages/Categories";
import FileList from "../pages/FileList";
import Integrations from "../pages/Integrations";
import Reviews from "../pages/Reviews";

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Route exact path="/" component={Dashboard} isPrivate />
              <Route exact path="/tickets/:ticketId?" component={Tickets} isPrivate />
              <Route exact path="/connections" component={Connections} isPrivate />
              <Route exact path="/contacts" component={Contacts} isPrivate />
              <Route exact path="/schedules" component={Schedules} isPrivate />
              <Route exact path="/users" component={Users} isPrivate />
              <Route exact path="/quickanswers" component={QuickAnswers} isPrivate />
              <Route exact path="/changelog" component={Changelog} isPrivate />
              <Route exact path="/settings" component={Settings} isPrivate />
              <Route exact path="/queues" component={Queues} isPrivate />
              <Route exact path="/campaigns" component={Campaigns} isPrivate />
              <Route exact path="/tags" component={Tags} isPrivate />
              <Route exact path="/categories" component={Categories} isPrivate />
              <Route exact path="/adminpanel" component={ControlPanel} isPrivate />
              <Route exact path="/filelist" component={FileList} isPrivate />
              <Route exact path="/integrations" component={Integrations} isPrivate />
              <Route exact path="/reviews" component={Reviews} isPrivate />
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
