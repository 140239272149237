import toastError from "../../errors/toastError";
import api from "../../services/api";

const useQueues = () => {
	const findAll = async () => {
        try{
            const { data } = await api.get("/queue");
            return data;
        }catch(err){
            toastError(err)
        }
    }

	return { findAll };
};

export default useQueues;
