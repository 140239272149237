import React, { useState } from "react";

import makeStyles from '@mui/styles/makeStyles';
import Drawer from "@mui/material/Drawer";


const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        display: "flex",
        // borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        // borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        // borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        // borderTopRightRadius: 4,
        // borderBottomRightRadius: 4,
    },
}));

const TicketDrawer = ({ children, open }) => {
    const classes = useStyles();

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={open}
            PaperProps={{ style: { position: "absolute" } }}
            BackdropProps={{ style: { position: "absolute" } }}
            ModalProps={{
                container: document.getElementById("drawer-container"),
                style: { position: "absolute" },
            }}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            {children}
        </Drawer>
    );
};

export default TicketDrawer;
