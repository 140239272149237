const messages = {
  es: {
    translations: {
      errors: {
        required: "Obligatorio",
      },
      signup: {
        title: "Registro",
        toasts: {
          success:
            "¡El usuario ha sido creado satisfactoriamente! ¡Ahora inicia sesión!",
          fail: "Error al crear el usuario. Verifica los datos reportados.",
        },
        form: {
          name: "Nombre",
          email: "Correo Electrónico",
          password: "Contraseña",
        },
        buttons: {
          submit: "Regístrate",
          login: "¿Ya tienes una cuenta? ¡Inicia sesión!",
        },
      },
      login: {
        title: "Inicio de Sesión",
        form: {
          email: "Correo Electrónico",
          password: "Contraseña",
        },
        buttons: {
          submit: "Ingresa",
          register: "¿No tienes cuenta? ¡Regístrate!",
        },
      },
      auth: {
        toasts: {
          success: "¡Inicio de sesión exitoso!",
        },
      },
      dashboard: {
        title: "Dashboard",
        filters: {
          startDate: "Fecha de início",
          endDate: "Fecha límite",
          status: "Status",
        },
        errors: {
          dateMax: "No puedes seleccionar una fecha de inicio mayor que la de término",
          dateMin: "No puedes seleccionar una fecha de término menor que la de inicio",
          generic: "¡Ocurrió un error!",
        },
        charts: {
          name: {
            title: "Nombre",
          },
          ticketCount: {
            title: "Tickets",
          },
          perDay: {
            title: "Tickets hoy: ",
          },
          perHour: {
            title: "Tickets por hora",
          },
          perUser: {
            title: "Atenciones por Usuarios",
          },
          perContact: {
            title: "Atenciones por Contactos",
          },
          perQueue: {
            title: "Atenciones por Colas",
          },
          meanMedian: {
            mean: "Tiempo promedio de espera de los tickets",
            median: "Mediana",
            groupWarning: "El conteo no se aplica a grupos.",
          },
        },
        dataGrid: {
          noRowsLabel: 'Sin información',
          toolbarDensityLabel: 'Size',
          toolbarDensityCompact: 'Small',
          toolbarDensityStandard: 'Medium',
          toolbarDensityComfortable: 'Large',
        },
        messages: {
          inAttendance: {
            title: "En servicio"
          },
          waiting: {
            title: "Esperando"
          },
          closed: {
            title: "Finalizado"
          },
          total: {
            title: "Total",
          },
          noQueue: {
            title: "Sin cola",
          },
          unanswered: {
            title: "No atendido",
          },
        },
      },
      connections: {
        title: "Conexiones",
        toasts: {
          deleted:
            "¡La conexión de WhatsApp ha sido borrada satisfactoriamente!",
        },
        confirmationModal: {
          deleteTitle: "Borrar",
          deleteMessage: "¿Estás seguro? Este proceso no puede ser revertido.",
          disconnectTitle: "Desconectar",
          disconnectWppMessage:
            "Estas seguro? Deberá escanear el código QR nuevamente.",
          disconnectFbMessage: "Estás seguro? Deberá volver a leer el código QR",
        },
        buttons: {
          add: "Agrega WhatsApp",
          disconnect: "Desconectar",
          tryAgain: "Inténtalo de nuevo",
          qrcode: "QR CODE",
          newQr: "Nuevo QR CODE",
          connecting: "Conectando",
          whatsapp: "Whatsapp",
          altconn: "Conexión alternativa"
        },
        toolTips: {
          disconnected: {
            title: "No se pudo iniciar la sesión de WhatsApp",
            content:
              "Asegúrese de que su teléfono celular esté conectado a Internet y vuelva a intentarlo o solicite un nuevo código QR",
          },
          disconnectedfb: {
            title: "Error al iniciar sesión en Facebook",
            content:
              "Intente conectarse nuevamente, si no es posible, realize la autenticación nuevamente",
          },
          qrcode: {
            title: "Esperando la lectura del código QR",
            content:
              "Haga clic en el botón 'CÓDIGO QR' y lea el Código QR con su teléfono celular para iniciar la sesión",
          },
          connected: {
            title: "Conexión establecida!",
          },
          timeout: {
            title: "Se perdió la conexión con el teléfono celular",
            content:
              "Asegúrese de que su teléfono celular esté conectado a Internet y que WhatsApp esté abierto, o haga clic en el botón 'Desconectar' para obtener un nuevo código QR",
          },
        },
        table: {
          name: "Nombre",
          status: "Estado",
          lastUpdate: "Última Actualización",
          default: "Por Defecto",
          actions: "Acciones",
          session: "Sesión",
        },
      },
      whatsappModal: {
        title: {
          add: "Agrega {{connType}}",
          edit: "Edita {{connType}}",
        },
        form: {
          name: "Nombre",
          default: "Por Defecto",
          history: "Importar historial al conectar",
          historyStart: "Fecha de inicio de la importación",
          historyEnd: "Fecha de finalización de la importación",
          farewellMessage: "Mensaje de despedida",
          awayMessage: "Mensaje de ausencia de asistente",
          isMultidevice: "Multidevice",
          transferTicketMessage: "Mensaje de Transfencia de Ticket",
          reopenLastTicket: "Reabrir Ultimo Ticket",
          minutes: "Minutos",
          newticketafter: "Generar un Nuevo Ticket después",
          titlenewticketafter: "Al recibir un mensaje",
          aiMode: "Modo IA",
          queueMode: "Modo de colas",
          integratedAi: "Utilizar IA Integrada",
          integratedMovidesk: "Utilizar integración con Movidesk",
          reviewMessage: "Mensaje de evaluación post-atención",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "WhatsApp guardado satisfactoriamente.",
      },
      qrCode: {
        message: "Lée el código QR para iniciar la sesión.",
      },
      contacts: {
        title: "Contactos",
        toasts: {
          deleted: "¡Contacto borrado satisfactoriamente!",
        },
        searchPlaceholder: "Buscar...",
        confirmationModal: {
          deleteTitle: "Borrar",
          importTitlte: "Importar contactos",
          deleteMessage:
            "¿Estás seguro que deseas borrar este contacto? Todos los tickets relacionados se perderán.",
          importMessage:
            "¿Quieres importar todos los contactos desde tu teléfono?",
        },
        buttons: {
          import: "Importar Contactos",
          add: "Agregar Contacto",
        },
        table: {
          name: "Nombre",
          whatsapp: "WhatsApp",
          company: "Empresa",
          email: "Correo Electrónico",
          extraInfo: "Info Extra",
          actions: "Acciones",
        },
      },
      contactModal: {
        title: {
          add: "Agregar contacto",
          edit: "Editar {{type}}",
        },
        form: {
          mainInfo: "Detalles del {{type}}",
          extraInfo: "Información adicional",
          name: "Nombre",
          number: "Número de Whatsapp",
          company: "Empresa",
          email: "Correo Electrónico",
          obs: "Observaciones",
          extraName: "Nombre del Campo",
          extraValue: "Valor",
          movideskPersonId: "ID de cliente de Movidesk"

        },
        buttons: {
          addExtraInfo: "Agregar información",
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "{{type}} guardado satisfactoriamente.",
        group: "grupo",
        contact: "contacto"
      },
      quickAnswersModal: {
        title: {
          add: "Agregar respuesta rápida",
          edit: "Editar respuesta rápida",
        },
        form: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Respuesta rápida guardada correctamente.",
      },
      fileListModal: {
        title: {
          edit: "Editar lista de archivos {{name}}",
          add: "Agregar lista de archivos",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
          addFile: "Agregar archivo"
        },
        form: {
          name: "Nombre",
          fileName: "Archivo seleccionado",
          noFileName: "Ningún archivo seleccionado"
        },
        success: "Lista de archivos guardada exitosamente!."
      },
      queueModal: {
        title: {
          add: "Agregar cola",
          edit: "Editar cola",
        },
        form: {
          name: "Nombre",
          color: "Color",
          greetingMessage: "Mensaje de saludo",
          transferQueueMessage: "Mensaje de Transferencia entre Sectores",
          outOfWorkMessage: "Personaliza el mensaje cuando estés fuera del horario de oficina",
          awayMessage: "Mensaje de ausencia de asistente",
          startWorkHour: "Expediente: Apertura",
          endWorkHour: "Expediente: Cierre",
          monday: "Lunes",
          tuesday: "Martes",
          wednesday: "Miércoles",
          thursday: "Jueves",
          friday: "Viernes",
          saturday: "Sábado",
          sunday: "Domingo",
          holiday: "Feriado",
          token: "Token",
          checkHourExpedient: "Consultar horario de oficina",
          aiMode: "Modo IA",
          optionMode: "Modo de opciones",
        },
        buttons: {
          okAdd: "Añadir",
          okEdit: "Ahorrar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Agregar usuario",
          edit: "Editar usuario",
        },
        form: {
          title: "Datos del agente para la integración con movidesk: {{movideskName}}",
          name: "Nombre",
          email: "Correo Electrónico",
          password: "Contraseña",
          profile: "Perfil",
          whatsapp: "Conexión estándar",
          agentId: "ID del agente",
          agentTeam: "Equipo del agente"
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Usuario guardado satisfactoriamente.",
      },
      chat: {
        noTicketMessage: "Selecciona un ticket para empezar a chatear.",
      },
      uploads: {
        titles: {
          titleUploadMsgDragDrop: "ARRASTRA Y SUELTA LOS ARCHIVOS HACIA EL CAMPO DE ABAJO",
          titleFileList: "Lista de archivo(s)"
        },
        buttons: {
          okAdd: "Añadir",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Etiqueta guardada exitosamente.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Nuevo",
        },
      },
      ticketsFilter: {
        title: "Filtros",
        filters: {
          queues: "Colas",
          tags: "Tags",
          connections: "Conexiones"
        }
      },
      tickets: {
        toasts: {
          deleted: "El ticket en el que estabas ha sido borrado.",
        },
        notification: {
          message: "Mensaje de",
        },
        tabs: {
          open: { title: "Bandeja" },
          closed: { title: "Resueltos" },
          search: { title: "Buscar" },
        },
        search: {
          placeholder: "Buscar tickets y mensajes.",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Escriba para buscar usuarios",
        fieldQueueLabel: "Transferir a la cola",
        fieldConnectionLabel: "Transferir a la conexión",
        fieldQueuePlaceholder: "Seleccione una cola",
        fieldConnectionPlaceholder: "Seleccione una conexión",
        noOptions: "No se encontraron usuarios con ese nombre",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Cola",
        assignedHeader: "Atendiendo",
        noTicketsTitle: "¡Nada aquí!",
        noTicketsMessage:
          "No se encontraron tickets con este estado o término de búsqueda",
        connectionTitle: "Conexión que se está utilizando actualmente.",
        noUser: "Ninguno",
        noQueue: "Sin cola",
        buttons: {
          accept: "Acceptar",
        },
        badge: {
          closed: "Cerrado",
          expired: "Expirado"
        },
      },
      newTicketModal: {
        title: "Crear Ticket",
        fieldLabel: "Escribe para buscar un contacto",
        queueLabel: "Selecionar cola",
        connectionLabel: "Seleccionar conección",
        add: "Añadir",
        buttons: {
          ok: "Guardar",
          cancel: "Cancelar",
        },
      },
      ticketQueueSelect: {
        title: "Definir una fila para el ticket",
        fieldQueueLabel: "Seleccionar cola",
        buttons: {
          ok: "Confirmar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          campaigns: "Campañas",
          connections: "Conexiones",
          tickets: "Tickets",
          contacts: "Contactos",
          quickAnswers: "Respuestas rápidas",
          fileList: "Listas de archivos",
          queues: "Colas & Chatbot",
          schedules: "Horarios",
          tags: "Tags",
          categories: "Categorías",
          administration: "Administración",
          users: "Usuarios",
          settings: "Configuración",
          agenda: "Agenda",
          integrations: "Integraciones",
          reviews: "Evaluaciones",
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Cerrar Sesión",
          },
        },
      },
      notifications: {
        noTickets: "Sin notificaciones.",
      },
      schedules: {
        title: "Agendamientos",
        confirmationModal: {
          deleteTitle:
            "¿Estás seguro de que deseas borrar este horario?",
          deleteMessage: "Esta acción no se puede deshacer.",
        },
        table: {
          user: "Remetente",
          contact: "Contacto",
          body: "Mensaje",
          sendAt: "Fecha de Agendamiento",
          sentAt: "Fecha de Envio",
          status: "Status",
          statusTypes: {
            pending: "Pendiente",
            sent: "Enviado",
            error: "Error",
          },
          actions: "Acciones",
        },

        buttons: {
          add: "Nuevo",
        },
        toasts: {
          deleted: "Agendamiento eliminado correctamente.",
        }
      },
      scheduleModal: {
        title: {
          add: "Nuevo Agendaminto",
          edit: "Editar Agendamiento",
        },
        form: {
          body: "Mensaje",
          contact: "Contacto",
          sendAt: "Fecha de Agendamiento",
          sentAt: "Fecha de Envio"
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Agendamiento guardado con éxito.",
      },
      queues: {
        title: "Colas",
        table: {
          name: "Nombre",
          color: "Color",
          greeting: "Mensaje de saludo",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar cola",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
            "¿Estás seguro? ¡Esta acción no se puede revertir! Los tickets en esa cola seguirán existiendo, pero ya no tendrán ninguna cola asignada.",
        },
        queueSaved: "Cola guardada con éxito",
        queueDeleted: "Cola eliminada satisfactoriamente"
      },
      chatbots: {
        title: "Opciones",
        message: "Mensaje",
        confirmationModal: {
          deleteTitle: "Excluir {{option}}?",
          deleteMessage: "¿Estás seguro? Todas las opciones internas también serán eliminadas.",
        },
        tips: {
          mainTip: {
            title: "Agregar opciones para construir un chatbot",
            message: "En caso de existir solamente una opción la misma estará por defecto, siendo utilizada por el chatbot seguirá al paso siguiente.",
          },
          optionTip: {
            title: "Menmsaje obligatoria para seguir a la próxima opción",
            message: "Si no exixte un mensaje definido, el bot no seguirá a la opción siguiente",
          }
        },
        addButton: "Agregar opción",
        chatbotDeleted: "Opción eliminada correctamente",
        chatbotSaved: "Opción guardada con éxito"
      },
      queueSelect: {
        inputLabel: "Colas",
      },
      quickAnswers: {
        title: "Respuestas rápidas",
        table: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar respuesta rápida",
        },
        toasts: {
          deleted: "Respuesta rápida eliminada correctamente",
        },
        searchPlaceholder: "Buscar ...",
        confirmationModal: {
          deleteTitle:
            "¿Está seguro de que desea eliminar esta respuesta rápida?",
          deleteMessage: "Esta acción no se puede deshacer.",
        },
      },
      integrations: {
        title: "Integraciones",
        table: {
          name: "Nombre",
          type: {
            title: "Tipo",
            unknown: "Desconocido",
            openai: "OpenAi",
            movidesk: "Movidesk",
            api: "API",
            webhook: "Webhook"
          },
          status: {
            title: "Estado",
            unknown: "Desconocido",
            active: "Activo",
            alert: "Alerta",
            inactive: "Inactivo"
          },
          actions: "Acciones",
          openai: {
            usedTokens: "Tokens usados: {{count}}",
            promptTokens: "Prompt tokens: {{count}}",
            completionTokens: "Completion tokens: {{count}}",
            totalTokens: "Total tokens: {{count}}"
          }
        },
        deleteConfirmationModal: {
          title: 'Borrar integración',
          warning: '¿Estás seguro de que quieres eliminar esta integración? ¡Esta acción no se puede deshacer! ',
          deleteSuccess: 'Integración borrada con éxito'
        },

        searchPlaceholder: "Buscar...",
        buttons: {
          create: "Agregar integración"
        }
      },
      integrationModal: {
        title: {
          add: "Agregar integración",
          edit: "Editar integración"
        },
        form: {
          type: "Tipo",
          name: "Nombre",
          apiKey: "Clave API",
          model: "Modelo",
          instructions: "Instrucciones",
          assName: "Suscripción de asistente virtual",
          temperature: "Temperatura",
          maxTokens: "Límite de token en respuesta",
          historyLimit: "Máximo de mensajes en el historial",
          onlyTicket: "Sólo historial del ticket",
          options: {
            api: "API",
            webhook: "Webhook",
            openai: "OpenAi",
            movidesk: "Movidesk",
          }
        },
        success: "Integración guardada exitosamente",
        buttons: {
          okAdd: "Agregar",
          okEdit: "Ahorrar",
          cancel: "Cancelar"
        }
      },
      users: {
        title: "Usuarios",
        table: {
          name: "Nombre",
          email: "Correo Electrónico",
          profile: "Perfil",
          whatsapp: "Conexión estándar",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar usuario",
          editOpeningHours: "Editar horarios"
        },
        toasts: {
          deleted: "Usuario borrado correctamente.",
        },
        confirmationModal: {
          deleteTitle: "Borrar",
          deleteMessage:
            "Toda la información del usuario se perderá. Los tickets abiertos de este usuario se moverán a la cola.",
        },
      },
      settings: {
        success: "Configuración guardada satisfactoriamente.",
        title: "Configuración",
        theme: {
          name: "Tema",
          options: {
            dark: "Oscuro",
            light: "Claro",
          },
        },
        notifications: {
          name: "Sonido de notificaciones",
          options: {
            enabled: "Activado",
            disabled: "Desactivado",
          },
        },
        timeCreateNewTicket: {
          name: "Mensaje de bienvenida posterior a ",
          note: "Seleccione el tiempo necesario para abrir un nuevo ticket si el cliente vuelve a contactar",
          options: {
            "10": "10 Segundos",
            "30": "30 Segundos",
            "60": "1 minuto",
            "300": "5 minutos",
            "1800": "30 minutos",
            "3600": "1 hora",
            "7200": "2 horas",
            "21600": "6 horas",
            "43200": "12 horas",
            "86400": "24 horas",
            "172800": "48 horas",
          },
        },
        call: {
          name: "Aceptar llamadas",
          options: {
            enabled: "Activado",
            disabled: "Desactivado",
          },
        },
        userCreation: {
          name: "Creación de usuarios",
          options: {
            enabled: "Activado",
            disabled: "Desactivado",
          },
        },
        anonymoustickets: {
          name: "Tickets pendientes anónimos",
          options: {
            enabled: "Activado",
            disabled: "Desactivado",
          },
        },
        acceptonlyfirst: {
          name: "Aceptar apenas el primer ticket",
          options: {
            enabled: "Activado",
            disabled: "Desactivado",
          },
        },
        groupsasticket: {
          name: "Tratar a los grupos como tickets",
          options: {
            enabled: "Activado",
            disabled: "Desactivado",
          },
        },
        reopenlastgroupticket: {
          name: "Reabrir el último ticket cerrado del grupo",
          options: {
            enabled: "Activado",
            disabled: "Desactivado",
          },
        },
        seeAllTickets: {
          name: "Switch mostrar todos para usuários",
          options: {
            enabled: "Activado",
            disabled: "Desactivado",
          },
        },
        requireticketsubject: {
          name: "Requerir asunto del ticket",
          options: {
            enabled: "Activado",
            disabled: "Desactivado",
          },
        },
        showfarewellmessageswitch: {
          name: "Mostrar interruptor de mensaje de despedida",
          options: {
            enabled: "Activado",
            disabled: "Desactivado",
          },
        },
        showreviewmessageswitch: {
          name: "Mostrar interruptor de mensaje de evaluación",
          options: {
            enabled: "Activado",
            disabled: "Desactivado",
          },
        },
        privateticket: {
          name: "Tickets privados",
          options: {
            disabled: "Desactivado",
            forusers: "Para usuarios",
            forqueues: "Para colas",
          },
        },
        pendingticketorder: {
          name: "Ordenar tickets esperando",
          options: {
            lastmsg: "Último mensaje",
            createdAt: "Por entrada"
          }
        },
        transfermsg: {
          name: "Mensaje de transferência",
          options: {
            enabled: "Activado",
            simplified: "Simplificado",
            disabled: "Desactivado",
          },
        },
        simpletransfermsg: {
          name: "Mensaje de transferencia simplificada",
          options: {
            enabled: "Activado",
            disabled: "Desactivado",
          },
        },
        showtransferswitch: {
          name: "Mostrar switch de transferencia para usuarios",
          options: {
            enabled: "Activado",
            disabled: "Desactivado",
          },
        },
        openticketorder: {
          name: "Ordenar tickets en atendiendo",
          options: {
            lastmsg: "Ultimo mensaje",
            createdAt: "Por entrada"
          }
        },
        externalmsgsend: {
          title: "Envio externo de mensajes",
          obs: "(En el caso de no haber ticket abierto)",
          options: {
            setpending: "Abrir ticket en espera",
            setclosed: "Abrir ticket como cerrado",
            setlastclosed: "Enviar para último ticket cerrado",
          }
        },
        showdeletedmsg: {
          name: "Mostrar contenido de mensajes borradas",
          options: {
            enabled: "Activado",
            disabled: "Descativado"
          }
        },
        general: {
          title: "General"
        },
        integration: {//settings.integration.externalmsgsend
          title: "Integración",
          description: "Información necesaria para la integración con el sistema",
          apiUrl: "Solicitud de URL",
          headers: "Headers",
          jsonFormat: "Formato JSON",
          connections: "ID de conexiones",
          examples: {
            number: "NÚMERO_DE_CONTACTO",
            body: "MENSAGE",
            whatsappId: "ID_DE_LA_CONEXIÓN",
          }
        },
        helpers: {
          userCreation: "Activa/Desactiva la creación de usuarios en la pantalla de registro",
          acceptCalls: `Activado: Cuando un contacto realiza una llamada y la misma no es contestada, se enviará un mensaje.
          Desactivado: cuando un contacto realiza una llamada la misma será rechazada automáticamente y se enviará un mensaje.`,
          transferMsg: `Habilitado: habilita la mensajería al transferir un contacto a otra cola, conexión y/o usuario.\n
                        Simplificado: habilite la mensajería al transferir un contacto a otra cola, conexión y/o usuario. El mensaje de transferencia solo informará al asistente y a la cola que el contacto fue transferido.\n
                        Deshabilitado: deshabilita los mensajes al transferir un contacto a otra cola, conexión y/o usuario.`,
          simpletransfermsg: "El mensaje de transferencia solo informará al operador y a la cola que el contacto fue transferido",
          showtransferswitch: "Activa/Desactiva la visualización del interruptor que permite desactivar el mensaje de transferencia al transferir un ticket. Esta acción solo se aplica a los usuarios, los administradores siguen pudiendo ver el cambio.",
          externalSend: {
            title: "Cualquier mensaje que se envíe a través de las campañas, o directamente a través de whatsapp, o a través de la API será tratado según esta configuración.",
            opt1: "Abrir ticket en espera -> Cuando envías una campaña a un contacto y no tiene ningún ticket abierto/pendiente, se creará un nuevo ticket que caerá en la pestaña de espera.",
            opt2: "-Abrir ticket como cerrado -> Si envías una campaña a un contacto y no tiene ningún ticket abierto/pendiente, se creará un ticket nuevo que pasará directamente a ticket cerrado.",
            opt3: "-Enviar al último ticket cerrado -> Si envías una campaña a un contacto y no tiene ningún ticket abierto/pendiente, el mensaje aparecerá en el último ticket cerrado.",
          },
          showdeletedmsg: "Eliminar un mensaje mantiene el contenido del mensaje visible para los usuarios",
          anonymousTickets: "Los boletos en la pestaña de espera mantienen oculta la información de contacto, y solo son visibles la hora de envío, la conexión y la cola. Sólo los usuarios con permisos de administrador pueden ver la información de contacto.",
          acceptonlyfirst: "Sólo el primer ticket en la pestaña de espera tiene un botón de aceptar. Sólo los usuarios con permiso de administrador pueden aceptar cualquier ticket.",
          privateticket: "Los usuarios solo pueden ver el ticket que se les ha asignado. Solo el administrador podrá ver todos los tickets haciendo clic en el interruptor 'Todos'.\n Desactiva automáticamente el interruptor \"mostrar todo a los usuarios\"",
          seeAllTickets: "Los usuarios pueden ver el interruptor para mostrar todos los tickets de las colas que posee.",
          pendingticketorder: "Ordenar las entradas en la pestaña de espera",
          openticketorder: "Ordenar los tickets en la pestaña de publicación."
        }
      },
      campaigns: {
        title: 'Campañas',
        buttons: {
          send: 'Enviar',
          create: 'Crear campaña',
          add: 'Agregar',
          cancel: 'Cancelar',
          edit: 'Guardar',
        },
        sendConfirmationModal: {
          title: 'Estás seguro que deseas enviar esta campaña',
          warning: '¡Atención! El uso de campañas de mensajería masiva puede provocar el bloqueo de tu número de WhatsApp. Esto se debe a que la aplicación prohíbe los mensajes masivos para proteger a los usuarios del spam y el abuso.',
          sentSuccess: 'Campanha enviada com sucesso!',
        },
        deleteConfirmationModal: {
          title: 'Eliminar campaña',
          warning: '¿Está seguro de que desea eliminar esta campaña? ¡Esta acción no se puede revertir! La información sobre el historial de envíos no se eliminará.',
          deleteSuccess: 'Campaña eliminada correctamente'
        },
        contactsModal: {
          title: 'Agregarar contactos a la campaña {{name}}',
          buttons: {
            unselectAll: 'Desmarcar todos',
            selectMax: 'Seleccionar primeros {{max}} contactos listados'
          },
          notices: {
            updateSuccess: 'Lista de contactos de la campaña ha sido atualizada!',
            maxSelect: '¡Puedes seleccionar un máximo de {{max}} contactos por campaña!',
            selecteds: 'Contatos seleccionados',
            sents: 'Contactos enviados',
            alrdSent: 'Ya enviado!',

          },
          selecteds: 'Seleccionados',
          sents: 'Enviados',
        },
        table: {
          name: 'Nombre',
          status: 'Estado',
          sendAt: 'Fecha de envío',
          messages: 'Mensajes',
          message: "Mensaje",
          whatsapp: 'Whatsapp',
          connection: 'Conexión',
          contacts: 'Contactos',
          lastSent: 'Último envío',
          neverSent: 'Nunca enviada',
          actions: 'Acciones',
          statusTypes: {
            pending: "Pendiente",
            sending: "Enviando",
            sent: "Completado",
            error: "Erro",
          },
        },
      },
      campaignModal: {
        form: {
          name: "Nombre",
          sendAt: "Fecha de envío",
          connection: 'Conexión',
          message: "Mensaje",
          min: "Mínimo",
          max: "Máximo",
          mean: "Médio",
          start: "Inicio",
          end: "Fin",
          interval: "Intervalo de envío entre mensajes:",
          restrictTime: "No enviar entre:",
          estimatedTime: "Tiempo estimado de envío para {{count}} contactos"
        },
        title: {
          edit: 'Editar campaña {{name}}',
          add: 'Agregar campaña'
        },
        contacts: {
          selecteds: "Selecionados",
          sents: "Enviados",
          buttons: {
            selectMax: "Seleccionar más {{max}} contactos",
            unselectAll: "Desmarcar todos"
          },
          notices: {
            maxSelect: "¡Puedes seleccionar un máximo de {{max}} contactos por campaña!",
            showingResults: "Mostrando {{count}} de {{total}} contactos",
            selecteds: "Contactos seleccionados",
            sents: "Contactos enviados",
            alrdSent: "Ya enviado",
          },
        },

        notices: {
          warning: 'Advertencia: El uso excesivo de campañas de mensajería puede bloquear tu número en WhatsApp. Evita el abuso y el spam.',
          sendLocked: 'ENVIO BLOQUEADO! -- Puedes enviar solamente UNA campaña em intervalos de 3 horas!',
          sendUnlocked: 'ENVIO LIBERADO! -- Puedes enviar solamente UNA campaña en intervalos de 3 horas!',
          noContacts: 'No existe ningún contacto en la campanha!',
          helper: "Los mensajes se envían aleatoriamente a los contactos. El mensaje aparecerá de la siguiente manera para el contacto: '¡Hola, buenos días, {NOMBRE DEL CONTACTO}! {MENSAJE 1,2 O 3}'.",
        },
      },
      tags: {
        title: 'Tags',
        buttons: {
          create: 'Agregar Tag',
          add: 'Agregar',
          cancel: 'Cancelar',
          edit: 'Guardar',
        },
        deleteConfirmationModal: {
          title: 'Eliminar tag',
          warning: '¿Estás seguro de que deseas eliminar esta etiqueta? ¡Esta acción no se puede revertir!',
          deleteSuccess: 'Tag eliminada correctamente'
        },
        contactsModal: {
          title: 'Agregar tag {{tagName}} a contatos',
          buttons: {
            unselectAll: 'Desmarcar todos',
          },
          notices: {
            updateSuccess: '¡Lista de contactos de la tag fué actualizada!',
            selecteds: 'Contactos seleccionados',
          },
        },
        tagModal: {
          title: {
            edit: 'Editar tag',
            add: 'Agregar tag',
          },
          notices: {
            addSuccess: 'Tag agregada exitosamente',
          }

        },
        form: {
          name: 'Nombre',
          color: 'Color',
        },
        table: {
          name: 'Nombre',
          color: 'Color',
          contacts: 'Contactos',
          actions: 'Acciones'
        },

      },
      categories: {
        title: 'Categorias',
        buttons: {
          create: 'Agregar categoria',
          add: 'Agregar',
          cancel: 'Cancelar',
          edit: 'Guardar',
        },
        searchPlaceholder: "Buscar...",
        deleteConfirmationModal: {
          title: 'Eliminar categoria',
          warning: '¿Estás seguro de que deseas eliminar esta categoría? ¡Esta acción no se puede revertir!',
          deleteSuccess: 'Categoría eliminada correctamente'
        },
        ticketsModal: {
          title: 'Agregar categoría {{categoryName}} a los tickets',
          buttons: {
            unselectAll: 'Desmarcar todos',
          },
          notices: {
            updateSuccess: '¡La lista de tickets de la categoría ha sido actualizada!',
            selecteds: 'Tickets selecionados',
          },
        },
        categoryModal: {
          title: {
            edit: 'Editar categoría',
            add: 'Adicionar categoría',
          },
          notices: {
            addSuccess: 'Categoría agregada correctamente',
          }

        },
        form: {
          name: 'Nombre',
          color: 'Color',
        },
        table: {
          name: 'Nombre',
          number: 'Número',
          color: 'Color',
          tickets: 'Tickets',
          actions: 'Acciones',
          details: 'Detalles',
          date: 'Fecha',
          createdAt: 'Creado en: ',
          updatedAt: 'Actualizado en: '
        },
      },
      reviews: {
        title: "Evaluaciones",
        table: {
          contact: "Contacto",
          user: "Usuario",
          ticket: "Ticket",
          comment: "Comentario",
          rating: "Evaluación",
          ratedIn: "Evaluado en"
        },
        filter: {
          user: "Usuario",
          queue: "Cola",
          dateStart: "Fecha de início",
          dateEnd: "Fecha de término",
          rating: "Evaluación"
        }
      },
      fileList: {
        title: "Lista de archivos",
        buttons: {
          add: "Agregar lista"
        },
        table: {
          name: "Nombre",
          files: "Archivos",
          actions: "Acciones"
        }
      },
      messagesList: {
        header: {
          assignedTo: "Asignado a:",
          buttons: {
            return: "Devolver",
            resolve: "Resolver",
            new: "Nuevo Ticket",
            reopen: "Reabrir",
            accept: "Aceptar",
          },
        },
      },

      messagesInput: {
        placeholderOpen: "Escriba o presione '' / '' para usar respuestas rápidas",
        placeholderClosed:
          "Vuelva a abrir o acepte este ticket para enviar un mensaje.",
        signMessage: "Firmar",
      },
      openingHoursModal: {
        title: "Editar horários de atendimento dos usuários",
        form: {
          start: "Início",
          end: "Fim"
        },
        notices: {
          onCopy: "Estás copiando el horario del usuario {{userName}}. Selecciona los usuarios que también adoptarán este horario.",
          nothingHere: "¡Nada aquí!",
          nothingHereDescription: "No hay ningún usuario para listar."
        },
        buttons: {
          add: "Añadir horario",
          copy: "Copiar horario",
          cancel: "Cancelar",
          edit: "Guardar",
          paste: "Pegar"
        },
        toasts: {
          success: "¡Horarios guardados con éxito!",
        },
        days: {
          sunday: "Domingo",
          monday: "Lunes",
          tuesday: "Marte",
          wednesday: "Miércoles",
          thursday: "Jueves",
          friday: "Viernes",
          saturday: "Sábado",

        },

      },
      ticketDrawer: {
        header: "Detalles",

        name: "Nombre",
        number: "Número",
        subject: "Assunto",
        email: "Email",
        company: "Empresa",
        queue: "Cola",
        connection: "Conexión",
        ticket: "Ticket",
        buttons: {
          edit: "Editar {{type}}",
          medias: "Ver medios",
        },
        tags: "Tags de {{type}}",
        categories: "Categorias del ticket",
        extraInfo: "Otras informaciones",
        obs: "Observaciones",
        group: "grupo",
        contact: "contacto"
      },
      searchDrawer: {
        header: "Buscar mensajes"
      },
      ticketOptionsMenu: {
        delete: "Borrar",
        transfer: "Transferir",
        search: "Buscar",
        schedule: "Agendar",
        exportMsgs: "Exportar mensajes",
        confirmationModal: {
          title: "¿Borrar ticket #{{ticketId}} del contacto {{contactName}}?",
          message:
            "¡Atención! Todos los mensajes Todos los mensajes relacionados con el ticket se perderán.",
        },
        buttons: {
          delete: "Borrar",
          cancel: "Cancelar",
        },
        showLogs: "Ver logs",
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        chat: 'Chatear con',
        delete: "Borrar",
        reply: "Responder",
        foward: "Encaminar",
        confirmationModal: {
          title: "¿Borrar mensaje?",
          message: "Esta acción no puede ser revertida.",
        },
      },
      changelog: {
        title: "Notas de atualización",
        loading: "Obteniedo informaciones sobre atualizaciones...",
        loadfail: "No se puede obtener información sobre las actualizaciones.",
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP:
          "Debe haber al menos una conexión de WhatsApp predeterminada.",
        ERR_NO_DEF_WAPP_FOUND:
          "No se encontró WhatsApp predeterminado. Verifique la página de conexiones.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sesión de WhatsApp no ​​está inicializada. Verifique la página de conexiones.",
        ERR_WAPP_CHECK_CONTACT:
          "No se pudo verificar el contacto de WhatsApp. Verifique la página de conexiones.",
        ERR_WAPP_INVALID_CONTACT: "Este no es un número de whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "No se pudieron descargar los medios de WhatsApp. Verifique la página de conexiones.",
        ERR_INVALID_CREDENTIALS: "Error de autenticación. Vuelva a intentarlo.",
        ERR_SENDING_WAPP_MSG:
          "Error al enviar el mensaje de WhatsApp. Verifique la página de conexiones.",
        ERR_DELETE_WAPP_MSG: "No se pudo borrar el mensaje de WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Ya hay un ticket abierto para este contacto.",
        ERR_SESSION_EXPIRED: "Sesión caducada. Inicie sesión.",
        ERR_USER_CREATION_DISABLED:
          "La creación de usuarios fue deshabilitada por el administrador.",
        ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso.",
        ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
        ERR_NO_SETTING_FOUND:
          "No se encontró ninguna configuración con este ID.",
        ERR_NO_CONTACT_FOUND: "No se encontró ningún contacto con este ID.",
        ERR_NO_TICKET_FOUND: "No se encontró ningún ticket con este ID.",
        ERR_NO_USER_FOUND: "No se encontró ningún usuario con este ID.",
        ERR_NO_WAPP_FOUND: "No se encontró WhatsApp con este ID.",
        ERR_CREATING_MESSAGE: "Error al crear el mensaje en la base de datos.",
        ERR_CREATING_TICKET: "Error al crear el ticket en la base de datos.",
        ERR_FETCH_WAPP_MSG:
          "Error al obtener el mensaje en WhtasApp, tal vez sea demasiado antiguo.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Este color ya está en uso, elija otro.",
        ERR_WAPP_GREETING_REQUIRED:
          "El mensaje de saludo es obligatorio cuando hay más de una cola.",
      },
    },
  }
};


export { messages };