import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Paper, Stack, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles";
import api from "../../services/api";
import { useEffect, useReducer, useState } from "react";
import { green, red } from "@mui/material/colors";
import openSocket from "../../services/socket-io";
import MainHeader from "../MainHeader";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { format } from "date-fns";

const useStyles = makeStyles(theme => ({
    logPaper: {
        padding: 5,
        margin: "5px 0px"
    },
    newValues: {
        flex: 1,
        padding: 5,
        backgroundColor: green[500] + "1d"
    },
    oldValues: {
        flex: 1,
        padding: 5,
        backgroundColor: red[500] + "1d"
    }
}));


const reducer = (state, action) => {
    if (action.type === "LOAD_LOGS") {
        const logs = action.payload;
        const newLogs = [];

        logs.forEach((log) => {
            const logIndex = state.findIndex((q) => q.id === log.id);
            if (logIndex !== -1) {
                state[logIndex] = log;
            } else {
                newLogs.push(log);
            }
        });

        return [...state, ...newLogs];
    }

    if (action.type === "UPDATE_LOGS") {
        const log = action.payload;
        return [log, ...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const LogsModal = ({ ticketId, open, onClose }) => {
    const classes = useStyles();
    const [logs, dispatch] = useReducer(reducer, []);
    const [count, setCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [ticketId, open]);

    useEffect(() => {
        if (!ticketId || !open) return;
        const fetchLogs = async () => {
            try {
                const { data } = await api.get(`/logs/tickets/${ticketId}`, { params: { pageNumber } });
                dispatch({ type: "LOAD_LOGS", payload: data.logs });
                setCount(data.count);
                setHasMore(data.hasMore);
            } catch (err) {
                toastError(err);
            }
        }
        fetchLogs()
    }, [open]);

    useEffect(() => {
        const socket = openSocket();

        socket.on("log", (data) => {
            if (data.action === "update" || data.action === "create") {
                if ((ticketId && +ticketId === +data.log.ticketId) || !ticketId) {
                    dispatch({ type: "UPDATE_LOGS", payload: data.log });
                }
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);
    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }
    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
            <MainHeader>
                <DialogTitle
                    color="textPrimary"
                >{i18n.t("logs.title")}
                </DialogTitle>
            </MainHeader>
            <DialogContent dividers>
                {i18n.t("logs.showingText", { count: logs.length, total: count })}
                {logs.length > 0 && logs.map((log) => <>
                    <Paper className={classes.logPaper} variant="outlined">
                        <Typography>
                            {i18n.t("logs.ticketAction", {
                                username: log.user?.name,
                                userId: log?.userId,
                                action: i18n.t("logs.actions.action", { action: log?.action.toUpperCase() }),
                                ticketId: log?.ticketId,
                                time: format(new Date(log?.createdAt||0), "dd-MM-yyyy HH:mm")
                            })}
                        </Typography>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                            {!isEmptyObject(log.oldValues) && !isEmptyObject(log.newValues) ? <>

                                <Paper className={classes.oldValues}>
                                    {i18n.t("logs.fields.oldValues")}
                                    {log.oldValues.userId !== undefined && (
                                        <span>
                                            <br />
                                            {`Usuário: ${log.oldValues?.userName || "Sem usuário"}`}
                                        </span>
                                    )}
                                    {log.oldValues.queueId !== undefined && (
                                        <span>
                                            <br />
                                            {`Fila: ${log.oldValues?.queueName || "Sem fila"}`}
                                        </span>
                                    )}
                                    {log.oldValues.status && (
                                        <span>
                                            <br />
                                            {`Status: ${log.oldValues?.status}`}
                                        </span>
                                    )}
                                </Paper>

                                <Divider orientation="vertical" flexItem >
                                    {">>>"}
                                </Divider>
                                <Paper className={classes.newValues}>
                                    {i18n.t("logs.fields.newValues")}
                                    {log.newValues.userId !== undefined && (
                                        <span>
                                            <br />
                                            { i18n.t("logs.fields.user",{username:log.newValues?.userName || "Sem usuário"})}
                                        </span>
                                    )}
                                    {log.newValues.queueId !== undefined && (
                                        <span>
                                            <br />
                                            {i18n.t("logs.fields.queue", { queue: log.newValues?.queueName || "Sem fila" })}
                                            
                                        </span>
                                    )}
                                    {log.newValues.status && (
                                        <span>
                                            <br />
                                            {i18n.t("logs.fields.status", { status: log.newValues?.status })}
                                        </span>
                                    )}
                                </Paper>

                            </>
                                : log?.action === "update" &&
                                <div style={{ flex: 1, width: "100%", textAlign: "center" }}>{"Nenhum valor alterado"}</div>}
                        </div>
                    </Paper>
                </>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={onClose}
                >
                    {i18n.t("logs.buttons.close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default LogsModal;