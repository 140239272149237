import React, { useContext, useEffect, useRef, useState } from "react";


import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from "@mui/material/Badge";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { Button } from "@mui/material";
import TicketsFilter from "../TicketsFilter";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { TagsContext } from "../../context/Tags/TagsContext";
import { Groups } from "@mui/icons-material";


const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderLeft: 0,
    borderTop: 0,
    borderBottom: 0,
    [theme.breakpoints.down('md')]: {
      border: 0
    }
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: theme.palette.background.header,
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    minWidth: 120,
    width: 120,

  },
  ticketsTab: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: theme.palette.background.list
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.background.list,
    padding: theme.spacing(0.5),
  },

  serachInputWrapper: {
    flex: 1,
    background: theme.palette.background.header,
    display: "flex",
    borderRadius: 11,
    padding: 4,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },

  searchIcon: {
    color: theme.palette.text.secondary,
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
  },

  badge: {
    right: "-10px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },
  smallTab: {
    justifyContent: "center",
    maxWidth: 70,
    minWidth: 60,
    flex: "1"
  }
}));

const TicketsManager = () => {
  const classes = useStyles();

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(localStorage.getItem("showalltickets") === "false" ? false : localStorage.getItem("showalltickets") === "true" ? true : false);
  const searchInputRef = useRef();
  const { user,loading:userLoading } = useContext(AuthContext);

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [groupCount, setGroupCount] = useState(0);

  const userQueueIds = user?.queues?.map((q) => q.id) || [];
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const { tags,loading:tagsLoading } = useContext(TagsContext);
  const tagIds = tags.map((t) => t.id);
  const [selectedTagIds, setSelectedTagIds] = useState(tagIds || []);
  const { whatsApps, loading:whatsAppsLoading } = useContext(WhatsAppsContext);
  const whatsAppsIds = whatsApps.map((w) => w.id);
  const [selectedConnectionIds, setSelectedConnectionsIds] = useState(whatsAppsIds || []);
  const { settings, loading:settingsLoading } = useContext(SettingsContext);

  const getSettingValue = (key) => {
    if (settings && settings.length > 0) {
      const { value } = settings.find(s => s.key === key);
      return value;
    }
  };

  useEffect(() => {
    if (user?.profile?.toUpperCase() !== "ADMIN") {
      setShowAllTickets(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
      setSearchParam("");
    }
  }, [tab]);

  let searchTimeout;

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const toggleShowTickets = () => {
    setShowAllTickets(showAllTickets === false ? true : false);
    localStorage.setItem("showalltickets", showAllTickets === false ? "true" : "false");
  }

  const canView = Can({
    role: user.profile,
    perform: "drawer-admin-items:view",
    yes: () => true,
    no: () => false,
  });
  const isPrivateTickets = getSettingValue('privateticket') === 'forusers' ? true : false;
  const isShowAllToUsers = getSettingValue('seealltickets') === 'enabled' ? true : false;
  const isGroupAsTicket = getSettingValue('groupsasticket') === 'enabled' ? true : false;


  const showAllVisibilityControl = () => {
    let tabOpen = tab === "open";
    return (
      (isPrivateTickets && isShowAllToUsers && canView) ||
      (!isPrivateTickets && isShowAllToUsers && canView && tabOpen) ||
      (!isPrivateTickets && !isShowAllToUsers && canView && tabOpen) ||
      (isPrivateTickets && !isShowAllToUsers && canView) ||
      (!isPrivateTickets && isShowAllToUsers && !canView && tabOpen)
    )
  }

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };
  return (
    <Paper elevation={0} square variant="outlined" className={classes.ticketsWrapper}>
        <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(e) => setNewTicketModalOpen(false)}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab
            value={"open"}
            icon={<MoveToInboxIcon />}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"closed"}
            icon={<CheckBoxIcon />}
            label={i18n.t("tickets.tabs.closed.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"search"}
            icon={<SearchIcon />}
            label={i18n.t("tickets.tabs.search.title")}
            classes={{ root: classes.tab }}
          />
        </Tabs>
      </Paper>
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        {tab === "search" ? (
          <div className={classes.serachInputWrapper}>
            <SearchIcon className={classes.searchIcon} />
            <InputBase
              className={classes.searchInput}
              inputRef={searchInputRef}
              placeholder={i18n.t("tickets.search.placeholder")}
              type="search"
              onChange={handleSearch}
            />
          </div>
        ) : (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setNewTicketModalOpen(true)}
            >
              {i18n.t("ticketsManager.buttons.newTicket")}
            </Button>
          </>
        )}
        {showAllVisibilityControl() &&
          <FormControlLabel
            label={i18n.t("tickets.buttons.showAll")}
            labelPlacement="top"
            control={
              <Switch
                size="small"
                checked={showAllTickets}
                onChange={toggleShowTickets}
                name="showAllTickets"
                color="primary"
              />
            }
          />
        }

        {/*
        <TicketsQueueSelect
          style={{ marginLeft: 6 }}
          selectedQueueIds={selectedQueueIds}
          userQueues={user?.queues}
          onChange={(values) => setSelectedQueueIds(values)}
        />*/}
        <TicketsFilter
          queues={user?.queues}
          tags={tags}
          connections={whatsApps}
          selectedQueuesIds={selectedQueueIds}
          selectedTagsIds={selectedTagIds}
          selectedConnectionsIds={selectedConnectionIds}
          onChange={(q, t, c) => {
            setSelectedQueueIds(q);
            setSelectedTagIds(t);
            setSelectedConnectionsIds(c);
          }}
        />
      </Paper>
      <TabPanel value={tab} name="open" className={classes.ticketsTab}>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {!isGroupAsTicket &&
          <Tab
            className={classes.smallTab}
            label={
              <Badge
                className={classes.badge}
                badgeContent={groupCount}
                color="secondary"
              >
                <Groups />
              </Badge>
            }
            value={"groups"}
          />}
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={openCount}
                color="primary"
              >
                {i18n.t("ticketsList.assignedHeader")}
              </Badge>
            }
            value={"open"}
          />
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={pendingCount}
                color="secondary"
              >
                {i18n.t("ticketsList.pendingHeader")}
              </Badge>
            }
            value={"pending"}
          />
        </Tabs>
        <Paper square className={classes.ticketsWrapper}>
          <TicketsList
            status={'open'}
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            selectedTagIds={selectedTagIds}
            selectedConnectionIds={selectedConnectionIds}
            updateCount={(val) => setOpenCount(val)}
            style={applyPanelStyle("open")}
          />
          <TicketsList
            status={'pending'}
            selectedQueueIds={selectedQueueIds}
            selectedTagIds={selectedTagIds}
            selectedConnectionIds={selectedConnectionIds}
            updateCount={(val) => setPendingCount(val)}
            style={applyPanelStyle("pending")}
          />
          {!isGroupAsTicket &&
          <TicketsList
            status={'groups'}
            showAll={isPrivateTickets ? showAllTickets : true}
            selectedQueueIds={selectedQueueIds}
            selectedTagIds={selectedTagIds}
            selectedConnectionIds={selectedConnectionIds}
            updateCount={(val) => setGroupCount(val)}
            style={applyPanelStyle("groups")}
          />}
        </Paper>
      </TabPanel>
      <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
        <TicketsList
          status="closed"
          showAll={isPrivateTickets ? showAllTickets : true}
          /*showAll={true}*/
          selectedQueueIds={selectedQueueIds}
          selectedTagIds={selectedTagIds}
          selectedConnectionIds={selectedConnectionIds}
        />
      </TabPanel>
      <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
        <TicketsList
          searchParam={searchParam}
          showAll={isPrivateTickets ? showAllTickets : true}
          /*showAll={true}*/
          selectedQueueIds={selectedQueueIds}
          selectedTagIds={selectedTagIds}
          selectedConnectionIds={selectedConnectionIds}
        />
      </TabPanel>
      
    </Paper>
  );
};

export default TicketsManager;